import { gql, useLazyQuery } from '@apollo/client';
import * as React from 'react';

import {
  UseMediaThumbnailQuery_ThumbnailQuery,
  UseMediaThumbnailQuery_ThumbnailQueryVariables,
} from './___generated___/useMediaThumbnailUrlQuery.types';

const DEFAULT_THUMBNAIL_WIDTH_PX = 640;
const DEFAULT_THUMBNAIL_HEIGHT_PX = 360;
const THUMBNAIL_SNAP_INTERVAL_S = 5;

const ThumbnailQuery = gql`
  query UseMediaThumbnailQuery_ThumbnailQuery($mediaId: ID!, $offsetSeconds: Int) {
    media(id: $mediaId) {
      id
      previewImage(offsetSeconds: $offsetSeconds) {
        id
        url: url_1024
      }
    }
  }
`;

export interface IUseMediaThumbnailUrlQueryProps {
  mediaId?: string;
  height?: number;
  width?: number;
}

/**
 * Hook to get the thumbnail URL for a media item at a given offset.
 */
export function useMediaThumbnailUrlQuery({ mediaId }: IUseMediaThumbnailUrlQueryProps) {
  const [query] = useLazyQuery<UseMediaThumbnailQuery_ThumbnailQuery, UseMediaThumbnailQuery_ThumbnailQueryVariables>(
    ThumbnailQuery
  );

  const thumbnailQuery = React.useCallback(
    async (offsetSeconds?: number) => {
      if (!mediaId) {
        return null;
      }

      const _offsetSeconds =
        offsetSeconds !== undefined
          ? Math.floor(offsetSeconds / THUMBNAIL_SNAP_INTERVAL_S) * THUMBNAIL_SNAP_INTERVAL_S
          : undefined;

      const result = await query({
        variables: {
          mediaId: mediaId,
          offsetSeconds: _offsetSeconds,
        },
      });

      return result.data?.media?.previewImage?.url ?? null;
    },
    [mediaId, query]
  );

  return thumbnailQuery;
}
