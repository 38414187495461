import * as React from 'react';

import Alert from '../Alert';
import Modal, { ModalProps } from '../Modal';
import OriginTypeahead from '../Typeahead/OriginTypeahead';
import Button from '../ui/Button';

import { IDiscussionParent, useEditParentMutation, useIsChildDiscussionQuery } from './hooks';

export interface IEditOriginModalProps extends ModalProps {
  thread: { id: string; parent?: IDiscussionParent | null };
}

function EditParentModal({ thread, ...props }: IEditOriginModalProps) {
  const [error, setError] = React.useState<string | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const defaultParentIdRef = React.useRef<string | null>(thread.parent?.id ?? null);
  const defaultParentId = defaultParentIdRef.current;

  const [parentId, setParentId] = React.useState<string | null>(defaultParentId);

  React.useEffect(() => {
    if (defaultParentId !== thread.parent?.id) {
      defaultParentIdRef.current = thread.parent?.id ?? null;
      setParentId(defaultParentIdRef.current);
    }
  }, [defaultParentId, thread.parent?.id]);

  const [editParent] = useEditParentMutation();
  const [isChild] = useIsChildDiscussionQuery(thread.id);

  // TODO filter children of the current discussion from this list.
  const excludeIds = [thread.id, parentId].filterNullish();

  async function onSave() {
    setIsSaving(true);

    if (parentId !== defaultParentId) {
      await editParent(thread.id, parentId);
    }

    setIsSaving(false);
    props.onHide?.();
  }

  function onExit() {
    setParentId(defaultParentId);
    props.onHide?.();
  }

  async function validateAndSetId(optionId: string, optionName: string) {
    const result = await isChild(optionId);
    if (result?.data?.discussion?.isChild) {
      setError(`"${optionName}" is a descendant of the current Discussion. Please select a different option.`);
      setParentId(null);
    } else {
      setError(null);
      setParentId(optionId);
    }
  }

  return (
    <Modal {...props} onExit={onExit}>
      <Modal.Header closeButton>
        <Modal.Title>Change Parent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert
            variant="danger"
            onClose={() => {
              setError(null);
              setParentId(null);
            }}
          >
            {error}
          </Alert>
        )}
        <OriginTypeahead
          disabled={isSaving}
          excludeIds={excludeIds}
          onRemove={() => {
            setError(null);
            setParentId(null);
          }}
          onSelect={validateAndSetId}
          selectedId={parentId}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cancel</Button>
        <Button disabled={isSaving || !!error} onClick={onSave} type="primary">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditParentModal;
