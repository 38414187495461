import { gql } from '@apollo/client';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { usePinResourceMutation } from '../../../hooks/mutation/usePinResourceMutation';
import { truncateText } from '../../../lib/css';
import Logger from '../../../lib/observability/Logger';
import { CanonicalSizePx } from '../../../providers/ThemeProvider/themes';
import { ProfileAvatar } from '../../Avatar';
import { CF_ProfileAvatar } from '../../Avatar/___generated___/ProfileAvatar.types';
import EditableText from '../../EditableText';
import { CopyIcon, EditIcon, LinkExternalIcon, PinIcon } from '../../Icon';
import Interactive from '../../Interactive';
import { ConditionalWrapper } from '../../lib/ConditionalWrapper';
import Tooltip from '../../Tooltip';
import Button from '../../ui/Button';

import { CF_ThreadResourceListItem } from './___generated___/ThreadResourceListItem.types';
import ThreadResourceThumbnail from './ThreadResourceThumbnail';
import useEditResourceLabel from './useEditResourceLabel';

const _HoverOverlay = styled.div`
  align-items: center;
  bottom: 0;
  display: none;
  gap: 4px;

  & > button {
    filter: drop-shadow(0 0 2px ${({ theme }) => theme.color.dropShadow});

    &:hover {
      filter: drop-shadow(0 0 2px ${({ theme }) => theme.color.dropShadow});
    }
  }
`;

const _ProfileAvatar = styled(ProfileAvatar)``;

const _PinIcon = styled(PinIcon)``;

const _Container = styled.div`
  border-radius: 4px;
  display: block;
  overflow: visible;
  position: relative;

  :hover {
    background: ${({ theme }) => theme.color.background500};
  }

  :hover ${_HoverOverlay} {
    display: flex;
  }

  :hover ${_ProfileAvatar} {
    display: none;
  }

  :hover ${_PinIcon} {
    display: none;
  }
`;

const _ItemContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.color};
  display: flex;
  gap: 8px;
  height: 28px;
  padding: 2px 8px;
`;

const _ThumbnailContainer = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 16px;
  justify-content: center;
  overflow: hidden;
  width: 16px;
`;

const _Title = styled.div`
  flex: 1;
  ${truncateText()}
`;

export interface IThreadResourceListItemBaseProps {
  eventPermalink: string;
  hideHoverActions?: boolean;
  hidePinnedIcon?: boolean;
  onEdit?: () => void;
  onOpen?: () => void;
  onPin?: (currentValue: boolean) => void;
  pinned?: boolean;
  shareUrl: string;
  sharedBy?: CF_ProfileAvatar | null;
  thumbnail: JSX.Element;
  title: JSX.Element | string;
  tooltip?: string | null;
}

export function ThreadResourceListItemBase(props: IThreadResourceListItemBaseProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const _pinResource = React.useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await props.onPin?.(!!props.pinned);
      } catch (e) {
        Logger.error(e as Error, 'Failed to pin permalink');
      }
    },
    [props]
  );

  const _copyShareURL = React.useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await navigator.clipboard.writeText(props.shareUrl);
      } catch (e) {
        Logger.error(e as Error, 'Failed to copy permalink');
      }
    },
    [props.shareUrl]
  );

  const _onEdit = React.useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      props.onEdit && props.onEdit();
    },
    [props]
  );

  const _openURL = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        const url = new URL(props.shareUrl);
        // if this is an internal link either use the onOpen callback or navigate
        // if it's an external link, open in a new tab
        if (url.host === window.location.host) {
          if (props.onOpen) {
            props.onOpen();
          } else {
            navigate(url.pathname);
          }
        } else {
          window.open(props.shareUrl, '_blank');
        }
      } catch (e) {
        Logger.error(e as Error, 'Failed to open permalink');
      }
    },
    [navigate, props]
  );

  const base = (
    <_Container>
      <ConditionalWrapper
        condition={!!props.tooltip}
        wrapper={({ children }) => (
          <Tooltip placement="auto-start" title={props.tooltip}>
            <span>{children}</span>
          </Tooltip>
        )}
      >
        <_ItemContainer>
          <_ThumbnailContainer>{props.thumbnail}</_ThumbnailContainer>
          <_Title>{props.title}</_Title>
          {!props.hidePinnedIcon && props.pinned && (
            <_PinIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
          )}
          {props.sharedBy && <_ProfileAvatar profile={props.sharedBy} size={CanonicalSizePx.xSmall} />}
          {!props.hideHoverActions && (
            <_HoverOverlay>
              {props.onEdit && (
                <Tooltip title="Edit label">
                  <Button icon={<EditIcon />} onClick={_onEdit} size="small" shape="round" />
                </Tooltip>
              )}
              <Tooltip title="Copy link">
                <Button icon={<CopyIcon />} onClick={_copyShareURL} size="small" shape="round" />
              </Tooltip>
              <Tooltip title="Open link">
                <Button icon={<LinkExternalIcon />} onClick={_openURL} size="small" shape="round" />
              </Tooltip>
              <Tooltip title={props.pinned ? 'Un-Pin' : 'Pin'}>
                <Button active={props.pinned} icon={<PinIcon />} onClick={_pinResource} size="small" shape="round" />
              </Tooltip>
            </_HoverOverlay>
          )}
        </_ItemContainer>
      </ConditionalWrapper>
    </_Container>
  );

  return props.onOpen ? (
    <Interactive onClick={props.onOpen}>{base}</Interactive>
  ) : (
    <Link to={props.eventPermalink}>{base}</Link>
  );
}

// ThreadResourceListItem

export interface IThreadResourceListItemProps {
  hideHoverActions?: boolean;
  hidePinnedIcon?: boolean;
  hideSharedBy?: boolean;
  onOpen?: () => void;
  resource: CF_ThreadResourceListItem;
}

export default function ThreadResourceListItem(props: IThreadResourceListItemProps) {
  const [editingLabel, setEditingLabel] = React.useState(false);
  const editLabel = useEditResourceLabel(props.resource.id);
  const [pinResource, pinResourceResult] = usePinResourceMutation(props.resource.id);

  const { resource } = props.resource;
  if (!resource) {
    return null;
  }

  const event = props.resource.threadEvents?.[0];
  if (!event) {
    return null;
  }

  const sharedBy = props.resource.sharedBy?.[0];
  const shareUrl =
    resource.__typename === 'Link'
      ? resource.href
      : resource.__typename === 'Meeting' || resource.__typename === 'Discussion'
      ? `${window.location.origin}${resource.permalink}`
      : `${window.location.origin}${event.permalink}`;
  const tooltip = resource.__typename === 'Link' ? resource.href : undefined;

  const title = !editingLabel ? (
    props.resource.label
  ) : (
    <EditableText
      focus
      text={props.resource.label}
      onEdit={async (text) => {
        if (text.length > 0) {
          await editLabel(text);
        }
        setEditingLabel(false);
      }}
    />
  );

  return (
    <ThreadResourceListItemBase
      eventPermalink={event.permalink}
      hideHoverActions={props.hideHoverActions}
      hidePinnedIcon={props.hidePinnedIcon}
      onEdit={props.resource.canEditLabel ? () => setEditingLabel(true) : undefined}
      onOpen={props.onOpen}
      onPin={(current) => pinResource(!current)}
      pinned={props.resource.pinned}
      sharedBy={!props.hideSharedBy ? sharedBy : null}
      shareUrl={shareUrl}
      thumbnail={<ThreadResourceThumbnail resource={resource} size={14} />}
      title={title}
      tooltip={tooltip}
    />
  );
}

ThreadResourceListItem.fragment = gql`
  ${ProfileAvatar.fragment}
  fragment CF_ThreadResourceListItem on ThreadResource {
    id
    canEditLabel
    label
    pinned
    resource {
      ... on Discussion {
        id
        messageCount
        name
        permalink
        videoCount
        ...CF_ProfileAvatar
      }
      ... on File {
        id
        contentType
        name
      }
      ... on Image {
        id
        name
        url: url_64
      }
      ... on Link {
        id
        appObject {
          id
          app
          objectData
        }
        name
        image {
          id
          url: url_64
        }
        href: url
      }
      ... on Media {
        id
        name
        previewImage {
          id
          url: url_64
        }
      }
      ... on Meeting {
        id
        name
        media {
          id
          previewImage {
            id
            url: url_64
          }
        }
        permalink
      }
      ... on Team {
        id
        messageCount
        name
        permalink
        videoCount
        ...CF_ProfileAvatar
      }
    }
    sharedBy {
      id
      ...CF_ProfileAvatar
    }
    threadEvents {
      id
      permalink
      time
    }
    time
  }
`;
