import * as React from 'react';
import { Editor, Node, NodeEntry, Range, Element as SlateElement, Text } from 'slate';

import { useSearchResultContext } from '../../../providers/SearchResultContextProvider';

export const SEARCH_HIGHLIGHT_MARK: string = '_searchHighlight';

export const useSearchHighlightDecorator = (editor: Editor): ((entry: NodeEntry) => Range[]) => {
  const { keywords } = useSearchResultContext();

  return React.useCallback(
    (entry: NodeEntry): Range[] => {
      if (!keywords || keywords.length === 0) {
        return [];
      }

      const [node, path] = entry;
      if (!SlateElement.isElement(node)) {
        return [];
      }

      // Make sure the node is a block node with text children or inline nodes
      if (
        node.children.length === 0 ||
        !node.children.every((child) => Text.isText(child) || Editor.isInline(editor, child))
      ) {
        return [];
      }

      const ranges: Range[] = [];
      const nodeStrings = node.children.map((n) => Node.string(n).toLocaleLowerCase());
      const concatString = nodeStrings.join('');

      // Find all occurrences of the keywords in the node
      for (const keyword of keywords) {
        let matchStart = concatString.indexOf(keyword.toLocaleLowerCase());

        let nodeStringsIdx = 0;
        let concatStringOffset = 0;

        while (matchStart !== -1) {
          // Skip over any node strings that are before the current match
          while (
            nodeStringsIdx < nodeStrings.length &&
            matchStart >= concatStringOffset + nodeStrings[nodeStringsIdx].length
          ) {
            concatStringOffset += nodeStrings[nodeStringsIdx].length;
            nodeStringsIdx++;
          }

          // Find start and end path for the keyword which may span multiple nodes

          let offset = matchStart - concatStringOffset;
          let remainder = keyword.length;
          while (nodeStringsIdx < nodeStrings.length && remainder > 0) {
            const currentText = nodeStrings[nodeStringsIdx];
            const currentPath = path.concat([nodeStringsIdx]);
            const charsTaken = Math.min(remainder, currentText.length - offset);
            ranges.push({
              anchor: { path: currentPath, offset },
              focus: { path: currentPath, offset: offset + charsTaken },
              [SEARCH_HIGHLIGHT_MARK]: true,
            });

            remainder -= charsTaken;

            if (remainder > 0) {
              concatStringOffset += currentText.length;
              nodeStringsIdx++;
              offset = 0;
            }
          }

          matchStart = concatString.indexOf(keyword.toLocaleLowerCase(), matchStart + keyword.length);
        }
      }

      return ranges;
    },
    [editor, keywords]
  );
};
