import { subDays } from 'date-fns';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { truncateText } from '../../../lib/css';
import { CanonicalSizePx } from '../../../providers/ThemeProvider/themes';
import { isIThread } from '../../../types/Model';
import { ProfileAvatar } from '../../Avatar';
import Dropdown from '../../Dropdown';
import HotkeyHint from '../../HotkeyTooltip/HotkeyHint';
import { ClockIcon, LockIcon } from '../../Icon';
import SearchResultHighlighter from '../../search/SearchResultHighlighter';
import Timestamp, { TimestampFormat } from '../../Timestamp';
import Typeahead, { ItemProps, TypeaheadProps, TypeaheadRef } from '../Typeahead';

import { ISearchTokenOption, ISearchTypeaheadOption } from './useSearchTypeaheadOptionsQuery';

// We render two different option types in the SearchTypeahead:
//  ACTION: these options are rendered as actions the user can select to complete certain tasks
//  FILTER: these options are rendered as tokens which the user can select to further refine search results
//  RESULT: these options are rendered as search results which the user can select to navigate
export type IActionSearchTypeaheadOption = { optionType: 'ACTION' } & {
  id: string;
  disabled?: boolean;
  hotkeyHint?: string[];
  icon?: JSX.Element;
  name: string;
  onClick: () => void;
  preview?: string;
};
export type IFilterSearchTypeaheadOption = { optionType: 'FILTER' } & ISearchTokenOption;
export type IResultSearchTypeaheadOption = { optionType: 'RESULT' } & ISearchTypeaheadOption;
export type IUberSearchTypeaheadOption =
  | IActionSearchTypeaheadOption
  | IFilterSearchTypeaheadOption
  | IResultSearchTypeaheadOption;

// Render a "recent" flag on any item that has been viewed in the last four weeks
const RECENT_ITEMS_CUTOFF_DAYS = 28;

const ICON_SIZE_PX = 20;

//
// Search result option rendering
//

const _ResultItem = styled(Typeahead.Item)`
  align-items: start;
  display: flex;
  gap: 8px;
  line-height: 1.2;
  padding: 6px 8px;
`;

const _ResultMain = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
`;

const _ResultIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: ${ICON_SIZE_PX}px;
`;

const _ResultName = styled.div`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${ICON_SIZE_PX}px;
  ${truncateText()}
`;

const _ResultPreview = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  ${truncateText()};
`;

const _ResultIconDecoration = styled.div`
  padding-top: 2px;
`;

const _ResultDate = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
  width: 70px; /* To keep icons lined up; ideally this column with would be dynamic based on max length of date string across all results */
`;

interface IResultOptionProps extends ItemProps {
  option: ISearchTypeaheadOption;
}

const _ResultOption = ({ option, ...props }: IResultOptionProps) => {
  const theme = useTheme();

  const createdAt =
    (option.__typename === 'Account' && option.privateChat?.createdAt) ||
    (isIThread(option) && option.createdAt) ||
    null;

  const lastEventAt =
    (option.__typename === 'Account' && option.privateChat?.lastEventAt) ||
    (isIThread(option) && option.lastEventAt) ||
    null;

  const lastVisitedAt =
    (option.__typename === 'Account' && option.privateChat?.lastVisitedAt) ||
    (isIThread(option) && option.lastVisitedAt) ||
    null;

  const isPrivate =
    (option.__typename === 'Discussion' || option.__typename === 'Team') && option.audience === 'PRIVATE';

  const highlightedName = option.searchMetadata?.highlights?.name ? (
    <SearchResultHighlighter text={option.searchMetadata.highlights.name} />
  ) : (
    option.name
  );

  const decoration =
    lastVisitedAt && new Date(lastVisitedAt) > subDays(new Date(), RECENT_ITEMS_CUTOFF_DAYS) ? (
      <ClockIcon color={theme.color.secondaryColor} size="xSmall" />
    ) : null;

  return (
    <_ResultItem {...props} disabled={option.disabled} option={option}>
      <_ResultIcon>
        <ProfileAvatar size={CanonicalSizePx.xSmall} profile={option} />
      </_ResultIcon>
      <_ResultMain style={{ flexDirection: 'row' }}>
        {isPrivate ? <LockIcon size="xxSmall" /> : null}
        <_ResultName>{highlightedName}</_ResultName>
      </_ResultMain>
      {decoration && <_ResultIconDecoration>{decoration}</_ResultIconDecoration>}
      {(lastEventAt || createdAt) && (
        <_ResultDate>
          <Timestamp format={TimestampFormat.EXTRA_SHORT} timestamp={new Date(lastEventAt || createdAt)} />
        </_ResultDate>
      )}
    </_ResultItem>
  );
};

//
// Action option
//

const _ActionName = styled(_ResultName)`
  font-size: ${({ theme }) => theme.fontSize.h6};
`;

interface IActionOptionProps extends ItemProps {
  option: IActionSearchTypeaheadOption;
}

const _ActionOption = ({ option, ...props }: IActionOptionProps) => {
  return (
    <_ResultItem {...props} option={option}>
      <_ResultIcon>{option.icon}</_ResultIcon>
      <_ResultMain>
        <_ActionName>{option.name}</_ActionName>
        <_ResultPreview>{option.preview}</_ResultPreview>
      </_ResultMain>
      {option.hotkeyHint && <HotkeyHint keys={option.hotkeyHint} />}
    </_ResultItem>
  );
};

interface IRenderResultsProps {
  results: IUberSearchTypeaheadOption[];
}

const _renderMenuContent = ({ results }: IRenderResultsProps) => {
  const menuContent: JSX.Element[] = [];

  let position = 0;

  const options = results.filter(
    (r): r is IActionSearchTypeaheadOption | IResultSearchTypeaheadOption => r.optionType !== 'FILTER'
  );

  if (options.find((o) => o.optionType === 'RESULT')) {
    menuContent.push(<Dropdown.Header key={'suggestions'}>Suggestions</Dropdown.Header>);
  }

  options.map((option, idx) => {
    menuContent.push(
      option.optionType === 'ACTION' ? (
        <_ActionOption key={`${option.optionType}-${option.id}`} option={option} position={position++} />
      ) : (
        <_ResultOption key={`${option.optionType}-${option.id}`} option={option} position={position++} />
      )
    );

    // If next option is of a different type than the current option, add a divider
    if (idx < options.length - 1 && option.optionType !== options[idx + 1].optionType) {
      menuContent.push(<Dropdown.Divider key={`divider-${idx}`} />);
    }
  });

  return menuContent;
};

export interface IBaseSearchTypeaheadProps extends Omit<TypeaheadProps, 'onChange'> {
  onChange?: (selected: IUberSearchTypeaheadOption[]) => void;
  options: IUberSearchTypeaheadOption[];
}

const BaseSearchTypeahead = React.forwardRef<TypeaheadRef, IBaseSearchTypeaheadProps>(
  ({ labelKey = 'name', ...props }, ref) => {
    return (
      <Typeahead
        {...props}
        align="left"
        labelKey={labelKey}
        onChange={(selected) => props.onChange?.(selected as IUberSearchTypeaheadOption[])}
        ref={ref}
        renderMenu={(results, { renderMenuItemChildren, ...menuProps }, childProps) => {
          return (
            <Typeahead.Menu {...menuProps} width="100%">
              {_renderMenuContent({ results: results as IUberSearchTypeaheadOption[] })}
            </Typeahead.Menu>
          );
        }}
        renderToken={(option, props) => {
          // Render selected filter options
          const { disabled, id, optionType, name } = option as IUberSearchTypeaheadOption;
          return (
            <Typeahead.Token
              key={`${optionType}-${id}`}
              disabled={disabled || props.disabled}
              onRemove={props.onRemove}
              option={option}
            >
              {name}
            </Typeahead.Token>
          );
        }}
      />
    );
  }
);

BaseSearchTypeahead.displayName = 'BaseSearchTypeahead';

export { BaseSearchTypeahead };
