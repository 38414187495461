import { gql, useQuery } from '@apollo/client';
import { RTElementProp, RTTeamRefElement } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';

import ResourceRef, { ResourceType } from '../../../../Resource/ResourceRef';
import { IRTElementProps } from '../../../IRTElementProps';
import VoidInlineElement from '../../common/VoidInlineElement';

import {
  BATCHED__TeamRefElement_TeamQuery,
  BATCHED__TeamRefElement_TeamQueryVariables,
} from './___generated___/TeamRefElement.types';

export interface IBaseTeamRefElementProps extends IRTElementProps<RTTeamRefElement> {
  canSee?: boolean;
  name?: string;
  permalink?: string;
  selected?: boolean;
}

export function BaseTeamRefElement(props: IBaseTeamRefElementProps) {
  return (
    <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
      <ResourceRef
        canSeeResource={props.canSee ?? false}
        editable={props.editable}
        resourceId={props.element[RTElementProp.TEAM_REF__TEAM_ID]}
        resourcePermalink={props.permalink}
        resourceTitle={props.name}
        resourceType={ResourceType.TEAM}
        selected={props.selected ?? false}
        threadEventId={props.element[RTElementProp.TEAM_REF__EVENT_ID]}
      />
    </VoidInlineElement>
  );
}

const TeamQuery = gql`
  # Batched query
  query BATCHED__TeamRefElement_TeamQuery($id: ID!) {
    team(id: $id) {
      id
      name
      permalink
    }
  }
`;

export default function TeamRefElement(props: IRTElementProps<RTTeamRefElement>) {
  const selected = useSelected();
  const discussionQuery = useQuery<BATCHED__TeamRefElement_TeamQuery, BATCHED__TeamRefElement_TeamQueryVariables>(
    TeamQuery,
    {
      variables: { id: props.element[RTElementProp.TEAM_REF__TEAM_ID] ?? '' },
      skip: !props.element[RTElementProp.TEAM_REF__TEAM_ID],
    }
  );

  const canSeeTeam = discussionQuery.loading || !!discussionQuery.data?.team?.id;

  return (
    <BaseTeamRefElement
      {...props}
      canSee={canSeeTeam}
      name={discussionQuery.data?.team?.name}
      permalink={discussionQuery.data?.team?.permalink}
      selected={selected}
    />
  );
}
