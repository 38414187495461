import EmojiPicker, {
  Categories,
  EmojiClickData,
  EmojiStyle,
  SkinTonePickerLocation,
  SkinTones,
  SuggestionMode,
  Theme,
} from 'emoji-picker-react';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { EmojiSkinTone } from '../../___generated___/globalTypes';
import useBreakpoints from '../../hooks/useBreakpoints';
import { CanonicalSizePx } from '../../providers/ThemeProvider/themes';

export const BASE_EMOJI_PICKER_DEFAULT_EMOJI_STYLE = EmojiStyle.APPLE;

const DEFAULT_REACTIONS = [
  '1f44d', // 👍
  '2764-fe0f', // ❤️
  '1f923', // 🤣
  '1f64f', // 🙏
  '1f440', // 👀
  '2705', // ✅
];

const EMOJI_PICKER_WIDTH_PX = 315;
const EMOJI_PICKER_HEIGHT_PX = 405;
const EMOJI_PICKER_WIDTH_MOBILE_PX = 250;
const EMOJI_PICKER_HEIGHT_MOBILE_PX = 300;

// Abbreviated list of categories given limited mobile real estate
const MOBILE_EMOJI_PICKER_CONFIG = [
  { category: Categories.SUGGESTED, name: 'Frequently Used' },
  { category: Categories.SMILEYS_PEOPLE, name: 'Smiles & People' },
  { category: Categories.ANIMALS_NATURE, name: 'Animals & Nature' },
  { category: Categories.FOOD_DRINK, name: 'Food & Drink' },
  { category: Categories.TRAVEL_PLACES, name: 'Travel & Places' },
  { category: Categories.ACTIVITIES, name: 'Activities' },
  { category: Categories.SYMBOLS, name: 'Symbols' },
];

const _skinTonesToEmojiSkinTone = (skinTone: SkinTones): EmojiSkinTone => {
  switch (skinTone) {
    case SkinTones.LIGHT:
      return EmojiSkinTone.LIGHT;
    case SkinTones.MEDIUM_LIGHT:
      return EmojiSkinTone.MEDIUM_LIGHT;
    case SkinTones.MEDIUM:
      return EmojiSkinTone.MEDIUM;
    case SkinTones.MEDIUM_DARK:
      return EmojiSkinTone.MEDIUM_DARK;
    case SkinTones.DARK:
      return EmojiSkinTone.DARK;
    default:
      return EmojiSkinTone.NEUTRAL;
  }
};

const _Container = styled.div`
  display: inline-block;

  /**
   * Override the default border color on the emoji picker
   * (3rd party component, hence the indirect selector)
   */

  /* stylelint-disable-next-line selector-class-pattern */
  .EmojiPickerReact {
    --epr-bg-color: transparent;
    --epr-category-label-bg-color: ${({ theme }) => theme.color.background600};
    --epr-category-navigation-button-size: ${CanonicalSizePx.medium}px;
    --epr-dark-category-icon-active-color: ${({ theme }) => theme.color.variant.primary.color};
    --epr-emoji-size: ${CanonicalSizePx.small}px;
    --epr-emoji-variation-picker-bg-color: ${({ theme }) => theme.color.background800};
    --epr-hover-bg-color: ${({ theme }) => theme.color.background500};
    --epr-picker-border-color: transparent; /* ${({ theme }) => theme.color.border}; */
    --epr-picker-border-radius: 0;
    --epr-reactions-bg-color: transparent;
    --epr-search-border-color: ${({ theme }) => theme.color.border};
    --epr-search-input-bg-color-active: ${({ theme }) => theme.color.background500};
    --epr-search-input-bg-color: ${({ theme }) => theme.color.background500};
    --epr-text-color: ${({ theme }) => theme.color.color};
  }
`;

export interface IBaseEmojiPickerProps extends React.HTMLAttributes<HTMLDivElement> {
  allowExpandCompact?: boolean;
  compact?: boolean;
  defaultSkinTone?: EmojiSkinTone | null;
  height?: number;
  onEmojiSelect?: (emoji: { unified: string; emoji: string }) => void;
  onSkinToneChange?: (skinTone: EmojiSkinTone) => void;
  open?: boolean;
  width?: number;
}

function BaseEmojiPicker({
  allowExpandCompact = true,
  compact,
  defaultSkinTone = EmojiSkinTone.NEUTRAL,
  height,
  onEmojiSelect,
  onSkinToneChange,
  open = true,
  width,
  ...props
}: IBaseEmojiPickerProps) {
  const { isMobileSize } = useBreakpoints();
  const theme = useTheme();

  const defaultHeight = isMobileSize ? EMOJI_PICKER_HEIGHT_MOBILE_PX : EMOJI_PICKER_HEIGHT_PX;
  const defaultWidth = isMobileSize ? EMOJI_PICKER_WIDTH_MOBILE_PX : EMOJI_PICKER_WIDTH_PX;

  const _onEmojiSelect = React.useCallback(
    (emojiData: EmojiClickData) => {
      onEmojiSelect?.({ unified: emojiData.unified, emoji: emojiData.emoji });
    },
    [onEmojiSelect]
  );

  const _onSkinToneChange = React.useCallback(
    (skinTone: SkinTones) => {
      onSkinToneChange?.(_skinTonesToEmojiSkinTone(skinTone));
    },
    [onSkinToneChange]
  );

  return (
    <_Container {...props}>
      <EmojiPicker
        allowExpandReactions={allowExpandCompact}
        autoFocusSearch
        categories={isMobileSize ? MOBILE_EMOJI_PICKER_CONFIG : undefined}
        defaultSkinTone={defaultSkinTone ? SkinTones[defaultSkinTone] : SkinTones.NEUTRAL}
        emojiStyle={BASE_EMOJI_PICKER_DEFAULT_EMOJI_STYLE}
        height={height ?? defaultHeight}
        lazyLoadEmojis
        onEmojiClick={_onEmojiSelect}
        onSkinToneChange={_onSkinToneChange}
        open={open}
        previewConfig={{ showPreview: false }}
        reactions={DEFAULT_REACTIONS}
        reactionsDefaultOpen={compact} // compact mode on mobile by default
        searchDisabled={isMobileSize} // not enough space on mobile for picker + keyboard
        skinTonePickerLocation={SkinTonePickerLocation.SEARCH}
        suggestedEmojisMode={SuggestionMode.RECENT}
        theme={theme.spec.scheme === 'dark' ? Theme.DARK : Theme.LIGHT}
        width={width ?? defaultWidth}
      />
    </_Container>
  );
}

export default BaseEmojiPicker;
