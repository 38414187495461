import * as React from 'react';
import styled from 'styled-components';

const _KeyContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;
  padding: 4px;
`;

const _Key = styled.div`
  align-items: baseline;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.color.secondaryColor};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  justify-content: center;
  min-width: 16px;
  padding: 1px 2px;
`;

export interface IHotkeyHintProps {
  keys: string[];
}

export default function HotkeyHint({ keys }: IHotkeyHintProps) {
  return (
    <_KeyContainer>
      {keys.map((key) => (
        <_Key key={key}>{key}</_Key>
      ))}
    </_KeyContainer>
  );
}
