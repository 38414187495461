import {
  AppAuthError,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_REFRESH_TOKEN,
  RevokeTokenRequest,
} from '@openid/appauth';
import { useLocalStorage } from '@rehooks/local-storage';
import {
  ACCESS_TOKEN_STORE_KEY,
  AUTH_MANAGER_SET_ACCESS_TOKEN,
  LOGGED_IN_ACCOUNT_STORE_KEY,
  REFRESH_TOKEN_STORE_KEY,
} from '@rmvw/c-common';
import { Paths } from '@rmvw/x-common';
import * as React from 'react';
import { Location } from 'react-router-dom';

import { IS_MOBILE_APP } from '../../env';
import Logger from '../../lib/observability/Logger';
import SentryAgent from '../../lib/observability/SentryAgent';

import AuthorizationError from './AuthorizationError';
import useAuthorizationRequest from './useAuthorizationRequest';
import useAuthorizationServiceConfiguration from './useAuthorizationServiceConfiguration';
import useTokenRequest, { ITokenRequestArgs } from './useTokenRequest';

const REDIRECT_LOCATION_STORE_KEY = '_loc';
const REFRESH_TOKEN_REFRESH_LOCK_STORE_KEY = '_rtl';

/**
 * RefreshToken state kept out of React to avoid re-renders
 */
const _refreshTokenStore = {
  get: () => window.localStorage.getItem(REFRESH_TOKEN_STORE_KEY),
  remove: () => window.localStorage.removeItem(REFRESH_TOKEN_STORE_KEY),
  set: (refreshToken: string) => window.localStorage.setItem(REFRESH_TOKEN_STORE_KEY, refreshToken),
};

interface ILoggedInAccount {
  accountId: string;
}

interface ILogoutArgs {
  /**
   * Did the user explicitly initiate the logout request?
   */
  userInitiated?: boolean;
}

interface IAuthManagerMessage {
  type: typeof AUTH_MANAGER_SET_ACCESS_TOKEN;
  accessToken?: string;
  loggedInAccount?: ILoggedInAccount;
}

interface IAuthManager {
  accessToken: string | null;
  error: AuthorizationError | null;
  getRedirectLocation: () => Location | null;
  loading: boolean;
  loggedInAccount: ILoggedInAccount | null;
  login: (args?: { loginHint?: string; refId?: string }) => Promise<void>;
  logout: (args: ILogoutArgs) => Promise<void>;
  popRedirectLocation: () => Location | null;
  pushRedirectLocation: (location: Location) => void;
  refreshAccessToken: () => Promise<boolean>;
  requestToken: (args: ITokenRequestArgs) => Promise<void>;
  setError: (error: AuthorizationError) => void;
}

export const defaultAuthContext: IAuthManager = {
  accessToken: null,
  getRedirectLocation: () => null,
  error: null,
  loading: false,
  loggedInAccount: null,
  login: async () => undefined,
  logout: async () => undefined,
  popRedirectLocation: () => null,
  pushRedirectLocation: () => undefined,
  refreshAccessToken: async () => false,
  requestToken: async () => undefined,
  setError: () => undefined,
};

export const AuthContext = React.createContext<IAuthManager>(defaultAuthContext);

export interface IAuthProviderProps {
  clientId: string;
  issuer: string;
  redirectUri: string;
}

export default function AuthProvider({
  clientId,
  issuer,
  redirectUri,
  ...props
}: React.PropsWithChildren<IAuthProviderProps>) {
  const { startAuthorizationRequest } = useAuthorizationRequest();

  const [accessToken, setAccessToken, deleteAccessToken] = useLocalStorage<string>(ACCESS_TOKEN_STORE_KEY);

  const [error, setError] = React.useState<AuthorizationError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loggedInAccount, setLoggedInAccount, deleteLoggedInAccount] =
    useLocalStorage<ILoggedInAccount>(LOGGED_IN_ACCOUNT_STORE_KEY);

  const _cleanup = React.useCallback(() => {
    // eslint-disable-next-line no-console
    console.trace('[AuthProvider] _cleanup invoked'); // Temporary logging to understand under what conditions this is getting called (https://app.asana.com/0/1205575844742537/1208873698993391/f)

    _refreshTokenStore.remove();
    deleteAccessToken();
    deleteLoggedInAccount();
    setLoading(false);
  }, [deleteAccessToken, deleteLoggedInAccount]);

  const { authConfig } = useAuthorizationServiceConfiguration({ issuer });
  const { tokenRequest } = useTokenRequest({ authConfig, clientId, issuer, redirectUri });

  const requestToken = React.useCallback(
    async (args: ITokenRequestArgs) => {
      const response = await tokenRequest(args);
      Logger.info(`[AuthProvider] ${args.grantType} request successful`);
      _refreshTokenStore.set(response.refreshToken);
      setAccessToken(response.accessToken);
      setLoggedInAccount({ accountId: response.accountId });
    },
    [setAccessToken, setLoggedInAccount, tokenRequest]
  );

  /**
   * Attempt to refresh the current access token.
   *
   * @return true if any requests pending a token refresh should be retried.
   */
  const refreshAccessToken = React.useCallback(async (): Promise<boolean> => {
    if (IS_MOBILE_APP) {
      Logger.error('[AuthProvider] refreshAccessToken unexpectedly called from c-mobile');
      return false; // Shouldn't get here: token refresh is managed by mobile app
    }

    const refreshRequestId = crypto.randomUUID();

    // If no refresh token, cleanup and return false
    if (!_refreshTokenStore.get()) {
      Logger.debug({ refreshRequestId }, '[AuthProvider] refreshAccessToken could not find existing refreshToken...');
      _cleanup();
      return false; // Cannot retry without a new refresh token (e.g., user logged out)
    }

    // If browser is offline, wait for it to come back online before attempting the token refresh
    if (!navigator.onLine) {
      Logger.info(
        { refreshRequestId },
        '[AuthProvider] Waiting for browser to come back online before refreshing token...'
      );
      await new Promise((resolve) => window.addEventListener('online', resolve, { once: true }));
    }

    // If refresh token is already being refreshed, wait for it to complete.
    // The value stored in REFRESH_TOKEN_REFRESH_LOCK_STORE_KEY, if it exists, is the timestamp
    // of when the lock was acquired.

    const TOKEN_REFRESH_TIMEOUT_MS = 15_000;
    const CHECK_INTERVAL_MS = 100;

    const _isLocked = () => {
      return !!window.localStorage.getItem(REFRESH_TOKEN_REFRESH_LOCK_STORE_KEY);
    };

    const _isExpiring = () => {
      const timestamp = window.localStorage.getItem(REFRESH_TOKEN_REFRESH_LOCK_STORE_KEY);
      return timestamp ? Date.now() - +timestamp > TOKEN_REFRESH_TIMEOUT_MS : false;
    };

    if (_isLocked()) {
      Logger.debug({ refreshRequestId }, '[AuthProvider] Token refresh already in progress. Waiting for completion...');
    }

    while (_isLocked() && !!_refreshTokenStore.get()) {
      if (_isExpiring()) {
        // Lock is expiring. Acquired a new lock and break out of loop to refresh token
        Logger.warn({ refreshRequestId }, '[AuthProvider] Refresh lock is expiring. Acquiring a new lock...');
        break;
      }

      // Wait for a random interval to prevent thundering herd
      await new Promise((resolve) => setTimeout(resolve, CHECK_INTERVAL_MS + Math.random() * CHECK_INTERVAL_MS));

      // Check if lock has been cleared or refresh token has been cleared (e.g., logout, auth failure)
      if (!_isLocked() || !_refreshTokenStore.get()) {
        Logger.debug(
          { refreshRequestId, refreshTokenCleared: !_refreshTokenStore.get() },
          '[AuthProvider] Lock or refreshToken cleared. Exiting wait loop...'
        );
        return !!_refreshTokenStore.get();
      }
    }

    // Acquire lock
    Logger.debug({ refreshRequestId }, '[AuthProvider] Acquiring refresh lock...');
    window.localStorage.setItem(REFRESH_TOKEN_REFRESH_LOCK_STORE_KEY, Date.now().toString());

    try {
      const refreshToken = _refreshTokenStore.get();
      if (!refreshToken) {
        Logger.debug({ refreshRequestId }, '[AuthProvider] refreshAccessToken could not find existing refreshToken...');
        return false; // Cannot retry without a new refresh token (e.g., user logged out)
      }

      Logger.info({ refreshRequestId }, '[AuthProvider] Refreshing accessToken...');
      await requestToken({ grantType: GRANT_TYPE_REFRESH_TOKEN, refreshToken }); // will reset refreshTokenRef
      Logger.info({ refreshRequestId }, '[AuthProvider] Token refresh complete');
      return true; // Retry any requests that were waiting for a token refresh
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      }

      // Cleanup only if error is type AppAuthError indicating a token issue, NOT a network issue
      if (e instanceof AppAuthError) {
        Logger.info(
          { refreshRequestId, errorMessage: e.message },
          '[AuthProvider] refreshAccessToken failed with AppAuthError'
        );
        _cleanup();
        return false;
      }

      Logger.info(
        { refreshRequestId, errorMessage: (e as Error)?.message },
        '[AuthProvider] refreshAccessToken failed with non-Auth error'
      );

      // Retry any requests that were waiting for a token refresh since this
      // failure is most likely an ephemeral network issue.
      return true;
    } finally {
      setLoading(false);

      // Clear refresh lock
      Logger.debug({ refreshRequestId }, '[AuthProvider] Clearing refresh lock...');
      window.localStorage.removeItem(REFRESH_TOKEN_REFRESH_LOCK_STORE_KEY);
    }
  }, [_cleanup, requestToken]);

  const logout = React.useCallback(
    async (args: ILogoutArgs) => {
      if (IS_MOBILE_APP) {
        window._mobileBridge?.logout?.({ suspendNotifications: args.userInitiated }); // Tokens are managed by mobile app
        return;
      }

      const refreshToken = _refreshTokenStore.get();
      if (!refreshToken) {
        return;
      }

      Logger.debug({ args }, '[AuthProvider] Logging out...');

      setLoading(true);

      try {
        // Best-effort token revocation
        await new BaseTokenRequestHandler(new FetchRequestor()).performRevokeTokenRequest(
          authConfig,
          new RevokeTokenRequest({
            client_id: clientId,
            token: refreshToken,
            token_type_hint: 'refresh_token',
          })
        );
      } catch (e) {
        // swallow error (this is not critical)
        Logger.warn(e as Error, '[AuthProvider] performRevokeTokenRequest failed');
      }

      // Cleanup
      _cleanup();

      // Redirect to home page
      if (args.userInitiated) {
        window.location.replace(Paths.HOME);
      }
    },
    [authConfig, _cleanup, clientId]
  );

  const login = React.useCallback(
    async (args?: { loginHint?: string; refId?: string }) => {
      if (accessToken || loading) {
        return;
      }

      Logger.debug('[AuthProvider] Logging in...');

      setLoading(true);

      try {
        startAuthorizationRequest({
          authConfig,
          clientId,
          loginHint: args?.loginHint,
          redirectUri,
          refId: args?.refId,
        });
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        }
        setLoading(false);
      }
    },
    [authConfig, accessToken, clientId, startAuthorizationRequest, loading, redirectUri]
  );

  /**
   * Update Sentry user when idToken changes
   */
  React.useEffect(() => {
    SentryAgent.getInstance().setUser({ id: loggedInAccount?.accountId });
  }, [loggedInAccount]);

  /**
   * Inspect last location prior to logout
   */
  const getRedirectLocation = React.useCallback(() => {
    return JSON.parse(window.sessionStorage.getItem(REDIRECT_LOCATION_STORE_KEY) ?? 'null') as Location | null;
  }, []);

  /**
   * Save a last location prior to logout
   */
  const pushRedirectLocation = React.useCallback((location: Location) => {
    window.sessionStorage.setItem(REDIRECT_LOCATION_STORE_KEY, JSON.stringify(location));
  }, []);

  /**
   * Pop last location prior to most recent logout
   */
  const popRedirectLocation = React.useCallback(() => {
    const lastLocation = getRedirectLocation();
    window.sessionStorage.removeItem(REDIRECT_LOCATION_STORE_KEY);
    return lastLocation;
  }, [getRedirectLocation]);

  /**
   * Set up listener for accessToken updates originating from containing native app(s).
   */
  React.useEffect(() => {
    const _listener = (event: MessageEvent<IAuthManagerMessage>) => {
      if (event.origin !== window.location.origin) {
        // Security: invalid origin of message
        return;
      }

      const { data } = event;
      switch (data?.type) {
        case AUTH_MANAGER_SET_ACCESS_TOKEN: {
          if (!data.accessToken || !data.loggedInAccount || !data.loggedInAccount.accountId) {
            Logger.error(`[AuthManager] Invalid ${AUTH_MANAGER_SET_ACCESS_TOKEN} message`);
            return;
          }

          // Update externally communicated accessToken
          setAccessToken(data.accessToken);
          setLoggedInAccount(data.loggedInAccount);
          break;
        }

        default: {
          // Ignore
          return;
        }
      }
    };

    window.addEventListener('message', _listener);
    return () => window.removeEventListener('message', _listener);
  }, [setAccessToken, setLoggedInAccount]);

  const authManager = React.useMemo(
    () => ({
      accessToken: loggedInAccount && accessToken,
      error,
      getRedirectLocation,
      loading,
      loggedInAccount,
      login,
      logout,
      popRedirectLocation,
      pushRedirectLocation,
      refreshAccessToken,
      requestToken,
      setError,
    }),
    [
      accessToken,
      error,
      getRedirectLocation,
      loading,
      loggedInAccount,
      login,
      logout,
      popRedirectLocation,
      pushRedirectLocation,
      refreshAccessToken,
      requestToken,
    ]
  );

  return <AuthContext.Provider value={authManager}>{props.children}</AuthContext.Provider>;
}

export const useAuthManager = () => React.useContext(AuthContext);
