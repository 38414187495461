import { RTElementProp, RTElementType } from '@rmvw/x-common';
import * as Prismjs from 'prismjs';
import * as React from 'react';
import { Editor, Node, NodeEntry, Range, Element as SlateElement, Text } from 'slate';

export const useCodeHighlightDecorator = (editor: Editor): ((entry: NodeEntry) => Range[]) => {
  return React.useCallback(
    (entry: NodeEntry): Range[] => {
      const [node, path] = entry;

      const ranges: Range[] = [];

      if (SlateElement.isElement(node) && node.type === RTElementType.CODE) {
        const language = node[RTElementProp.CODE__LANGUAGE];
        if (language) {
          // Dynamically load syntax highlighting modules from prismjs
          switch (language) {
            case 'bash':
              require('prismjs/components/prism-bash');
              break;
            case 'c':
              require('prismjs/components/prism-c');
              break;
            case 'cpp':
              require('prismjs/components/prism-c'); // dependency for cpp
              require('prismjs/components/prism-cpp');
              break;
            case 'csharp':
              require('prismjs/components/prism-csharp');
              break;
            case 'java':
              require('prismjs/components/prism-java');
              break;
            case 'javascript':
              require('prismjs/components/prism-javascript');
              break;
            case 'php':
              require('prismjs/components/prism-php');
              break;
            case 'python':
              require('prismjs/components/prism-python');
              break;
            case 'ruby':
              require('prismjs/components/prism-ruby');
              break;
            case 'tsx':
              require('prismjs/components/prism-javascript'); // dependency for jsx
              require('prismjs/components/prism-jsx'); // dependency for tsx
              require('prismjs/components/prism-typescript'); // dependency for tsx
              require('prismjs/components/prism-tsx');
              break;
            case 'typescript':
              require('prismjs/components/prism-typescript');
              break;
          }

          for (const [child, childPath] of Node.children(editor, path)) {
            if (Text.isText(child)) {
              const { text } = child;

              let start = 0;
              const tokens = Prismjs.tokenize(text, Prismjs.languages[language]);
              for (const token of tokens) {
                const end = start + getTokenLength(token);
                if (typeof token !== 'string') {
                  ranges.push({
                    [`_${token.type}`]: true,
                    anchor: { path: childPath, offset: start },
                    focus: { path: childPath, offset: end },
                  });
                }
                start = end;
              }
            }
          }
        }
      }

      return ranges;
    },
    [editor]
  );
};

function getTokenLength(token: Prismjs.Token | string): number {
  if (typeof token === 'string') {
    return token.length;
  } else if (token.content instanceof Prismjs.Token || typeof token.content === 'string') {
    return getTokenLength(token.content);
  } else {
    return token.content.reduce((len, tok) => len + getTokenLength(tok), 0);
  }
}
