import { gql, useQuery } from '@apollo/client';

import { InboxFilterType } from '../___generated___/globalTypes';

import { InboxThreadCountQuery, InboxThreadCountQueryVariables } from './___generated___/useInboxThreadCount.types';

const InboxThreadCountQueryGQL = gql`
  query InboxThreadCountQuery($filter: InboxFilterType) {
    inboxThreadCount(filter: $filter)
  }
`;

export function useInboxThreadCount(skip?: boolean, filter?: InboxFilterType) {
  const { data } = useQuery<InboxThreadCountQuery, InboxThreadCountQueryVariables>(InboxThreadCountQueryGQL, {
    skip: skip,
    variables: { filter },
  });
  return data?.inboxThreadCount ?? 0;
}

export function useInboxUnreadCount(skip?: boolean) {
  return useInboxThreadCount(skip, InboxFilterType.UNREAD);
}

export function useInboxUnreadPrivateChatCount(skip?: boolean) {
  return useInboxThreadCount(skip, InboxFilterType.UNREAD_PRIVATE_CHATS);
}
