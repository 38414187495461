import { MutationHookOptions, gql, useMutation } from '@apollo/client';
import * as React from 'react';

import {
  StarThreadEventMutation,
  StarThreadEventMutationVariables,
} from './___generated___/useStarThreadEventMutation.types';

export const StarredThreadEventsFragmentGQL = gql`
  fragment StarredThreadEventsFragment on IThread {
    id
    starred {
      id
      time
    }
  }
`;

export const StarThreadEventMutationGQL = gql`
  mutation StarThreadEventMutation($input: StarThreadEventInput!) {
    starThreadEvent(input: $input) {
      id
      starred
      thread {
        ...StarredThreadEventsFragment
      }
    }
  }
  ${StarredThreadEventsFragmentGQL}
`;

export function useStarThreadEventMutation(
  options?: Omit<MutationHookOptions<StarThreadEventMutation, StarThreadEventMutationVariables>, 'variables'>
) {
  const [mutation, mutationResult] = useMutation<StarThreadEventMutation, StarThreadEventMutationVariables>(
    StarThreadEventMutationGQL
  );

  return React.useMemo(
    () =>
      [
        async (input: StarThreadEventMutationVariables['input']) => mutation({ variables: { input } }),
        mutationResult,
      ] as const,
    [mutation, mutationResult]
  );
}
