import { gql, useQuery } from '@apollo/client';

import { ProfileAvatar } from '../../../components/Avatar';
import LiveAvatar from '../../../components/PresenceBar/LiveAvatar';
import Logger from '../../../lib/observability/Logger';

import { GetShortcutsQuery, GetShortcutsQueryVariables } from './___generated___/useGetShortcutsQuery.types';

export const ShortcutTargetFragment = gql`
  fragment HF_PrivateChatTarget on PrivateChat {
    id
    hasUnread
    meeting {
      id
      isActive
    }
    name
    newRepliesCount
    members {
      id
      ...CF_LiveAvatar
    }
    permalink
    ...CF_ProfileAvatar
  }

  fragment HF_ShortcutTarget on INode {
    id
    ... on Account {
      id
      permalink
      privateChat {
        ...HF_PrivateChatTarget
      }
      ...CF_LiveAvatar
    }

    ... on Discussion {
      hasUnread
      meeting {
        id
        isActive
      }
      name
      newRepliesCount
      permalink
      ...CF_ProfileAvatar
    }

    ... on PrivateChat {
      ...HF_PrivateChatTarget
    }

    ... on Team {
      hasUnread
      meeting {
        id
        isActive
      }
      name
      newRepliesCount
      permalink
      ...CF_ProfileAvatar
    }
  }
  ${ProfileAvatar.fragment}
  ${LiveAvatar.fragment}
`;

export const ShortcutFragment = gql`
  fragment HF_Shortcut on NavShortcut {
    id
    sectionId
    target {
      ...HF_ShortcutTarget
    }
  }
  ${ShortcutTargetFragment}
`;

export const ShortcutSectionFragment = gql`
  fragment HF_ShortcutSection on NavSection {
    id
    name
    shortcuts {
      id
      ...HF_Shortcut
    }
  }
  ${ShortcutFragment}
`;

export const GetShortcutsQueryGQL = gql`
  query GetShortcutsQuery {
    account {
      id
      navConfig {
        id
        defaultSection {
          id
          ...HF_ShortcutSection
        }
        sections {
          id
          ...HF_ShortcutSection
        }
      }
      teamMemberships {
        id
        name
        ...HF_ShortcutTarget
      }
    }
  }
  ${ProfileAvatar.fragment}
  ${ShortcutSectionFragment}
`;

export type NavConfig = NonNullable<GetShortcutsQuery['account']>['navConfig'];
export type NavSection = NavConfig['defaultSection'] & {
  isDefault?: boolean;
};
export type NavShortcut = NavSection['shortcuts'][0];
export type NavShortcutTarget = NavShortcut['target'];

export function useGetShortcutsQuery() {
  const { data, error, loading } = useQuery<GetShortcutsQuery, GetShortcutsQueryVariables>(GetShortcutsQueryGQL);

  if (error) {
    Logger.info(error, '[useGetShortcutsQuery] Error fetching teams');
  }

  return { data, error, loading };
}
