export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  NonEmptyString: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AccessRequestThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'AccessRequestThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  /** @deprecated This field is not guaranteed to be in sync */
  readonly membershipStatus: Maybe<MembershipStatus>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly requestor: Account;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

/** Account object */
export type Account = INode & IProfile & {
  readonly __typename: 'Account';
  readonly activityStatus: Maybe<ActivityStatus>;
  readonly assistantSessions: ReadonlyArray<AssistantSession>;
  readonly bookmarks: Maybe<ReadonlyArray<Maybe<IThread>>>;
  /** returns whether the account can access message or thread ref'd by id */
  readonly canSee: Maybe<Scalars['Boolean']['output']>;
  readonly emojiSkinTone: EmojiSkinTone;
  readonly familyName: Scalars['String']['output'];
  readonly givenName: Scalars['String']['output'];
  readonly history: Maybe<IThreadConnection>;
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Is this account outside of the viewer's org */
  readonly isExternal: Scalars['Boolean']['output'];
  /** Is this account affiliated with the cordura organization */
  readonly isInternal: Maybe<Scalars['Boolean']['output']>;
  /** Is this account affiliated with the current session? */
  readonly isMe: Scalars['Boolean']['output'];
  readonly isPresenceEnabled: Scalars['Boolean']['output'];
  readonly locale: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly navConfig: NavConfig;
  /** @deprecated Shim for cases where we don't want '(External)' appended to the name */
  readonly nonExternalName: Scalars['String']['output'];
  readonly org: Maybe<Org>;
  /** Orgs associated with the Account */
  readonly orgs: ReadonlyArray<Org>;
  readonly permalink: Scalars['String']['output'];
  /** private chat between account & viewer */
  readonly privateChat: Maybe<PrivateChat>;
  /** private chats this account is part of */
  readonly privateChats: Maybe<ReadonlyArray<Maybe<PrivateChat>>>;
  readonly profileImage: Maybe<Image>;
  readonly readmeNote: Maybe<Note>;
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
  readonly suggestedThreads: ReadonlyArray<TypeaheadQueryResult>;
  readonly teamMemberships: ReadonlyArray<Team>;
  readonly timezone: Maybe<Scalars['String']['output']>;
};


/** Account object */
export type AccountassistantSessionsArgs = {
  contextFilters?: InputMaybe<AssistantContextFiltersInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** Account object */
export type AccountcanSeeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** Account object */
export type AccounthistoryArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Account object */
export type AccountprivateChatsArgs = {
  last?: InputMaybe<Scalars['Float']['input']>;
};


/** Account object */
export type AccountsuggestedThreadsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
};


/** Account object */
export type AccountteamMembershipsArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** Action item thread summary event */
export type ActionItemThreadSummaryEvent = INode & IThreadSummaryEvent & {
  readonly __typename: 'ActionItemThreadSummaryEvent';
  readonly accounts: ReadonlyArray<Account>;
  readonly durationSeconds: Scalars['Float']['output'];
  readonly id: Scalars['ID']['output'];
  readonly meetingOffsetSeconds: Maybe<Scalars['Float']['output']>;
  readonly summary: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

/** Account activity status */
export enum ActivityStatus {
  ACTIVE = 'ACTIVE',
  AWAY = 'AWAY',
  INACTIVE = 'INACTIVE',
  UNKNOWN = 'UNKNOWN'
}

export type AddShortcutInput = {
  readonly position?: InputMaybe<Scalars['Int']['input']>;
  readonly sectionId?: InputMaybe<Scalars['ID']['input']>;
  readonly shortcutType: NavShortcutType;
  readonly targetId: Scalars['ID']['input'];
};

export type AddTagInput = {
  readonly tagId: Scalars['ID']['input'];
  readonly taggedId: Scalars['ID']['input'];
};

export type AddTagResult = {
  readonly __typename: 'AddTagResult';
  readonly tag: Discussion;
  readonly tagged: ITaggable;
};

/** third party objects linked by url */
export type AppObject = INode & IProfile & {
  readonly __typename: 'AppObject';
  readonly app: Maybe<Application>;
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly objectData: Maybe<Scalars['JSONObject']['output']>;
  readonly objectId: Maybe<Scalars['String']['output']>;
  readonly org: Maybe<Org>;
  readonly permalink: Scalars['String']['output'];
  readonly profileImage: Maybe<Image>;
  readonly readmeNote: Maybe<Note>;
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
};

/** Application source of object info */
export enum Application {
  FB = 'FB',
  GITHUB = 'GITHUB',
  UNDEFINED = 'UNDEFINED'
}

/** Set whether thread is archived in inbox */
export type ArchiveThreadInput = {
  readonly archived?: InputMaybe<Scalars['Boolean']['input']>;
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

export type ArchiveThreadNotificationInput = {
  readonly notificationId?: InputMaybe<Scalars['ID']['input']>;
  readonly threadEventId?: InputMaybe<Scalars['ID']['input']>;
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

export type AssistantContextFilters = {
  readonly __typename: 'AssistantContextFilters';
  readonly endTime: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['ID']['output'];
  /** The set of threads to which assistant message is scoped. Empty array means global scope. */
  readonly scopedThreads: ReadonlyArray<IThread>;
  readonly startTime: Maybe<Scalars['DateTime']['output']>;
};

export type AssistantContextFiltersInput = {
  /** End time filter */
  readonly endTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Thread scope ids */
  readonly scopeIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  /** Start time filter */
  readonly startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum AssistantMessageState {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
  WAITING = 'WAITING'
}

export type AssistantSession = {
  readonly __typename: 'AssistantSession';
  readonly contextFilters: AssistantContextFilters;
  readonly id: Scalars['ID']['output'];
  readonly messages: ReadonlyArray<IAssistantMessage>;
  readonly name: Scalars['String']['output'];
};

export type AssistantTypeAssistantMessage = IAssistantMessage & INode & {
  readonly __typename: 'AssistantTypeAssistantMessage';
  readonly body: Scalars['String']['output'];
  readonly contextFilters: AssistantContextFilters;
  readonly debug: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly keywords: ReadonlyArray<Scalars['String']['output']>;
  readonly relatedQuestions: ReadonlyArray<Scalars['String']['output']>;
  readonly session: AssistantSession;
  readonly sourceSummaryChunks: ReadonlyArray<SummaryChunk>;
  readonly state: AssistantMessageState;
};

export type AudienceMemberUpdatedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'AudienceMemberUpdatedThreadEvent';
  readonly actor: Maybe<Account>;
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly members: ReadonlyArray<CanHavePermissionRole>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly status: Maybe<MembershipStatus>;
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export enum AudienceType {
  ORG = 'ORG',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type AudienceUpdatedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'AudienceUpdatedThreadEvent';
  readonly actor: Maybe<Account>;
  readonly actors: ReadonlyArray<Account>;
  readonly audience: AudienceType;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

/** MeetingParticipant heartbeat */
export type BeatMeetingParticipantHeartInput = {
  readonly participantId: Scalars['ID']['input'];
};

export type CanHavePermissionRole = Account | MaskedTeam | Org | PublicAudience | Team;

export enum CanHavePermissionRoleType {
  ACCOUNT = 'Account',
  ORG = 'Org',
  TEAM = 'Team'
}

export enum ClientAnalyticsEventType {
  MEETING_STATS = 'MEETING_STATS',
  RELOAD = 'RELOAD',
  VIEW_DISCUSSION = 'VIEW_DISCUSSION',
  VIEW_INBOX = 'VIEW_INBOX',
  VIEW_INBOX_THREAD = 'VIEW_INBOX_THREAD',
  VIEW_MEETING_RECORDING = 'VIEW_MEETING_RECORDING',
  VIEW_PRIVATE_CHAT = 'VIEW_PRIVATE_CHAT',
  VIEW_SEARCH_RESULTS = 'VIEW_SEARCH_RESULTS',
  VIEW_TEAM = 'VIEW_TEAM',
  VIEW_VIDEOS = 'VIEW_VIDEOS'
}

/** Composer draft object */
export type ComposerDraft = {
  readonly __typename: 'ComposerDraft';
  readonly id: Scalars['ID']['output'];
  readonly targetId: Maybe<Scalars['ID']['output']>;
  readonly ydoc: Maybe<YDoc>;
};

export type ConvertReplyThreadToDiscussionInput = {
  readonly subject?: InputMaybe<Scalars['String']['input']>;
  readonly threadEventId: Scalars['ID']['input'];
};

export type CreateAssistantMessageInput = {
  /** Message body (serialized RichText) */
  readonly body: Scalars['String']['input'];
  /** Filters to apply when responding to message. Will inherit from session contextFilters by default */
  readonly contextFilters?: InputMaybe<AssistantContextFiltersInput>;
  /** Assistant sessionId */
  readonly sessionId: Scalars['ID']['input'];
};

export type CreateAssistantSessionInput = {
  readonly contextFilters?: InputMaybe<AssistantContextFiltersInput>;
  /** Initial message body from the user in serialized RT format */
  readonly initialUserMessage?: InputMaybe<Scalars['String']['input']>;
  /** If true, the initial user message will be considered stealth (e.g., hidden in session history) */
  readonly initialUserMessageIsStealth?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of the assistant session */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  /** If defined, will try to re-use most recent session matching `contextFilters` active after given time */
  readonly reuseExistingSessionAfter?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Create thread input */
export type CreateDiscussionInput = {
  readonly audience?: InputMaybe<AudienceType>;
  readonly icon?: InputMaybe<DiscussionIconType>;
  readonly memberIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly messageBody?: InputMaybe<Scalars['String']['input']>;
  readonly notifyAddedMembers?: InputMaybe<Scalars['Boolean']['input']>;
  readonly originId?: InputMaybe<Scalars['ID']['input']>;
  readonly parentMessageId?: InputMaybe<Scalars['String']['input']>;
  readonly setShortcut?: InputMaybe<Scalars['Boolean']['input']>;
  readonly subject?: InputMaybe<Scalars['String']['input']>;
};

export type CreateImageFromURLInput = {
  readonly imageTemplate?: InputMaybe<ImageTemplateType>;
  readonly url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMeetingSeriesInput = {
  /** Name of MeetingSeries (optional) */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  /** IThread to which to attach this MeetingSeries (optional) */
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateMessageInput = {
  readonly body?: InputMaybe<Scalars['String']['input']>;
  readonly parentMessageId?: InputMaybe<Scalars['String']['input']>;
  readonly scheduleFor?: InputMaybe<Scalars['DateTime']['input']>;
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSectionInput = {
  readonly name: Scalars['String']['input'];
  readonly position?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTeamInput = {
  readonly audience: AudienceType;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly memberIds?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly notifyAddedMembers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateThreadNoteInput = {
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

/** Decline meeting invitation input */
export type DeclineMeetingInvitationInput = {
  readonly participantId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteMediaInput = {
  readonly id?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteMeetingRecordingInput = {
  readonly meetingId: Scalars['ID']['input'];
};

export type DeleteSectionInput = {
  readonly sectionId: Scalars['ID']['input'];
};

export type DeleteShortcutInput = {
  readonly shortcutId: Scalars['ID']['input'];
};

export type DeleteThreadEventInput = {
  readonly threadEventId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteThreadInput = {
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

/** Object Update Event object */
export type DesktopNotification = {
  readonly __typename: 'DesktopNotification';
  readonly body: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly image: Maybe<Image>;
  readonly key: Scalars['String']['output'];
  readonly targetUrl: Maybe<Scalars['String']['output']>;
  /** Relevant to NEW_THREAD_EVENT */
  readonly threadEvent: Maybe<IThreadEvent>;
  readonly title: Scalars['String']['output'];
  readonly type: NotificationType;
};

/** Discussion object */
export type Discussion = IAccountGroup & IFollowable & IHasPermissionProfile & INode & IProfile & ITaggable & IThread & {
  readonly __typename: 'Discussion';
  readonly abstract: Maybe<Scalars['String']['output']>;
  readonly accessRequests: ReadonlyArray<Account>;
  readonly archived: Maybe<Scalars['Boolean']['output']>;
  readonly audience: AudienceType;
  readonly canAssignAdminRole: Maybe<Scalars['Boolean']['output']>;
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  readonly canEditOrigin: Scalars['Boolean']['output'];
  readonly canEditPrivacy: Maybe<Scalars['Boolean']['output']>;
  readonly canEditProperties: Maybe<Scalars['Boolean']['output']>;
  readonly canRequestAccess: Maybe<Scalars['Boolean']['output']>;
  /** Canonical MeetingSeries for Thread, if one exists */
  readonly canonicalMeetingSeries: Maybe<MeetingSeries>;
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly creator: Maybe<Account>;
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly eventConnections: Maybe<ThreadEventConnection>;
  readonly externallyVisible: Scalars['Boolean']['output'];
  readonly firstMessage: Maybe<Message>;
  readonly firstUnreadMessageEvent: Maybe<MessageCreatedThreadEvent>;
  readonly followers: ReadonlyArray<Account>;
  readonly following: Maybe<Scalars['Boolean']['output']>;
  readonly hasUnread: Scalars['Boolean']['output'];
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly inboxed: Maybe<Scalars['Boolean']['output']>;
  readonly isAdmin: Maybe<Scalars['Boolean']['output']>;
  readonly isChild: Scalars['Boolean']['output'];
  readonly isPR: Maybe<Scalars['Boolean']['output']>;
  readonly joinMeetingPath: Maybe<Scalars['String']['output']>;
  readonly lastEvent: Maybe<IThreadEvent>;
  readonly lastEventAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastMeeting: Maybe<Meeting>;
  readonly lastMessage: Maybe<Message>;
  readonly lastMessageAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastVisitedAt: Maybe<Scalars['DateTime']['output']>;
  readonly length: Maybe<Scalars['Float']['output']>;
  readonly meeting: Maybe<Meeting>;
  readonly membership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly messageCount: Scalars['Float']['output'];
  readonly name: Scalars['String']['output'];
  readonly newDiscussionCount: Maybe<Scalars['Float']['output']>;
  readonly newMentionCount: Scalars['Float']['output'];
  readonly newMessageCount: Maybe<Scalars['Float']['output']>;
  readonly newRepliesCount: Scalars['Float']['output'];
  readonly newVideoCount: Maybe<Scalars['Float']['output']>;
  readonly notes: Maybe<ReadonlyArray<Maybe<Note>>>;
  readonly notifications: Maybe<ReadonlyArray<Maybe<ThreadNotification>>>;
  readonly org: Maybe<Org>;
  readonly parent: Maybe<IThread>;
  readonly parents: Maybe<ReadonlyArray<Maybe<IThread>>>;
  readonly participants: ReadonlyArray<Account>;
  readonly permalink: Scalars['String']['output'];
  readonly permissions: DiscussionPermissionProfile;
  readonly presence: Maybe<PresenceState>;
  readonly present: ReadonlyArray<Account>;
  readonly profileImage: Maybe<Image>;
  readonly readmeNote: Maybe<Note>;
  readonly requests: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly resourceConnections: Maybe<ThreadResourceConnection>;
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
  readonly starred: ReadonlyArray<IThreadEvent>;
  readonly startingMessage: Maybe<Message>;
  readonly subDiscussions: IThreadConnection;
  readonly subject: Maybe<Scalars['String']['output']>;
  readonly summaryEventConnections: ThreadSummaryEventConnection;
  readonly summaryEventGroupConnections: ThreadSummaryEventGroupConnection;
  /** @deprecated Tags are influx -- do not use until vanilla tags */
  readonly tagged: ReadonlyArray<ITaggable>;
  readonly tags: ReadonlyArray<Discussion>;
  readonly typing: ReadonlyArray<Account>;
  readonly unreadMessageCount: Scalars['Float']['output'];
  readonly videoCount: Scalars['Float']['output'];
};


/** Discussion object */
export type DiscussioneventConnectionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<ReadonlyArray<ThreadEventType>>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Discussion object */
export type DiscussionisChildArgs = {
  threadId?: InputMaybe<Scalars['ID']['input']>;
};


/** Discussion object */
export type DiscussionlastEventArgs = {
  includeReplies?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Discussion object */
export type DiscussionresourceConnectionsArgs = {
  filters?: InputMaybe<ThreadResourcesFiltersInput>;
  page?: InputMaybe<PaginationArgsInput>;
  sort?: InputMaybe<ThreadResourcesSortInput>;
};


/** Discussion object */
export type DiscussionsubDiscussionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Discussion object */
export type DiscussionsummaryEventConnectionsArgs = {
  filter?: InputMaybe<SummaryEventConnectionsFilterInput>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Discussion object */
export type DiscussionsummaryEventGroupConnectionsArgs = {
  page?: InputMaybe<PaginationArgsInput>;
};

export type DiscussionCreatedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'DiscussionCreatedThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly convertedFrom: Maybe<IThreadEvent>;
  readonly discussion: Maybe<Discussion>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly permissionScopes: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export enum DiscussionIconType {
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  DEFAULT = 'DEFAULT',
  IDEA = 'IDEA',
  IMPORTANT = 'IMPORTANT',
  ISSUE = 'ISSUE',
  MAIL = 'MAIL',
  MEETING = 'MEETING',
  QUESTION = 'QUESTION',
  RANDOM = 'RANDOM',
  TAG = 'TAG',
  TEAM = 'TEAM'
}

/** Permission Profile for a Discussion */
export type DiscussionPermissionProfile = INode & IPermissionProfile & {
  readonly __typename: 'DiscussionPermissionProfile';
  readonly canAssignAdminRole: Maybe<Scalars['Boolean']['output']>;
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  readonly canEditOrigin: Scalars['Boolean']['output'];
  readonly canEditPrivacy: Maybe<Scalars['Boolean']['output']>;
  readonly canEditProperties: Scalars['Boolean']['output'];
  readonly canRequestAccess: Maybe<Scalars['Boolean']['output']>;
  readonly grants: ReadonlyArray<PermissionGrant>;
  readonly id: Scalars['ID']['output'];
  readonly isAdmin: Maybe<Scalars['Boolean']['output']>;
  readonly requests: ReadonlyArray<PermissionGrant>;
  readonly role: Maybe<MembershipStatus>;
  readonly target: Maybe<Discussion>;
};

export type DiscussionRenamedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'DiscussionRenamedThreadEvent';
  readonly actor: Maybe<Account>;
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly previousName: Scalars['String']['output'];
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
  readonly updatedName: Scalars['String']['output'];
};

/** Event documenting the creation context for a Discussion. */
export type DiscussionStartThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'DiscussionStartThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly discussion: Discussion;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  /** Thread in which this discussion was created.  Note that the currently assigned origin of the discussion may be different */
  readonly origin: Maybe<IThread>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type EditMessageInput = {
  readonly body?: InputMaybe<Scalars['String']['input']>;
  readonly messageId?: InputMaybe<Scalars['ID']['input']>;
};

export type EditResourceLabelInput = {
  readonly label: Scalars['ID']['input'];
  readonly threadResourceId: Scalars['ID']['input'];
};

/** Account default emoji skin tone */
export enum EmojiSkinTone {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  MEDIUM = 'MEDIUM',
  MEDIUM_DARK = 'MEDIUM_DARK',
  MEDIUM_LIGHT = 'MEDIUM_LIGHT',
  NEUTRAL = 'NEUTRAL'
}

/** File object */
export type File = {
  readonly __typename: 'File';
  readonly bytes: Maybe<Scalars['Int']['output']>;
  readonly contentType: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly url: Maybe<Scalars['String']['output']>;
};

export type FileUpload = File | Image | Media;

export type FileUploadInput = {
  readonly files: ReadonlyArray<Scalars['Upload']['input']>;
  readonly imageTemplate?: InputMaybe<ImageTemplateType>;
};

/** Set whether user is following thread */
export type FollowThreadInput = {
  readonly following?: InputMaybe<Scalars['Boolean']['input']>;
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

/** arguments for fetching inbox */
export type GetInboxArgs = {
  readonly filter?: InputMaybe<InboxFilterType>;
  readonly page?: InputMaybe<PaginationArgsInput>;
  readonly parentThreadId?: InputMaybe<Scalars['ID']['input']>;
};

/** Get viewer's membership status for a group */
export type GetMembershipStatusInput = {
  readonly groupId: Scalars['ID']['input'];
  readonly groupType?: InputMaybe<Scalars['String']['input']>;
};

export type GrantEntityInput = {
  readonly id: Scalars['ID']['input'];
  readonly type: CanHavePermissionRoleType;
};

/** Describes entity's membership status for a group */
export type GrantInput = {
  readonly entity: GrantEntityInput;
  readonly role?: InputMaybe<MembershipStatus>;
  readonly target: GrantTargetInput;
};

export type GrantTargetInput = {
  readonly id: Scalars['ID']['input'];
  readonly type: HasPermissionProfileEnum;
};

export type GroupMembership = {
  readonly __typename: 'GroupMembership';
  readonly account: Account;
  readonly group: IAccountGroup;
  readonly status: Maybe<MembershipStatus>;
};

export enum HasPermissionProfileEnum {
  DISCUSSION = 'Discussion',
  MEETING = 'Meeting',
  MEETINGSERIES = 'MeetingSeries',
  TEAM = 'Team'
}

/** IAccountGroupGQLType interface represent an entity which has some notion of membership */
export type IAccountGroup = {
  readonly canAssignAdminRole: Maybe<Scalars['Boolean']['output']>;
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  readonly canEditPrivacy: Maybe<Scalars['Boolean']['output']>;
  readonly canEditProperties: Maybe<Scalars['Boolean']['output']>;
  readonly canRequestAccess: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly isAdmin: Maybe<Scalars['Boolean']['output']>;
  readonly membership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly requests: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
};

export type IAssistantMessage = {
  /** The body of the message in RichText format */
  readonly body: Scalars['String']['output'];
  /** Context filters applicable to this message */
  readonly contextFilters: AssistantContextFilters;
  readonly id: Scalars['ID']['output'];
  readonly session: AssistantSession;
  readonly state: AssistantMessageState;
};

export type IFollowable = {
  readonly followers: ReadonlyArray<Account>;
  readonly following: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
};

/** IHasPermissionProfile interface represents an entity that has a set of grants governing access to it */
export type IHasPermissionProfile = {
  readonly id: Scalars['ID']['output'];
  readonly permissions: IPermissionProfile;
};

export type INode = {
  readonly id: Scalars['ID']['output'];
};

/** Permission grants, role assignment, and permission flags for a target */
export type IPermissionProfile = {
  readonly canAssignAdminRole: Maybe<Scalars['Boolean']['output']>;
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  readonly canEditPrivacy: Maybe<Scalars['Boolean']['output']>;
  readonly canRequestAccess: Maybe<Scalars['Boolean']['output']>;
  readonly grants: ReadonlyArray<PermissionGrant>;
  readonly id: Scalars['ID']['output'];
  readonly isAdmin: Maybe<Scalars['Boolean']['output']>;
  readonly requests: ReadonlyArray<PermissionGrant>;
  readonly role: Maybe<MembershipStatus>;
  readonly target: Maybe<IHasPermissionProfile>;
};

export type IProfile = {
  /** Icon for entity */
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Name of entity */
  readonly name: Scalars['String']['output'];
  /** Org to which entity belongs */
  readonly org: Maybe<Org>;
  /** Permalink for entity */
  readonly permalink: Scalars['String']['output'];
  /** Profile image for entity */
  readonly profileImage: Maybe<Image>;
  /** README Note for entity */
  readonly readmeNote: Maybe<Note>;
  /** Returns a list of shortcuts to this profile for the viewer */
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
};

export type ITaggable = {
  /** Icon for entity */
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Name of entity */
  readonly name: Scalars['String']['output'];
  /** Org to which entity belongs */
  readonly org: Maybe<Org>;
  /** Permalink for entity */
  readonly permalink: Scalars['String']['output'];
  /** Profile image for entity */
  readonly profileImage: Maybe<Image>;
  /** README Note for entity */
  readonly readmeNote: Maybe<Note>;
  /** Returns a list of shortcuts to this profile for the viewer */
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
  readonly tags: Maybe<ReadonlyArray<Discussion>>;
};

export type IThread = {
  readonly abstract: Maybe<Scalars['String']['output']>;
  readonly archived: Maybe<Scalars['Boolean']['output']>;
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  /** Canonical MeetingSeries for Thread, if one exists */
  readonly canonicalMeetingSeries: Maybe<MeetingSeries>;
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly cursor: Maybe<Scalars['String']['output']>;
  /** Email address for this Thread */
  readonly email: Maybe<Scalars['String']['output']>;
  readonly eventConnections: Maybe<ThreadEventConnection>;
  readonly externallyVisible: Scalars['Boolean']['output'];
  readonly firstMessage: Maybe<Message>;
  readonly firstUnreadMessageEvent: Maybe<MessageCreatedThreadEvent>;
  readonly followers: ReadonlyArray<Account>;
  readonly following: Maybe<Scalars['Boolean']['output']>;
  readonly hasUnread: Scalars['Boolean']['output'];
  /** Icon for entity */
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly inboxed: Maybe<Scalars['Boolean']['output']>;
  readonly isPR: Maybe<Scalars['Boolean']['output']>;
  readonly lastEvent: Maybe<IThreadEvent>;
  readonly lastEventAt: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use `canonicalMeetingSeries` instead */
  readonly lastMeeting: Maybe<Meeting>;
  readonly lastMessage: Maybe<Message>;
  readonly lastMessageAt: Maybe<Scalars['DateTime']['output']>;
  /** The last time the viewer visited this Thread */
  readonly lastVisitedAt: Maybe<Scalars['DateTime']['output']>;
  readonly length: Maybe<Scalars['Float']['output']>;
  /**
   * The active Meeting on the Thread, if any.
   * @deprecated Use `canonicalMeetingSeries` instead
   */
  readonly meeting: Maybe<Meeting>;
  readonly messageCount: Scalars['Float']['output'];
  /** Name of entity */
  readonly name: Scalars['String']['output'];
  readonly newDiscussionCount: Maybe<Scalars['Float']['output']>;
  readonly newMentionCount: Scalars['Float']['output'];
  /** number of messages since user last visited, undefined if never visited */
  readonly newMessageCount: Maybe<Scalars['Float']['output']>;
  readonly newRepliesCount: Scalars['Float']['output'];
  readonly newVideoCount: Maybe<Scalars['Float']['output']>;
  readonly notes: Maybe<ReadonlyArray<Maybe<Note>>>;
  readonly notifications: Maybe<ReadonlyArray<Maybe<ThreadNotification>>>;
  /** Org to which entity belongs */
  readonly org: Maybe<Org>;
  readonly parent: Maybe<IThread>;
  readonly parents: Maybe<ReadonlyArray<Maybe<IThread>>>;
  readonly participants: ReadonlyArray<Account>;
  /** Permalink for entity */
  readonly permalink: Scalars['String']['output'];
  readonly presence: Maybe<PresenceState>;
  readonly present: ReadonlyArray<Account>;
  /** Profile image for entity */
  readonly profileImage: Maybe<Image>;
  /** README Note for entity */
  readonly readmeNote: Maybe<Note>;
  readonly resourceConnections: Maybe<ThreadResourceConnection>;
  /** Returns a list of shortcuts to this profile for the viewer */
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
  readonly starred: ReadonlyArray<IThreadEvent>;
  readonly startingMessage: Maybe<Message>;
  /** @deprecated Not actively supported.  Use resourceConnections instead. */
  readonly subDiscussions: IThreadConnection;
  readonly summaryEventConnections: ThreadSummaryEventConnection;
  readonly summaryEventGroupConnections: ThreadSummaryEventGroupConnection;
  readonly typing: ReadonlyArray<Account>;
  /** number of unread messages since user subscribed */
  readonly unreadMessageCount: Scalars['Float']['output'];
  readonly videoCount: Scalars['Float']['output'];
};


export type IThreadeventConnectionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<ReadonlyArray<ThreadEventType>>;
};


export type IThreadlastEventArgs = {
  includeReplies?: InputMaybe<Scalars['Boolean']['input']>;
};


export type IThreadresourceConnectionsArgs = {
  filters?: InputMaybe<ThreadResourcesFiltersInput>;
  page?: InputMaybe<PaginationArgsInput>;
  sort?: InputMaybe<ThreadResourcesSortInput>;
};


export type IThreadsubDiscussionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
};


export type IThreadsummaryEventGroupConnectionsArgs = {
  page?: InputMaybe<PaginationArgsInput>;
};

export type IThreadConnection = {
  readonly __typename: 'IThreadConnection';
  readonly edges: ReadonlyArray<IThreadEdge>;
  readonly pageInfo: PageInfo;
};

export type IThreadEdge = {
  readonly __typename: 'IThreadEdge';
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String']['output'];
  readonly hasNextPage: Maybe<Scalars['Boolean']['output']>;
  readonly hasPreviousPage: Maybe<Scalars['Boolean']['output']>;
  readonly node: IThread;
};

export type IThreadEvent = {
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type IThreadSummaryEvent = {
  readonly durationSeconds: Scalars['Float']['output'];
  readonly id: Scalars['ID']['output'];
  /** Applicable only for Meeting threads, otherwise null */
  readonly meetingOffsetSeconds: Maybe<Scalars['Float']['output']>;
};

export type IThreadSummaryEventGroup = {
  readonly durationSeconds: Scalars['Float']['output'];
  /** ThreadSummaryEvents that roll up to this IThreadSummaryGroup */
  readonly eventConnections: ThreadSummaryEventConnection;
  readonly id: Scalars['ID']['output'];
  /** Applicable only for Meeting threads, otherwise null */
  readonly meetingOffsetSeconds: Maybe<Scalars['Float']['output']>;
  readonly title: Scalars['String']['output'];
};


export type IThreadSummaryEventGroupeventConnectionsArgs = {
  page?: InputMaybe<PaginationArgsInput>;
};

/** Image object */
export type Image = {
  readonly __typename: 'Image';
  readonly downloadUrl: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly url_64: Maybe<Scalars['String']['output']>;
  readonly url_128: Maybe<Scalars['String']['output']>;
  readonly url_256: Maybe<Scalars['String']['output']>;
  readonly url_512: Maybe<Scalars['String']['output']>;
  readonly url_1024: Maybe<Scalars['String']['output']>;
  readonly url_2048: Maybe<Scalars['String']['output']>;
  readonly url_4096: Maybe<Scalars['String']['output']>;
};

export enum ImageTemplateType {
  DEFAULT = 'DEFAULT',
  IMAGE_ATTACHMENT = 'IMAGE_ATTACHMENT',
  NOOP = 'NOOP',
  PROFILE_PHOTO = 'PROFILE_PHOTO'
}

/** Type of filter to apply to threads in inbox */
export enum InboxFilterType {
  ALL = 'ALL',
  ARCHIVED = 'ARCHIVED',
  MEETINGS = 'MEETINGS',
  MENTIONS = 'MENTIONS',
  PRIVATE_CHATS = 'PRIVATE_CHATS',
  REPLIES = 'REPLIES',
  STARRED = 'STARRED',
  UNREAD = 'UNREAD',
  UNREAD_MENTIONS = 'UNREAD_MENTIONS',
  UNREAD_PRIVATE_CHATS = 'UNREAD_PRIVATE_CHATS'
}

/** Invite meeting participant input */
export type InviteMeetingParticipantInput = {
  readonly accountId: Scalars['ID']['input'];
  readonly meetingId: Scalars['ID']['input'];
};

/** Join meeting input */
export type JoinMeetingInput = {
  /** Invite all members of thread to meeting upon creation */
  readonly inviteMembers?: InputMaybe<Scalars['Boolean']['input']>;
  /** Join by Meeting id */
  readonly meetingId?: InputMaybe<Scalars['ID']['input']>;
  /** Join by MeetingSeries code */
  readonly seriesCode?: InputMaybe<Scalars['ID']['input']>;
  /** Join by Thread id */
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

/** Leave meeting input */
export type LeaveMeetingInput = {
  readonly participantId: Scalars['ID']['input'];
};

/** web links */
export type Link = {
  readonly __typename: 'Link';
  readonly appObject: Maybe<AppObject>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly image: Maybe<Image>;
  /** Alias of title */
  readonly name: Scalars['String']['output'];
  readonly processed: Maybe<Scalars['Boolean']['output']>;
  readonly title: Scalars['String']['output'];
  readonly url: Scalars['String']['output'];
};

export type LogEventInput = {
  readonly data?: InputMaybe<Scalars['String']['input']>;
  readonly event: ClientAnalyticsEventType;
};

export type MarkPresentInput = {
  readonly saveRecentInteraction?: InputMaybe<Scalars['Boolean']['input']>;
  readonly state: PresenceState;
  readonly threadId: Scalars['ID']['input'];
};

export type MarkThreadEventReadInput = {
  readonly readAt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly threadEventId?: InputMaybe<Scalars['ID']['input']>;
};

/** Marks all events and reply threads as read */
export type MarkThreadReadInput = {
  readonly readAt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

/** A private Team that the viewer does not belong to but exposes membership */
export type MaskedTeam = IAccountGroup & INode & IProfile & {
  readonly __typename: 'MaskedTeam';
  readonly canAssignAdminRole: Maybe<Scalars['Boolean']['output']>;
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  readonly canEditPrivacy: Maybe<Scalars['Boolean']['output']>;
  readonly canEditProperties: Maybe<Scalars['Boolean']['output']>;
  readonly canRequestAccess: Maybe<Scalars['Boolean']['output']>;
  /** Icon for entity */
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly isAdmin: Maybe<Scalars['Boolean']['output']>;
  readonly membership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  /** Name of entity */
  readonly name: Scalars['String']['output'];
  /** Org to which entity belongs */
  readonly org: Maybe<Org>;
  /** Permalink for entity */
  readonly permalink: Scalars['String']['output'];
  /** Profile image for entity */
  readonly profileImage: Maybe<Image>;
  /** README Note for entity */
  readonly readmeNote: Maybe<Note>;
  readonly requests: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  /** Returns a list of shortcuts to this profile for the viewer */
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
};

/** Media object */
export type Media = {
  readonly __typename: 'Media';
  readonly downloadUrl: Maybe<Scalars['String']['output']>;
  /** Duration in seconds */
  readonly duration: Maybe<Scalars['Float']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly previewImage: Maybe<Image>;
  readonly thumbnails: Maybe<ReadonlyArray<Maybe<MediaThumbnail>>>;
  readonly type: Maybe<MediaType>;
  readonly url: Maybe<Scalars['String']['output']>;
};


/** Media object */
export type MediapreviewImageArgs = {
  offsetSeconds?: InputMaybe<Scalars['Int']['input']>;
};


/** Media object */
export type MediathumbnailsArgs = {
  first?: InputMaybe<Scalars['Float']['input']>;
  range?: InputMaybe<ThumbnailOffsetRangeInput>;
  type?: InputMaybe<MediaThumbnailType>;
};

/** Media thumbnail */
export type MediaThumbnail = {
  readonly __typename: 'MediaThumbnail';
  readonly id: Maybe<Scalars['ID']['output']>;
  readonly image: Maybe<Image>;
  readonly media: Maybe<Media>;
  readonly offsetSec: Maybe<Scalars['Float']['output']>;
  readonly type: Maybe<MediaThumbnailType>;
};

export enum MediaThumbnailType {
  INTERVAL = 'INTERVAL',
  KEYFRAME = 'KEYFRAME'
}

export enum MediaType {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO'
}

/** Meeting object */
export type Meeting = IAccountGroup & IFollowable & INode & IProfile & ITaggable & IThread & {
  readonly __typename: 'Meeting';
  readonly abstract: Maybe<Scalars['String']['output']>;
  readonly archived: Maybe<Scalars['Boolean']['output']>;
  readonly audience: AudienceType;
  readonly canAssignAdminRole: Maybe<Scalars['Boolean']['output']>;
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  readonly canEditPrivacy: Maybe<Scalars['Boolean']['output']>;
  readonly canEditProperties: Maybe<Scalars['Boolean']['output']>;
  readonly canInviteParticipants: Scalars['Boolean']['output'];
  readonly canJoin: Scalars['Boolean']['output'];
  readonly canRequestAccess: Maybe<Scalars['Boolean']['output']>;
  readonly canonicalMeetingSeries: Maybe<MeetingSeries>;
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly endTime: Maybe<Scalars['DateTime']['output']>;
  readonly eventConnections: Maybe<ThreadEventConnection>;
  readonly events: Maybe<ReadonlyArray<Maybe<MeetingEvent>>>;
  readonly externallyVisible: Scalars['Boolean']['output'];
  readonly firstMessage: Maybe<Message>;
  readonly firstUnreadMessageEvent: Maybe<MessageCreatedThreadEvent>;
  readonly followers: ReadonlyArray<Account>;
  readonly following: Maybe<Scalars['Boolean']['output']>;
  readonly hasUnread: Scalars['Boolean']['output'];
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly inboxed: Maybe<Scalars['Boolean']['output']>;
  readonly isActive: Scalars['Boolean']['output'];
  readonly isAdmin: Maybe<Scalars['Boolean']['output']>;
  readonly isPR: Maybe<Scalars['Boolean']['output']>;
  readonly joinMeetingPath: Scalars['String']['output'];
  readonly lastEvent: Maybe<IThreadEvent>;
  readonly lastEventAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastMeeting: Maybe<Meeting>;
  readonly lastMessage: Maybe<Message>;
  readonly lastMessageAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastVisitedAt: Maybe<Scalars['DateTime']['output']>;
  readonly length: Maybe<Scalars['Float']['output']>;
  readonly livestreamUrl: Maybe<Scalars['String']['output']>;
  readonly media: Maybe<Media>;
  readonly meeting: Maybe<Meeting>;
  /**
   * Participants in the meeting video channel
   * @deprecated Implement and use a paginated version of this field instead!
   */
  readonly meetingParticipants: Maybe<ReadonlyArray<Maybe<MeetingParticipant>>>;
  readonly meetingStartedEvent: Maybe<IThreadEvent>;
  readonly membership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly messageCount: Scalars['Float']['output'];
  readonly name: Scalars['String']['output'];
  readonly newDiscussionCount: Maybe<Scalars['Float']['output']>;
  readonly newMentionCount: Scalars['Float']['output'];
  readonly newMessageCount: Maybe<Scalars['Float']['output']>;
  readonly newRepliesCount: Scalars['Float']['output'];
  readonly newVideoCount: Maybe<Scalars['Float']['output']>;
  readonly notes: Maybe<ReadonlyArray<Maybe<Note>>>;
  readonly notifications: Maybe<ReadonlyArray<Maybe<ThreadNotification>>>;
  readonly org: Maybe<Org>;
  readonly parent: Maybe<IThread>;
  readonly parents: Maybe<ReadonlyArray<Maybe<IThread>>>;
  readonly participants: ReadonlyArray<Account>;
  readonly permalink: Scalars['String']['output'];
  readonly presence: Maybe<PresenceState>;
  readonly present: ReadonlyArray<Account>;
  readonly profileImage: Maybe<Image>;
  readonly providerJSON: Maybe<Scalars['String']['output']>;
  readonly providerType: Maybe<Scalars['ID']['output']>;
  readonly readmeNote: Maybe<Note>;
  readonly recordingEnabled: Maybe<Scalars['Boolean']['output']>;
  /**
   * in-meeting chat
   * @deprecated This field is redundant; meeting.replyThread == meeting.
   */
  readonly replyThread: IThread;
  readonly requests: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly resourceConnections: Maybe<ThreadResourceConnection>;
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
  /** The MeetingSeries from which this Meeting was spawned */
  readonly sourceMeetingSeries: Maybe<MeetingSeries>;
  readonly starred: ReadonlyArray<IThreadEvent>;
  readonly startTime: Maybe<Scalars['DateTime']['output']>;
  readonly startingMessage: Maybe<Message>;
  readonly subDiscussions: IThreadConnection;
  readonly summaryEventConnections: ThreadSummaryEventConnection;
  readonly summaryEventGroupConnections: ThreadSummaryEventGroupConnection;
  readonly tags: ReadonlyArray<Discussion>;
  readonly typing: ReadonlyArray<Account>;
  readonly unreadMessageCount: Scalars['Float']['output'];
  readonly videoCount: Scalars['Float']['output'];
};


/** Meeting object */
export type MeetingeventConnectionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<ReadonlyArray<ThreadEventType>>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Meeting object */
export type MeetingeventsArgs = {
  filters?: InputMaybe<MeetingEventFilterInput>;
};


/** Meeting object */
export type MeetinglastEventArgs = {
  includeReplies?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Meeting object */
export type MeetingresourceConnectionsArgs = {
  filters?: InputMaybe<ThreadResourcesFiltersInput>;
  page?: InputMaybe<PaginationArgsInput>;
  sort?: InputMaybe<ThreadResourcesSortInput>;
};


/** Meeting object */
export type MeetingsubDiscussionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Meeting object */
export type MeetingsummaryEventConnectionsArgs = {
  filter?: InputMaybe<SummaryEventConnectionsFilterInput>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Meeting object */
export type MeetingsummaryEventGroupConnectionsArgs = {
  page?: InputMaybe<PaginationArgsInput>;
};

export type MeetingCreatedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'MeetingCreatedThreadEvent';
  readonly actor: Maybe<Account>;
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly meeting: Maybe<Meeting>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type MeetingEndedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'MeetingEndedThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly meeting: Maybe<Meeting>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type MeetingEvent = MeetingEventActiveSpeaker | MeetingEventContentShare | MeetingEventTranscript;

/** MeetingEvent: active speaker */
export type MeetingEventActiveSpeaker = {
  readonly __typename: 'MeetingEventActiveSpeaker';
  /** Duration of event, in seconds */
  readonly duration: Maybe<Scalars['Float']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Start of event relative to start of meeting in seconds */
  readonly offsetSeconds: Maybe<Scalars['Float']['output']>;
  readonly participant: Maybe<MeetingParticipant>;
};

/** MeetingEvent: content share */
export type MeetingEventContentShare = {
  readonly __typename: 'MeetingEventContentShare';
  /** Duration of event, in seconds */
  readonly duration: Maybe<Scalars['Float']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Start of event relative to start of meeting in seconds */
  readonly offsetSeconds: Maybe<Scalars['Float']['output']>;
  readonly participant: Maybe<MeetingParticipant>;
};

export type MeetingEventFilterInput = {
  readonly type?: InputMaybe<ReadonlyArray<MeetingEventType>>;
};

/** MeetingEvent: transcript */
export type MeetingEventTranscript = {
  readonly __typename: 'MeetingEventTranscript';
  /** Duration of event, in seconds */
  readonly duration: Maybe<Scalars['Float']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Start of event relative to start of meeting in seconds */
  readonly offsetSeconds: Maybe<Scalars['Float']['output']>;
  readonly participant: Maybe<MeetingParticipant>;
  /** Time of event */
  readonly time: Maybe<Scalars['DateTime']['output']>;
  /** Meeting transcript */
  readonly transcript: Maybe<Scalars['String']['output']>;
};

export enum MeetingEventType {
  ACTIVE_SPEAKER = 'ACTIVE_SPEAKER',
  CONTENT_SHARE = 'CONTENT_SHARE',
  TRANSCRIPT = 'TRANSCRIPT'
}

/** MeetingParticipant object */
export type MeetingParticipant = {
  readonly __typename: 'MeetingParticipant';
  readonly account: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly meeting: Maybe<Meeting>;
  readonly providerJSON: Maybe<Scalars['String']['output']>;
  readonly providerType: Maybe<Scalars['ID']['output']>;
  readonly state: Maybe<MeetingParticipantState>;
};

export enum MeetingParticipantState {
  CONNECTING = 'CONNECTING',
  DECLINED = 'DECLINED',
  INVITED = 'INVITED',
  JOINED = 'JOINED',
  LEFT = 'LEFT'
}

export type MeetingParticipantsJoinedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'MeetingParticipantsJoinedThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly meeting: Maybe<Meeting>;
  readonly meetingParticipants: Maybe<ReadonlyArray<Maybe<MeetingParticipant>>>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type MeetingParticipantsLeftThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'MeetingParticipantsLeftThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly meeting: Maybe<Meeting>;
  readonly meetingParticipants: Maybe<ReadonlyArray<Maybe<MeetingParticipant>>>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

/** MeetingSeries object */
export type MeetingSeries = INode & {
  readonly __typename: 'MeetingSeries';
  /** The active Meeting on the MeetingSeries, if any */
  readonly activeMeeting: Maybe<Meeting>;
  readonly canJoin: Scalars['Boolean']['output'];
  readonly code: Scalars['ID']['output'];
  readonly creator: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  /** True if this MeetingSeries is the canonical series for associated Thread */
  readonly isCanonical: Scalars['Boolean']['output'];
  readonly joinMeetingPath: Scalars['String']['output'];
  /** The last completed Meeting on the MeetingSeries, if any */
  readonly lastMeeting: Maybe<Meeting>;
  readonly name: Scalars['String']['output'];
  /** The Thread associated with MeetingSeries, if any */
  readonly thread: Maybe<IThread>;
};

export type MeetingStartedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'MeetingStartedThreadEvent';
  readonly actor: Maybe<Account>;
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly meeting: Maybe<Meeting>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type MeetingsQueryInput = {
  /** Return only meetings that have ended */
  readonly ended?: InputMaybe<Scalars['Boolean']['input']>;
  readonly limit?: InputMaybe<Scalars['Float']['input']>;
};

/** Account membership status for a group */
export type MembershipInput = {
  readonly accountId: Scalars['ID']['input'];
  readonly groupId: Scalars['ID']['input'];
  readonly groupType: Scalars['String']['input'];
  readonly status?: InputMaybe<MembershipStatus>;
};

/** Account's membership status in a team */
export enum MembershipStatus {
  ADMIN = 'ADMIN',
  DENIED = 'DENIED',
  MEMBER = 'MEMBER',
  REQUESTED = 'REQUESTED'
}

export type MentionedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'MentionedThreadEvent';
  readonly actor: Maybe<Account>;
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly mentioned: Maybe<IProfile>;
  readonly message: Maybe<Message>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
  readonly where: Maybe<IProfile>;
};

/** Message object */
export type Message = {
  readonly __typename: 'Message';
  readonly body: Maybe<Scalars['String']['output']>;
  readonly bodyType: Maybe<MessageBodyType>;
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly creator: Maybe<Account>;
  readonly date: Maybe<Scalars['DateTime']['output']>;
  readonly editedAt: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly org: Maybe<Org>;
  readonly permalink: Maybe<Scalars['String']['output']>;
  readonly source: Maybe<MessageSource>;
  readonly thread: Maybe<IThread>;
  readonly threadEvent: Maybe<IThreadEvent>;
};

export enum MessageBodyType {
  RICHTEXT = 'RICHTEXT'
}

export type MessageCreatedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'MessageCreatedThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly message: Maybe<Message>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type MessageScheduledThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'MessageScheduledThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly message: Maybe<Message>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly scheduledFor: Maybe<Scalars['DateTime']['output']>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export enum MessageSource {
  EMAIL = 'EMAIL'
}

export type MoveShortcutInput = {
  readonly position: Scalars['Int']['input'];
  readonly sectionId: Scalars['ID']['input'];
  readonly shortcutId: Scalars['ID']['input'];
};

export type MoveThreadEventInput = {
  readonly threadEventId: Scalars['ID']['input'];
  readonly toThreadId: Scalars['ID']['input'];
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly addReaction: Maybe<IThreadEvent>;
  readonly addShortcut: Maybe<NavConfig>;
  readonly addTag: ReadonlyArray<AddTagResult>;
  readonly archiveThread: Maybe<IThread>;
  readonly archiveThreadNotification: Maybe<ReadonlyArray<Maybe<ThreadNotification>>>;
  readonly beatHeart: Maybe<Account>;
  readonly beatMeetingParticipantHeart: Maybe<MeetingParticipant>;
  readonly convertReplyThreadToDiscussion: Maybe<IThreadEvent>;
  readonly createAssistantMessage: IAssistantMessage;
  readonly createAssistantSession: AssistantSession;
  readonly createDiscussion: Maybe<Discussion>;
  readonly createImageFromURL: Maybe<Image>;
  readonly createMeetingSeries: Maybe<MeetingSeries>;
  readonly createMessage: Message;
  readonly createSection: Maybe<NavConfig>;
  readonly createTeam: Maybe<Team>;
  readonly createThreadNote: Maybe<Note>;
  readonly declineMeetingInvitation: Maybe<MeetingParticipant>;
  readonly deleteAccount: Maybe<Account>;
  readonly deleteMedia: Maybe<Media>;
  readonly deleteMeetingRecording: Maybe<Meeting>;
  readonly deleteSection: Maybe<NavConfig>;
  readonly deleteShortcut: Maybe<NavConfig>;
  readonly deleteThread: Maybe<IThread>;
  readonly deleteThreadEvent: Maybe<IThreadEvent>;
  readonly editMessage: Maybe<Message>;
  readonly editResourceLabel: Maybe<ThreadResource>;
  readonly followThread: Maybe<IThread>;
  readonly inviteMeetingParticipant: Maybe<MeetingParticipant>;
  readonly joinMeeting: Maybe<MeetingParticipant>;
  readonly leaveMeeting: Maybe<MeetingParticipant>;
  readonly logEvent: Maybe<Account>;
  readonly markPresence: Maybe<IThread>;
  readonly markThreadEventRead: Maybe<IThreadEvent>;
  readonly markThreadRead: Maybe<IThread>;
  readonly moveShortcut: Maybe<NavConfig>;
  readonly moveThreadEvent: Maybe<IThreadEvent>;
  readonly pinResource: Maybe<ThreadResource>;
  readonly registerForDesktopNotifications: Maybe<NotificationChannel>;
  readonly registerNotificationPushToken: Maybe<NotificationChannel>;
  readonly removeReaction: Maybe<IThreadEvent>;
  readonly removeTag: ReadonlyArray<RemoveTagResult>;
  /** Requests access to Discussions */
  readonly requestAccessToDiscussion: Maybe<GroupMembership>;
  /** Requests access to Meetings */
  readonly requestAccessToMeeting: Maybe<GroupMembership>;
  /** Request access to the active Meeting belonging to a MeetingSeries */
  readonly requestAccessToMeetingSeriesActiveMeeting: Maybe<GroupMembership>;
  /** Requests access to Teams */
  readonly requestAccessToTeam: Maybe<GroupMembership>;
  readonly setPresenceEnabled: Maybe<Account>;
  readonly setThreadEventHighlighted: Maybe<IThreadEvent>;
  readonly setThreadParent: Maybe<IThread>;
  readonly starThreadEvent: Maybe<IThreadEvent>;
  readonly startMeetingRecording: Maybe<Meeting>;
  readonly stopMeetingRecording: Maybe<Meeting>;
  readonly updateAssistantSession: AssistantSession;
  readonly updateDesktopNotificationDeliveryState: Maybe<DesktopNotification>;
  readonly updateDiscussion: Maybe<Discussion>;
  /** Update membership for Discussions */
  readonly updateDiscussionMembership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly updateEmojiSkinTone: Maybe<Account>;
  /** Updates a set of grants on an entity */
  readonly updateGrantsForEntity: Maybe<ReadonlyArray<Maybe<PermissionGrant>>>;
  readonly updateLocale: Maybe<Account>;
  readonly updateMeeting: Maybe<Meeting>;
  /** Update membership for Meetings */
  readonly updateMeetingMembership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly updateMeetingSeries: Maybe<MeetingSeries>;
  /** generic membership setter.  groupType is required */
  readonly updateMembership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly updateNote: Maybe<Note>;
  readonly updateNotificationChannel: Maybe<NotificationChannel>;
  readonly updateProfile: Maybe<IProfile>;
  readonly updateSection: Maybe<NavConfig>;
  readonly updateTeam: Maybe<Team>;
  /** Update membership for Teams */
  readonly updateTeamMembership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly uploadFiles: Maybe<ReadonlyArray<Maybe<FileUpload>>>;
  readonly userIsTyping: Maybe<IThread>;
};


export type MutationaddReactionArgs = {
  input?: InputMaybe<UpdateReactionsInput>;
};


export type MutationaddShortcutArgs = {
  input?: InputMaybe<AddShortcutInput>;
};


export type MutationaddTagArgs = {
  inputs?: InputMaybe<ReadonlyArray<InputMaybe<AddTagInput>>>;
};


export type MutationarchiveThreadArgs = {
  input?: InputMaybe<ArchiveThreadInput>;
};


export type MutationarchiveThreadNotificationArgs = {
  input?: InputMaybe<ArchiveThreadNotificationInput>;
};


export type MutationbeatMeetingParticipantHeartArgs = {
  input?: InputMaybe<BeatMeetingParticipantHeartInput>;
};


export type MutationconvertReplyThreadToDiscussionArgs = {
  input?: InputMaybe<ConvertReplyThreadToDiscussionInput>;
};


export type MutationcreateAssistantMessageArgs = {
  input?: InputMaybe<CreateAssistantMessageInput>;
};


export type MutationcreateAssistantSessionArgs = {
  input?: InputMaybe<CreateAssistantSessionInput>;
};


export type MutationcreateDiscussionArgs = {
  input?: InputMaybe<CreateDiscussionInput>;
};


export type MutationcreateImageFromURLArgs = {
  input?: InputMaybe<CreateImageFromURLInput>;
};


export type MutationcreateMeetingSeriesArgs = {
  input?: InputMaybe<CreateMeetingSeriesInput>;
};


export type MutationcreateMessageArgs = {
  input?: InputMaybe<CreateMessageInput>;
};


export type MutationcreateSectionArgs = {
  input?: InputMaybe<CreateSectionInput>;
};


export type MutationcreateTeamArgs = {
  input?: InputMaybe<CreateTeamInput>;
};


export type MutationcreateThreadNoteArgs = {
  input?: InputMaybe<CreateThreadNoteInput>;
};


export type MutationdeclineMeetingInvitationArgs = {
  input?: InputMaybe<DeclineMeetingInvitationInput>;
};


export type MutationdeleteMediaArgs = {
  input?: InputMaybe<DeleteMediaInput>;
};


export type MutationdeleteMeetingRecordingArgs = {
  input?: InputMaybe<DeleteMeetingRecordingInput>;
};


export type MutationdeleteSectionArgs = {
  input?: InputMaybe<DeleteSectionInput>;
};


export type MutationdeleteShortcutArgs = {
  input?: InputMaybe<DeleteShortcutInput>;
};


export type MutationdeleteThreadArgs = {
  input?: InputMaybe<DeleteThreadInput>;
};


export type MutationdeleteThreadEventArgs = {
  input?: InputMaybe<DeleteThreadEventInput>;
};


export type MutationeditMessageArgs = {
  input?: InputMaybe<EditMessageInput>;
};


export type MutationeditResourceLabelArgs = {
  input?: InputMaybe<EditResourceLabelInput>;
};


export type MutationfollowThreadArgs = {
  input?: InputMaybe<FollowThreadInput>;
};


export type MutationinviteMeetingParticipantArgs = {
  input?: InputMaybe<InviteMeetingParticipantInput>;
};


export type MutationjoinMeetingArgs = {
  input?: InputMaybe<JoinMeetingInput>;
};


export type MutationleaveMeetingArgs = {
  input?: InputMaybe<LeaveMeetingInput>;
};


export type MutationlogEventArgs = {
  input?: InputMaybe<LogEventInput>;
};


export type MutationmarkPresenceArgs = {
  input?: InputMaybe<MarkPresentInput>;
};


export type MutationmarkThreadEventReadArgs = {
  input?: InputMaybe<MarkThreadEventReadInput>;
};


export type MutationmarkThreadReadArgs = {
  input?: InputMaybe<MarkThreadReadInput>;
};


export type MutationmoveShortcutArgs = {
  input?: InputMaybe<MoveShortcutInput>;
};


export type MutationmoveThreadEventArgs = {
  input?: InputMaybe<MoveThreadEventInput>;
};


export type MutationpinResourceArgs = {
  input?: InputMaybe<PinResourceInput>;
};


export type MutationregisterNotificationPushTokenArgs = {
  input?: InputMaybe<RegisterNotificationPushTokenInput>;
};


export type MutationremoveReactionArgs = {
  input?: InputMaybe<UpdateReactionsInput>;
};


export type MutationremoveTagArgs = {
  inputs?: InputMaybe<ReadonlyArray<InputMaybe<RemoveTagInput>>>;
};


export type MutationrequestAccessToDiscussionArgs = {
  input?: InputMaybe<RequestAccessInput>;
};


export type MutationrequestAccessToMeetingArgs = {
  input?: InputMaybe<RequestAccessInput>;
};


export type MutationrequestAccessToMeetingSeriesActiveMeetingArgs = {
  input?: InputMaybe<RequestAccessToMeetingSeriesActiveMeetingInput>;
};


export type MutationrequestAccessToTeamArgs = {
  input?: InputMaybe<RequestAccessInput>;
};


export type MutationsetPresenceEnabledArgs = {
  input?: InputMaybe<SetPresenceEnabledInput>;
};


export type MutationsetThreadEventHighlightedArgs = {
  input?: InputMaybe<SetThreadEventHighlightedInput>;
};


export type MutationsetThreadParentArgs = {
  input?: InputMaybe<SetThreadParentInput>;
};


export type MutationstarThreadEventArgs = {
  input?: InputMaybe<StarThreadEventInput>;
};


export type MutationstartMeetingRecordingArgs = {
  input?: InputMaybe<StartMeetingRecordingInput>;
};


export type MutationstopMeetingRecordingArgs = {
  input?: InputMaybe<StopMeetingRecordingInput>;
};


export type MutationupdateAssistantSessionArgs = {
  input?: InputMaybe<UpdateAssistantSessionInput>;
};


export type MutationupdateDesktopNotificationDeliveryStateArgs = {
  input?: InputMaybe<UpdateDesktopNotificationDeliveryStateInput>;
};


export type MutationupdateDiscussionArgs = {
  input?: InputMaybe<UpdateDiscussionInput>;
};


export type MutationupdateDiscussionMembershipArgs = {
  input?: InputMaybe<UpdateMembershipInput>;
};


export type MutationupdateEmojiSkinToneArgs = {
  input?: InputMaybe<UpdateEmojiSkinToneInput>;
};


export type MutationupdateGrantsForEntityArgs = {
  input?: InputMaybe<UpdateGrantsForEntityInput>;
};


export type MutationupdateLocaleArgs = {
  input?: InputMaybe<UpdateLocaleInput>;
};


export type MutationupdateMeetingArgs = {
  input?: InputMaybe<UpdateMeetingInput>;
};


export type MutationupdateMeetingMembershipArgs = {
  input?: InputMaybe<UpdateMembershipInput>;
};


export type MutationupdateMeetingSeriesArgs = {
  input?: InputMaybe<UpdateMeetingSeriesInput>;
};


export type MutationupdateMembershipArgs = {
  input?: InputMaybe<UpdateMembershipInput>;
};


export type MutationupdateNoteArgs = {
  input?: InputMaybe<UpdateNoteInput>;
};


export type MutationupdateNotificationChannelArgs = {
  input?: InputMaybe<UpdateNotificationChannelInput>;
};


export type MutationupdateProfileArgs = {
  input?: InputMaybe<UpdateProfileInput>;
};


export type MutationupdateSectionArgs = {
  input?: InputMaybe<UpdateSectionInput>;
};


export type MutationupdateTeamArgs = {
  input?: InputMaybe<UpdateTeamInput>;
};


export type MutationupdateTeamMembershipArgs = {
  input?: InputMaybe<UpdateMembershipInput>;
};


export type MutationuploadFilesArgs = {
  input?: InputMaybe<FileUploadInput>;
};


export type MutationuserIsTypingArgs = {
  input?: InputMaybe<UserIsTypingInput>;
};

/** Navigation config for user */
export type NavConfig = {
  readonly __typename: 'NavConfig';
  readonly defaultSection: NavSection;
  readonly id: Scalars['ID']['output'];
  readonly sections: ReadonlyArray<NavSection>;
};

/** Navigation section */
export type NavSection = {
  readonly __typename: 'NavSection';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly shortcuts: ReadonlyArray<NavShortcut>;
};

/** Navigation shortcut */
export type NavShortcut = {
  readonly __typename: 'NavShortcut';
  readonly id: Scalars['ID']['output'];
  readonly sectionId: Scalars['ID']['output'];
  readonly target: NavShortcutTarget;
};

/** individual shortcut in navigation bar */
export type NavShortcutTarget = Account | Discussion | PrivateChat | Team;

export enum NavShortcutType {
  ACCOUNT = 'ACCOUNT',
  DISCUSSION = 'DISCUSSION',
  PRIVATE_CHAT = 'PRIVATE_CHAT',
  TEAM = 'TEAM'
}

/** Note object */
export type Note = {
  readonly __typename: 'Note';
  readonly body: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly isEmpty: Maybe<Scalars['Boolean']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly org: Maybe<Org>;
  readonly threads: Maybe<ReadonlyArray<Maybe<IThread>>>;
  readonly ydoc: Maybe<YDoc>;
};

export type NoteCreatedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'NoteCreatedThreadEvent';
  readonly account: Maybe<Account>;
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly note: Maybe<Note>;
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

/** NotificationChannel object */
export type NotificationChannel = {
  readonly __typename: 'NotificationChannel';
  readonly enabled: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly type: Maybe<NotificationChannelType>;
};

export enum NotificationChannelType {
  APNS = 'APNS',
  DESKTOP = 'DESKTOP',
  EMAIL = 'EMAIL'
}

export enum NotificationDeliveryState {
  COMPLAINT = 'COMPLAINT',
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  INVALIDATED = 'INVALIDATED',
  PENDING = 'PENDING',
  SENT = 'SENT'
}

export enum NotificationType {
  INVITED_TO_MEETING = 'INVITED_TO_MEETING',
  MEMBER_ADDED = 'MEMBER_ADDED',
  NEW_MEETING_SUMMARY = 'NEW_MEETING_SUMMARY',
  NEW_THREAD_EVENT = 'NEW_THREAD_EVENT',
  REACTION = 'REACTION',
  UNREAD_THREAD_COUNT = 'UNREAD_THREAD_COUNT'
}

/** Object Update Event object */
export type ObjectUpdateEvent = {
  readonly __typename: 'ObjectUpdateEvent';
  readonly accountActivityUpdated: Maybe<Account>;
  readonly accountUpdated: Maybe<Account>;
  readonly assistantMessageCreated: Maybe<IAssistantMessage>;
  readonly assistantMessageUpdated: Maybe<IAssistantMessage>;
  readonly assistantSessionUpdated: Maybe<AssistantSession>;
  readonly composerDraftDeleted: Maybe<ComposerDraft>;
  readonly discussionUpdated: Maybe<Discussion>;
  readonly followChange: Maybe<IThread>;
  readonly imageUpdated: Maybe<Image>;
  readonly linkMetadataUpdated: Maybe<Link>;
  readonly mediaDeleted: Maybe<Media>;
  readonly mediaReady: Maybe<Media>;
  readonly mediaThumbnailsUpdated: Maybe<Media>;
  readonly mediaUpdated: Maybe<Media>;
  readonly meetingCreatedOrJoined: Maybe<Meeting>;
  readonly meetingEnded: Maybe<Meeting>;
  readonly meetingParticipantDeclined: Maybe<MeetingParticipant>;
  readonly meetingParticipantInvited: Maybe<MeetingParticipant>;
  readonly meetingParticipantJoined: Maybe<MeetingParticipant>;
  readonly meetingParticipantLeft: Maybe<MeetingParticipant>;
  readonly meetingSeriesCreated: Maybe<MeetingSeries>;
  readonly meetingSeriesUpdated: Maybe<MeetingSeries>;
  readonly meetingSummaryUpdated: Maybe<Meeting>;
  readonly meetingUpdated: Maybe<Meeting>;
  readonly messageEdited: Maybe<Message>;
  readonly navUpdated: Maybe<NavConfig>;
  readonly noteUpdated: Maybe<Note>;
  readonly recentActivity: Maybe<Account>;
  readonly tagAdded: Maybe<AddTagResult>;
  readonly tagRemoved: Maybe<RemoveTagResult>;
  /** Returns Account of logged in user to support refetching entire team membership list */
  readonly teamCreated: Maybe<Account>;
  readonly teamUpdated: Maybe<Team>;
  readonly threadCreated: Maybe<IThread>;
  readonly threadDeleted: Maybe<IThread>;
  readonly threadEventCreated: Maybe<IThreadEvent>;
  readonly threadEventDeleted: Maybe<IThreadEvent>;
  readonly threadEventMoved: Maybe<ThreadEventMovedUpdate>;
  readonly threadEventRead: Maybe<IThreadEvent>;
  readonly threadEventUpdated: Maybe<IThreadEvent>;
  readonly threadNotificationCreated: Maybe<ThreadNotification>;
  readonly threadNotificationDeleted: Maybe<ThreadNotification>;
  readonly threadNotificationUpdated: Maybe<ThreadNotification>;
  readonly threadReadStateChange: Maybe<IThread>;
  readonly threadResourceCreated: Maybe<ThreadResource>;
  readonly threadResourceDeleted: Maybe<ThreadResource>;
  readonly threadResourceUpdated: Maybe<ThreadResource>;
  readonly threadSummaryUpdated: Maybe<IThread>;
  readonly threadUpdated: Maybe<IThread>;
  readonly userTypingThread: Maybe<IThread>;
};

/** Org object */
export type Org = INode & {
  readonly __typename: 'Org';
  /**
   * Accounts associated with the Org
   * @deprecated Implement a paginated version if using this query in production.
   */
  readonly accounts: Maybe<ReadonlyArray<Maybe<Account>>>;
  readonly activeMeetings: Maybe<ReadonlyArray<Maybe<Meeting>>>;
  readonly id: Scalars['ID']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
};

export type PageInfo = {
  readonly __typename: 'PageInfo';
  readonly endCursor: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly startCursor: Maybe<Scalars['String']['output']>;
};

/** pagination input */
export type PaginationArgsInput = {
  /** Pagination cursor */
  readonly cursor?: InputMaybe<Scalars['String']['input']>;
  /** Page size */
  readonly size?: InputMaybe<Scalars['Int']['input']>;
};

export type PermissionGrant = {
  readonly __typename: 'PermissionGrant';
  readonly entity: Maybe<CanHavePermissionRole>;
  /** compound id of (target-id)::(entity-id) */
  readonly id: Scalars['ID']['output'];
  readonly role: Maybe<MembershipStatus>;
  readonly target: IHasPermissionProfile;
};

export type PinResourceInput = {
  readonly pinned: Scalars['Boolean']['input'];
  readonly resourceId: Scalars['ID']['input'];
};

/** Account's presence in this thread */
export enum PresenceState {
  AWAY = 'AWAY',
  PRESENT = 'PRESENT'
}

/** Private Chat between individuals */
export type PrivateChat = IFollowable & INode & IProfile & ITaggable & IThread & {
  readonly __typename: 'PrivateChat';
  readonly abstract: Maybe<Scalars['String']['output']>;
  readonly archived: Maybe<Scalars['Boolean']['output']>;
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  /** Canonical MeetingSeries for Thread, if one exists */
  readonly canonicalMeetingSeries: Maybe<MeetingSeries>;
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly eventConnections: Maybe<ThreadEventConnection>;
  readonly externallyVisible: Scalars['Boolean']['output'];
  readonly firstMessage: Maybe<Message>;
  readonly firstUnreadMessageEvent: Maybe<MessageCreatedThreadEvent>;
  readonly followers: ReadonlyArray<Account>;
  readonly following: Maybe<Scalars['Boolean']['output']>;
  readonly hasUnread: Scalars['Boolean']['output'];
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly inboxed: Maybe<Scalars['Boolean']['output']>;
  readonly isPR: Maybe<Scalars['Boolean']['output']>;
  readonly lastEvent: Maybe<IThreadEvent>;
  readonly lastEventAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastMeeting: Maybe<Meeting>;
  readonly lastMessage: Maybe<Message>;
  readonly lastMessageAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastVisitedAt: Maybe<Scalars['DateTime']['output']>;
  readonly length: Maybe<Scalars['Float']['output']>;
  readonly meeting: Maybe<Meeting>;
  readonly members: ReadonlyArray<Account>;
  readonly messageCount: Scalars['Float']['output'];
  readonly name: Scalars['String']['output'];
  readonly newDiscussionCount: Maybe<Scalars['Float']['output']>;
  readonly newMentionCount: Scalars['Float']['output'];
  readonly newMessageCount: Maybe<Scalars['Float']['output']>;
  readonly newRepliesCount: Scalars['Float']['output'];
  readonly newVideoCount: Maybe<Scalars['Float']['output']>;
  readonly notes: Maybe<ReadonlyArray<Maybe<Note>>>;
  readonly notifications: Maybe<ReadonlyArray<Maybe<ThreadNotification>>>;
  readonly org: Maybe<Org>;
  readonly parent: Maybe<IThread>;
  readonly parents: Maybe<ReadonlyArray<Maybe<IThread>>>;
  readonly participants: ReadonlyArray<Account>;
  readonly permalink: Scalars['String']['output'];
  readonly presence: Maybe<PresenceState>;
  readonly present: ReadonlyArray<Account>;
  readonly profileImage: Maybe<Image>;
  readonly readmeNote: Maybe<Note>;
  readonly resourceConnections: Maybe<ThreadResourceConnection>;
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
  readonly starred: ReadonlyArray<IThreadEvent>;
  readonly startingMessage: Maybe<Message>;
  readonly subDiscussions: IThreadConnection;
  readonly summaryEventConnections: ThreadSummaryEventConnection;
  readonly summaryEventGroupConnections: ThreadSummaryEventGroupConnection;
  readonly tags: ReadonlyArray<Discussion>;
  readonly typing: ReadonlyArray<Account>;
  readonly unreadMessageCount: Scalars['Float']['output'];
  readonly videoCount: Scalars['Float']['output'];
};


/** Private Chat between individuals */
export type PrivateChateventConnectionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<ReadonlyArray<ThreadEventType>>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Private Chat between individuals */
export type PrivateChatlastEventArgs = {
  includeReplies?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Private Chat between individuals */
export type PrivateChatresourceConnectionsArgs = {
  filters?: InputMaybe<ThreadResourcesFiltersInput>;
  page?: InputMaybe<PaginationArgsInput>;
  sort?: InputMaybe<ThreadResourcesSortInput>;
};


/** Private Chat between individuals */
export type PrivateChatsubDiscussionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Private Chat between individuals */
export type PrivateChatsummaryEventConnectionsArgs = {
  filter?: InputMaybe<SummaryEventConnectionsFilterInput>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Private Chat between individuals */
export type PrivateChatsummaryEventGroupConnectionsArgs = {
  page?: InputMaybe<PaginationArgsInput>;
};

export type PublicAudience = INode & {
  readonly __typename: 'PublicAudience';
  readonly id: Scalars['ID']['output'];
};

export type Query = {
  readonly __typename: 'Query';
  /** Returns the account of the currently logged in user, or account by id/loginId */
  readonly account: Maybe<Account>;
  /** Returns a set of accounts by id or loginId */
  readonly accounts: Maybe<ReadonlyArray<Maybe<Account>>>;
  readonly assistantMessage: Maybe<IAssistantMessage>;
  readonly assistantSession: Maybe<AssistantSession>;
  /** @deprecated Needs paginated API */
  readonly browseTeams: ReadonlyArray<Team>;
  readonly canRequestAccess: Scalars['Boolean']['output'];
  /** Fetches a ComposerDraft by id or targetId */
  readonly composerDraft: Maybe<ComposerDraft>;
  /** Returns top accounts viewer is connected to */
  readonly connectedAccounts: Maybe<ReadonlyArray<Maybe<Account>>>;
  readonly discussion: Maybe<Discussion>;
  readonly discussions: Maybe<ReadonlyArray<Maybe<Discussion>>>;
  readonly externalResource: Maybe<AppObject>;
  readonly file: Maybe<File>;
  readonly getMembershipStatus: Maybe<MembershipStatus>;
  readonly image: Maybe<Image>;
  readonly inboxThreadCount: Maybe<Scalars['Float']['output']>;
  readonly link: Maybe<Link>;
  readonly media: Maybe<Media>;
  readonly mediaThumbnail: Maybe<MediaThumbnail>;
  readonly meeting: Maybe<Meeting>;
  readonly meetingParticipant: Maybe<MeetingParticipant>;
  readonly meetingParticipantInvitations: Maybe<ReadonlyArray<Maybe<MeetingParticipant>>>;
  readonly meetingSeries: Maybe<MeetingSeries>;
  readonly meetings: Maybe<ReadonlyArray<Maybe<Meeting>>>;
  readonly message: Maybe<Message>;
  readonly note: Maybe<Note>;
  readonly notificationChannel: Maybe<NotificationChannel>;
  readonly notificationChannels: Maybe<ReadonlyArray<Maybe<NotificationChannel>>>;
  /** Returns Org by id. If no id is provided, returns Org of current session. */
  readonly org: Maybe<Org>;
  readonly orgs: Maybe<ReadonlyArray<Maybe<Org>>>;
  readonly pagedInbox: Maybe<IThreadConnection>;
  readonly pagedMeetings: Maybe<IThreadConnection>;
  readonly privateChat: Maybe<PrivateChat>;
  readonly profile: Maybe<IProfile>;
  readonly profileNameTypeaheadQuery: Maybe<ReadonlyArray<Maybe<TypeaheadQueryResult>>>;
  readonly profiles: Maybe<ReadonlyArray<Maybe<IProfile>>>;
  readonly searchQuery: Maybe<ReadonlyArray<Maybe<SearchQueryResult>>>;
  readonly searchTypeaheadQuery: Maybe<ReadonlyArray<Maybe<TypeaheadQueryResult>>>;
  readonly team: Maybe<Team>;
  readonly teams: Maybe<ReadonlyArray<Maybe<Team>>>;
  readonly thread: Maybe<IThread>;
  readonly threadEvent: Maybe<IThreadEvent>;
  readonly threadEvents: Maybe<ReadonlyArray<Maybe<IThreadEvent>>>;
  readonly threadNotification: Maybe<ThreadNotification>;
  readonly threadNotificationCount: Maybe<Scalars['Float']['output']>;
  readonly threadResource: Maybe<ThreadResource>;
  readonly threadResourceSummaryChunk: Maybe<ThreadResourceSummaryChunk>;
  readonly threadSummaryChapter: Maybe<ThreadSummaryChapter>;
  readonly threadSummaryChunk: Maybe<ThreadSummaryChunk>;
  readonly threadSummaryEvent: Maybe<IThreadSummaryEvent>;
  readonly ydoc: Maybe<YDoc>;
};


export type QueryaccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryaccountsArgs = {
  ids: ReadonlyArray<Scalars['ID']['input']>;
};


export type QueryassistantMessageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryassistantSessionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerybrowseTeamsArgs = {
  accountId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerycanRequestAccessArgs = {
  input?: InputMaybe<GetMembershipStatusInput>;
};


export type QuerycomposerDraftArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryconnectedAccountsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
};


export type QuerydiscussionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerydiscussionsArgs = {
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryexternalResourceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};


export type QueryfileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerygetMembershipStatusArgs = {
  input?: InputMaybe<GetMembershipStatusInput>;
};


export type QueryimageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryinboxThreadCountArgs = {
  filter?: InputMaybe<InboxFilterType>;
};


export type QuerylinkArgs = {
  url?: InputMaybe<Scalars['String']['input']>;
};


export type QuerymediaArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerymediaThumbnailArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerymeetingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerymeetingParticipantArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerymeetingSeriesArgs = {
  code?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerymeetingsArgs = {
  input?: InputMaybe<MeetingsQueryInput>;
};


export type QuerymessageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerynoteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerynotificationChannelArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryorgArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerypagedInboxArgs = {
  input?: InputMaybe<GetInboxArgs>;
};


export type QuerypagedMeetingsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<MeetingsQueryInput>;
  page?: InputMaybe<PaginationArgsInput>;
};


export type QueryprivateChatArgs = {
  accountIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryprofileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryprofileNameTypeaheadQueryArgs = {
  filters?: InputMaybe<TypeaheadProfileQueryFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  scopeHint?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
};


export type QueryprofilesArgs = {
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


export type QuerysearchQueryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SearchQueryOrderBy>;
  query: Scalars['String']['input'];
};


export type QuerysearchTypeaheadQueryArgs = {
  filters?: InputMaybe<TypeaheadProfileQueryFilterInputType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  matchingParticipantIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  query: Scalars['String']['input'];
};


export type QueryteamArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryteamsArgs = {
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


export type QuerythreadArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerythreadEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerythreadEventsArgs = {
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


export type QuerythreadNotificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerythreadNotificationCountArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ThreadNotificationType>;
};


export type QuerythreadResourceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerythreadResourceSummaryChunkArgs = {
  id: Scalars['ID']['input'];
};


export type QuerythreadSummaryChapterArgs = {
  id: Scalars['ID']['input'];
};


export type QuerythreadSummaryChunkArgs = {
  id: Scalars['ID']['input'];
};


export type QuerythreadSummaryEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryydocArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Emoji reaction to a thread event */
export type Reaction = {
  readonly __typename: 'Reaction';
  readonly accounts: ReadonlyArray<Account>;
  readonly accountsIncludeMe: Scalars['Boolean']['output'];
  readonly count: Scalars['Float']['output'];
  readonly emojiId: Scalars['ID']['output'];
};

export type RegisterNotificationPushTokenInput = {
  readonly token: Scalars['String']['input'];
  readonly type: NotificationChannelType;
};

export type RemoveTagInput = {
  readonly tagId: Scalars['ID']['input'];
  readonly taggedId: Scalars['ID']['input'];
};

export type RemoveTagResult = {
  readonly __typename: 'RemoveTagResult';
  readonly tag: Discussion;
  readonly tagged: ITaggable;
};

/** Reply thread to a thread event */
export type Replies = IAccountGroup & IFollowable & INode & IProfile & IThread & {
  readonly __typename: 'Replies';
  readonly abstract: Maybe<Scalars['String']['output']>;
  readonly archived: Maybe<Scalars['Boolean']['output']>;
  readonly canAssignAdminRole: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated this is not well defined but having a placeholder resolver helps simplify some client code */
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  readonly canEditPrivacy: Maybe<Scalars['Boolean']['output']>;
  readonly canEditProperties: Maybe<Scalars['Boolean']['output']>;
  readonly canRequestAccess: Maybe<Scalars['Boolean']['output']>;
  readonly canonicalMeetingSeries: Maybe<MeetingSeries>;
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly eventConnections: Maybe<ThreadEventConnection>;
  readonly externallyVisible: Scalars['Boolean']['output'];
  readonly firstMessage: Maybe<Message>;
  readonly firstUnreadMessageEvent: Maybe<MessageCreatedThreadEvent>;
  readonly followers: ReadonlyArray<Account>;
  readonly following: Maybe<Scalars['Boolean']['output']>;
  readonly hasUnread: Scalars['Boolean']['output'];
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly inboxed: Maybe<Scalars['Boolean']['output']>;
  readonly isAdmin: Maybe<Scalars['Boolean']['output']>;
  readonly isPR: Maybe<Scalars['Boolean']['output']>;
  readonly lastEvent: Maybe<IThreadEvent>;
  readonly lastEventAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastMeeting: Maybe<Meeting>;
  readonly lastMessage: Maybe<Message>;
  readonly lastMessageAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastVisitedAt: Maybe<Scalars['DateTime']['output']>;
  readonly length: Maybe<Scalars['Float']['output']>;
  readonly meeting: Maybe<Meeting>;
  readonly membership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly messageCount: Scalars['Float']['output'];
  readonly name: Scalars['String']['output'];
  readonly newDiscussionCount: Maybe<Scalars['Float']['output']>;
  readonly newMentionCount: Scalars['Float']['output'];
  readonly newMessageCount: Maybe<Scalars['Float']['output']>;
  readonly newRepliesCount: Scalars['Float']['output'];
  readonly newVideoCount: Maybe<Scalars['Float']['output']>;
  readonly notes: Maybe<ReadonlyArray<Maybe<Note>>>;
  readonly notifications: Maybe<ReadonlyArray<Maybe<ThreadNotification>>>;
  readonly org: Maybe<Org>;
  readonly parent: Maybe<IThread>;
  readonly parentThreadEvent: IThreadEvent;
  readonly parents: Maybe<ReadonlyArray<Maybe<IThread>>>;
  readonly participants: ReadonlyArray<Account>;
  readonly permalink: Scalars['String']['output'];
  readonly presence: Maybe<PresenceState>;
  readonly present: ReadonlyArray<Account>;
  readonly profileImage: Maybe<Image>;
  readonly readmeNote: Maybe<Note>;
  readonly requests: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly resourceConnections: Maybe<ThreadResourceConnection>;
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
  readonly starred: ReadonlyArray<IThreadEvent>;
  readonly startingMessage: Maybe<Message>;
  readonly subDiscussions: IThreadConnection;
  readonly summaryEventConnections: ThreadSummaryEventConnection;
  readonly summaryEventGroupConnections: ThreadSummaryEventGroupConnection;
  readonly typing: ReadonlyArray<Account>;
  readonly unreadMessageCount: Scalars['Float']['output'];
  readonly videoCount: Scalars['Float']['output'];
};


/** Reply thread to a thread event */
export type ReplieseventConnectionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<ReadonlyArray<ThreadEventType>>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Reply thread to a thread event */
export type ReplieslastEventArgs = {
  includeReplies?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Reply thread to a thread event */
export type RepliesresourceConnectionsArgs = {
  filters?: InputMaybe<ThreadResourcesFiltersInput>;
  page?: InputMaybe<PaginationArgsInput>;
  sort?: InputMaybe<ThreadResourcesSortInput>;
};


/** Reply thread to a thread event */
export type RepliessubDiscussionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Reply thread to a thread event */
export type RepliessummaryEventConnectionsArgs = {
  filter?: InputMaybe<SummaryEventConnectionsFilterInput>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Reply thread to a thread event */
export type RepliessummaryEventGroupConnectionsArgs = {
  page?: InputMaybe<PaginationArgsInput>;
};

/** Request access to a group */
export type RequestAccessInput = {
  readonly groupId: Scalars['ID']['input'];
};

export type RequestAccessToMeetingSeriesActiveMeetingInput = {
  readonly meetingSeriesId: Scalars['ID']['input'];
};

/** The order in which to return search results */
export enum SearchQueryOrderBy {
  FIRST_EVENT_TIME_ASC = 'FIRST_EVENT_TIME_ASC',
  FIRST_EVENT_TIME_DESC = 'FIRST_EVENT_TIME_DESC',
  RELEVANCE = 'RELEVANCE'
}

export type SearchQueryResult = {
  readonly __typename: 'SearchQueryResult';
  readonly keywords: ReadonlyArray<Scalars['String']['output']>;
  /** @deprecated ** temporary ** */
  readonly meetingEvents: ReadonlyArray<MeetingEvent>;
  readonly score: Maybe<Scalars['Float']['output']>;
  /** @deprecated ** temporary ** */
  readonly threadEvents: ReadonlyArray<IThreadEvent>;
  readonly threadSummaryChunk: ThreadSummaryChunk;
};

export type SetPresenceEnabledInput = {
  readonly enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetThreadEventHighlightedInput = {
  readonly highlighted: Scalars['Boolean']['input'];
  readonly threadEventId: Scalars['ID']['input'];
};

export type SetThreadParentInput = {
  readonly parentThreadId?: InputMaybe<Scalars['ID']['input']>;
  readonly threadId: Scalars['ID']['input'];
};

export type StarThreadEventInput = {
  readonly starred: Scalars['Boolean']['input'];
  readonly threadEventId: Scalars['String']['input'];
};

export type StartMeetingRecordingInput = {
  readonly meetingId?: InputMaybe<Scalars['ID']['input']>;
};

export type StopMeetingRecordingInput = {
  readonly meetingId?: InputMaybe<Scalars['ID']['input']>;
};

export type Subscription = {
  readonly __typename: 'Subscription';
  readonly evictionNotice: Maybe<Scalars['String']['output']>;
  readonly notifications: Maybe<DesktopNotification>;
  readonly objectUpdateEvent: Maybe<ObjectUpdateEvent>;
};

export type SummaryChunk = ThreadResourceSummaryChunk | ThreadSummaryChunk;

export type SummaryEventConnectionsFilterInput = {
  readonly eventType?: InputMaybe<ReadonlyArray<ThreadSummaryEventType>>;
};

/** Summary item thread summary event */
export type SummaryItemThreadSummaryEvent = INode & IThreadSummaryEvent & {
  readonly __typename: 'SummaryItemThreadSummaryEvent';
  readonly accounts: ReadonlyArray<Account>;
  readonly durationSeconds: Scalars['Float']['output'];
  readonly id: Scalars['ID']['output'];
  readonly meetingOffsetSeconds: Maybe<Scalars['Float']['output']>;
  readonly summary: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export type TagAddedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'TagAddedThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly tag: Discussion;
  readonly tagged: ITaggable;
  readonly taggedBy: Maybe<Account>;
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type TagRemovedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'TagRemovedThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly removedBy: Maybe<Account>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly tag: Discussion;
  readonly tagged: ITaggable;
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

/** Team object */
export type Team = IAccountGroup & IFollowable & INode & IProfile & IThread & {
  readonly __typename: 'Team';
  readonly abstract: Maybe<Scalars['String']['output']>;
  readonly archived: Maybe<Scalars['Boolean']['output']>;
  readonly audience: AudienceType;
  readonly canAssignAdminRole: Maybe<Scalars['Boolean']['output']>;
  readonly canEditMembership: Maybe<Scalars['Boolean']['output']>;
  readonly canEditPrivacy: Maybe<Scalars['Boolean']['output']>;
  readonly canEditProperties: Maybe<Scalars['Boolean']['output']>;
  readonly canJoin: Scalars['Boolean']['output'];
  readonly canRequestAccess: Maybe<Scalars['Boolean']['output']>;
  readonly canSee: Scalars['Boolean']['output'];
  /** Canonical MeetingSeries for Thread, if one exists */
  readonly canonicalMeetingSeries: Maybe<MeetingSeries>;
  readonly chatPermalink: Scalars['String']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly cursor: Maybe<Scalars['String']['output']>;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly eventConnections: Maybe<ThreadEventConnection>;
  readonly externallyVisible: Scalars['Boolean']['output'];
  readonly firstMessage: Maybe<Message>;
  readonly firstUnreadMessageEvent: Maybe<MessageCreatedThreadEvent>;
  readonly followers: ReadonlyArray<Account>;
  readonly following: Maybe<Scalars['Boolean']['output']>;
  readonly hasUnread: Scalars['Boolean']['output'];
  readonly icon: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly inboxed: Maybe<Scalars['Boolean']['output']>;
  readonly isAdmin: Maybe<Scalars['Boolean']['output']>;
  readonly isPR: Maybe<Scalars['Boolean']['output']>;
  readonly lastEvent: Maybe<IThreadEvent>;
  readonly lastEventAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastMeeting: Maybe<Meeting>;
  readonly lastMessage: Maybe<Message>;
  readonly lastMessageAt: Maybe<Scalars['DateTime']['output']>;
  readonly lastVisitedAt: Maybe<Scalars['DateTime']['output']>;
  readonly length: Maybe<Scalars['Float']['output']>;
  readonly meeting: Maybe<Meeting>;
  readonly membership: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly messageCount: Scalars['Float']['output'];
  readonly name: Scalars['String']['output'];
  readonly newDiscussionCount: Maybe<Scalars['Float']['output']>;
  readonly newMentionCount: Scalars['Float']['output'];
  readonly newMessageCount: Maybe<Scalars['Float']['output']>;
  readonly newRepliesCount: Scalars['Float']['output'];
  readonly newVideoCount: Maybe<Scalars['Float']['output']>;
  readonly notes: Maybe<ReadonlyArray<Maybe<Note>>>;
  readonly notifications: Maybe<ReadonlyArray<Maybe<ThreadNotification>>>;
  readonly org: Maybe<Org>;
  readonly owningOrg: Maybe<Org>;
  readonly parent: Maybe<IThread>;
  readonly parents: Maybe<ReadonlyArray<Maybe<IThread>>>;
  readonly participants: ReadonlyArray<Account>;
  readonly permalink: Scalars['String']['output'];
  readonly presence: Maybe<PresenceState>;
  readonly present: ReadonlyArray<Account>;
  readonly profileImage: Maybe<Image>;
  readonly readmeNote: Maybe<Note>;
  readonly requests: Maybe<ReadonlyArray<Maybe<GroupMembership>>>;
  readonly resourceConnections: Maybe<ThreadResourceConnection>;
  readonly shortcuts: Maybe<ReadonlyArray<Maybe<NavShortcut>>>;
  readonly starred: ReadonlyArray<IThreadEvent>;
  readonly startingMessage: Maybe<Message>;
  readonly subDiscussions: IThreadConnection;
  readonly summaryEventConnections: ThreadSummaryEventConnection;
  readonly summaryEventGroupConnections: ThreadSummaryEventGroupConnection;
  readonly typing: ReadonlyArray<Account>;
  readonly unreadMessageCount: Scalars['Float']['output'];
  readonly videoCount: Scalars['Float']['output'];
};


/** Team object */
export type TeamcanSeeArgs = {
  target?: InputMaybe<GrantTargetInput>;
};


/** Team object */
export type TeameventConnectionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<ReadonlyArray<ThreadEventType>>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Team object */
export type TeamlastEventArgs = {
  includeReplies?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Team object */
export type TeamresourceConnectionsArgs = {
  filters?: InputMaybe<ThreadResourcesFiltersInput>;
  page?: InputMaybe<PaginationArgsInput>;
  sort?: InputMaybe<ThreadResourcesSortInput>;
};


/** Team object */
export type TeamsubDiscussionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Team object */
export type TeamsummaryEventConnectionsArgs = {
  filter?: InputMaybe<SummaryEventConnectionsFilterInput>;
  page?: InputMaybe<PaginationArgsInput>;
};


/** Team object */
export type TeamsummaryEventGroupConnectionsArgs = {
  page?: InputMaybe<PaginationArgsInput>;
};

/** Event documenting the creation context for a Team. */
export type TeamStartedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'TeamStartedThreadEvent';
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly creator: Maybe<Account>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly team: Team;
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type ThreadEventConnection = {
  readonly __typename: 'ThreadEventConnection';
  readonly edges: ReadonlyArray<ThreadEventEdge>;
  readonly pageInfo: PageInfo;
};

export type ThreadEventEdge = {
  readonly __typename: 'ThreadEventEdge';
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String']['output'];
  readonly hasNextPage: Maybe<Scalars['Boolean']['output']>;
  readonly hasPreviousPage: Maybe<Scalars['Boolean']['output']>;
  readonly node: IThreadEvent;
};

export type ThreadEventMovedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'ThreadEventMovedThreadEvent';
  readonly actor: Maybe<Account>;
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly destination: Maybe<IThread>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly threadEvent: Maybe<IThreadEvent>;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

/** Update event when a thread event is moved */
export type ThreadEventMovedUpdate = {
  readonly __typename: 'ThreadEventMovedUpdate';
  readonly from: IThread;
  readonly threadEvent: Maybe<IThreadEvent>;
  /** Expose thread event id in case it is no longer visible in its new location. */
  readonly threadEventId: Scalars['ID']['output'];
};

export enum ThreadEventType {
  ACCESS_REQUEST = 'ACCESS_REQUEST',
  AUDIENCE_MEMBER_UPDATE = 'AUDIENCE_MEMBER_UPDATE',
  AUDIENCE_UPDATE = 'AUDIENCE_UPDATE',
  DISCUSSION_CREATED = 'DISCUSSION_CREATED',
  DISCUSSION_RENAMED = 'DISCUSSION_RENAMED',
  DISCUSSION_START = 'DISCUSSION_START',
  MEETING_CREATED = 'MEETING_CREATED',
  MEETING_ENDED = 'MEETING_ENDED',
  MEETING_PARTICIPANTS_JOINED = 'MEETING_PARTICIPANTS_JOINED',
  MEETING_PARTICIPANTS_LEFT = 'MEETING_PARTICIPANTS_LEFT',
  MEETING_STARTED = 'MEETING_STARTED',
  MENTIONED = 'MENTIONED',
  MESSAGE_CREATED = 'MESSAGE_CREATED',
  MESSAGE_SCHEDULED = 'MESSAGE_SCHEDULED',
  NOTE_CREATED = 'NOTE_CREATED',
  TAG_ADDED = 'TAG_ADDED',
  TAG_REMOVED = 'TAG_REMOVED',
  TEAM_STARTED = 'TEAM_STARTED',
  THREAD_EVENT_MOVED = 'THREAD_EVENT_MOVED',
  THREAD_PARENT_UPDATED = 'THREAD_PARENT_UPDATED'
}

/** Thread notification */
export type ThreadNotification = {
  readonly __typename: 'ThreadNotification';
  readonly account: Account;
  readonly archived: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly mentionedVia: Maybe<IProfile>;
  readonly notificationType: ThreadNotificationType;
  readonly threadEvent: IThreadEvent;
  readonly updatedAt: Scalars['DateTime']['output'];
};

/** Notifications that appear in a thread view */
export enum ThreadNotificationType {
  DISCUSSION_CREATED = 'DISCUSSION_CREATED',
  MEETING_CREATED = 'MEETING_CREATED',
  MENTIONED = 'MENTIONED',
  REPLY = 'REPLY',
  STARRED = 'STARRED',
  UNREAD = 'UNREAD'
}

export type ThreadParentUpdatedThreadEvent = INode & IThreadEvent & {
  readonly __typename: 'ThreadParentUpdatedThreadEvent';
  readonly actor: Maybe<Account>;
  readonly actors: ReadonlyArray<Account>;
  readonly canReply: Scalars['Boolean']['output'];
  readonly composerDraft: Maybe<ComposerDraft>;
  readonly highlightedBy: Maybe<Account>;
  readonly id: Scalars['ID']['output'];
  readonly permalink: Scalars['String']['output'];
  readonly permissionScope: Maybe<IProfile>;
  readonly previousParent: Maybe<IThread>;
  readonly reactions: Maybe<ReadonlyArray<Maybe<Reaction>>>;
  readonly readAt: Maybe<Scalars['DateTime']['output']>;
  readonly replyThread: Maybe<IThread>;
  readonly starred: Scalars['Boolean']['output'];
  readonly thread: IThread;
  readonly time: Maybe<Scalars['DateTime']['output']>;
  readonly updatedParent: Maybe<IThread>;
  /** The Thread whose parent was updated */
  readonly updatedThread: IThread;
};

/** Thread resource */
export type ThreadResource = {
  readonly __typename: 'ThreadResource';
  readonly canEditLabel: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly label: Scalars['String']['output'];
  readonly pinned: Scalars['Boolean']['output'];
  readonly pinnedBy: Maybe<Account>;
  readonly resource: Maybe<ThreadResourceItem>;
  readonly sharedBy: Maybe<ReadonlyArray<Maybe<Account>>>;
  readonly thread: Maybe<IThread>;
  readonly threadEvents: Maybe<ReadonlyArray<Maybe<IThreadEvent>>>;
  readonly time: Maybe<Scalars['DateTime']['output']>;
};

export type ThreadResourceConnection = {
  readonly __typename: 'ThreadResourceConnection';
  readonly edges: ReadonlyArray<ThreadResourceEdge>;
  readonly pageInfo: PageInfo;
};

export type ThreadResourceEdge = {
  readonly __typename: 'ThreadResourceEdge';
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String']['output'];
  readonly hasNextPage: Maybe<Scalars['Boolean']['output']>;
  readonly hasPreviousPage: Maybe<Scalars['Boolean']['output']>;
  readonly node: ThreadResource;
};

export type ThreadResourceItem = Discussion | File | Image | Link | Media | Meeting | Team;

/** Thread resource summary chunk */
export type ThreadResourceSummaryChunk = INode & {
  readonly __typename: 'ThreadResourceSummaryChunk';
  readonly id: Scalars['ID']['output'];
  /** Corresponding page of resource, if applicable */
  readonly page: Maybe<Scalars['Float']['output']>;
  readonly resource: ThreadResource;
};

export enum ThreadResourceType {
  DISCUSSION = 'Discussion',
  FILE = 'File',
  IMAGE = 'Image',
  LINK = 'Link',
  MEDIA = 'Media',
  MEETING = 'Meeting',
  TEAM = 'Team'
}

export type ThreadResourcesFiltersInput = {
  readonly pinned?: InputMaybe<Scalars['Boolean']['input']>;
  readonly resourceType?: InputMaybe<ReadonlyArray<InputMaybe<ThreadResourceType>>>;
};

export type ThreadResourcesSortInput = {
  readonly elevatePinned?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Thread summary chapter */
export type ThreadSummaryChapter = INode & IThreadSummaryEventGroup & {
  readonly __typename: 'ThreadSummaryChapter';
  readonly durationSeconds: Scalars['Float']['output'];
  /** ThreadSummaryEvents that roll up to this IThreadSummaryGroup */
  readonly eventConnections: ThreadSummaryEventConnection;
  readonly id: Scalars['ID']['output'];
  /** Applicable only for meeting chunks */
  readonly meetingOffsetSeconds: Maybe<Scalars['Float']['output']>;
  readonly title: Scalars['String']['output'];
};


/** Thread summary chapter */
export type ThreadSummaryChaptereventConnectionsArgs = {
  page?: InputMaybe<PaginationArgsInput>;
};

/** Thread summary chunk */
export type ThreadSummaryChunk = INode & IThreadSummaryEventGroup & {
  readonly __typename: 'ThreadSummaryChunk';
  readonly durationSeconds: Scalars['Float']['output'];
  /** ThreadSummaryEvents that roll up to this IThreadSummaryGroup */
  readonly eventConnections: ThreadSummaryEventConnection;
  readonly firstEventTime: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Applicable only for meeting chunks */
  readonly meetingOffsetSeconds: Maybe<Scalars['Float']['output']>;
  readonly permalink: Scalars['String']['output'];
  /** @deprecated Use events field instead */
  readonly summary: Maybe<Scalars['String']['output']>;
  readonly thread: Maybe<IThread>;
  /** @deprecated No longer used */
  readonly thumbnailImage: Maybe<Image>;
  readonly title: Scalars['String']['output'];
};


/** Thread summary chunk */
export type ThreadSummaryChunkeventConnectionsArgs = {
  page?: InputMaybe<PaginationArgsInput>;
};

export type ThreadSummaryEventConnection = {
  readonly __typename: 'ThreadSummaryEventConnection';
  readonly edges: ReadonlyArray<ThreadSummaryEventEdge>;
  readonly pageInfo: PageInfo;
};

export type ThreadSummaryEventEdge = {
  readonly __typename: 'ThreadSummaryEventEdge';
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String']['output'];
  readonly hasNextPage: Maybe<Scalars['Boolean']['output']>;
  readonly hasPreviousPage: Maybe<Scalars['Boolean']['output']>;
  readonly node: IThreadSummaryEvent;
};

export type ThreadSummaryEventGroupConnection = {
  readonly __typename: 'ThreadSummaryEventGroupConnection';
  readonly edges: ReadonlyArray<ThreadSummaryEventGroupEdge>;
  readonly pageInfo: PageInfo;
};

export type ThreadSummaryEventGroupEdge = {
  readonly __typename: 'ThreadSummaryEventGroupEdge';
  /** Used in `before` and `after` args */
  readonly cursor: Scalars['String']['output'];
  readonly hasNextPage: Maybe<Scalars['Boolean']['output']>;
  readonly hasPreviousPage: Maybe<Scalars['Boolean']['output']>;
  readonly node: IThreadSummaryEventGroup;
};

export enum ThreadSummaryEventType {
  ACTION_ITEM = 'ACTION_ITEM',
  SUMMARY_ITEM = 'SUMMARY_ITEM'
}

export type ThumbnailOffsetRangeInput = {
  /** (Optional) End of range as fraction or seconds. If not provided, defaults to `start.` */
  readonly end?: InputMaybe<Scalars['Float']['input']>;
  /** Start of range as fraction of seconds */
  readonly start?: InputMaybe<Scalars['Float']['input']>;
};

export type TypeaheadProfileQueryFilterInputType = {
  readonly profileTypes?: InputMaybe<ReadonlyArray<InputMaybe<TypeaheadProfileQueryProfileType>>>;
};

export enum TypeaheadProfileQueryProfileType {
  ACCOUNT = 'ACCOUNT',
  DISCUSSION = 'DISCUSSION',
  MEETING = 'MEETING',
  PRIVATE_CHAT = 'PRIVATE_CHAT',
  TEAM = 'TEAM'
}

export type TypeaheadQueryResult = {
  readonly __typename: 'TypeaheadQueryResult';
  readonly highlights: Maybe<TypeaheadQueryResultHighlights>;
  readonly profile: Maybe<IProfile>;
  readonly score: Maybe<Scalars['Float']['output']>;
};

export type TypeaheadQueryResultHighlights = {
  readonly __typename: 'TypeaheadQueryResultHighlights';
  readonly highlights: ReadonlyArray<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
};

export type UpdateAssistantSessionInput = {
  /** Updated contextFilters for session */
  readonly contextFilters?: InputMaybe<AssistantContextFiltersInput>;
  /** Updated name for session */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly sessionId: Scalars['ID']['input'];
};

export type UpdateDesktopNotificationDeliveryStateInput = {
  readonly id: Scalars['ID']['input'];
  readonly state?: InputMaybe<NotificationDeliveryState>;
};

/** update fields on discussion */
export type UpdateDiscussionInput = {
  readonly audience?: InputMaybe<AudienceType>;
  readonly discussionId: Scalars['ID']['input'];
  readonly icon?: InputMaybe<DiscussionIconType>;
  readonly subject?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmojiSkinToneInput = {
  readonly emojiSkinTone?: InputMaybe<EmojiSkinTone>;
};

/** Updates target's permission grants */
export type UpdateGrantsForEntityInput = {
  /** When set true, any grants on entity not included in grants input will be removed. Be careful using this option! */
  readonly allowImplicitDeletes?: InputMaybe<Scalars['Boolean']['input']>;
  readonly grants: ReadonlyArray<GrantInput>;
  readonly notifyAddedMembers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateLocaleInput = {
  readonly locale?: InputMaybe<Scalars['String']['input']>;
  readonly timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMeetingInput = {
  readonly meetingId: Scalars['ID']['input'];
  readonly name?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateMeetingSeriesInput = {
  readonly meetingSeriesId: Scalars['ID']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

/** Updates an account's membership status for a group */
export type UpdateMembershipInput = {
  readonly memberships: ReadonlyArray<MembershipInput>;
  readonly notifyAddedMembers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateNoteInput = {
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly noteId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateNotificationChannelInput = {
  readonly enabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id: Scalars['ID']['input'];
};

/** Update profile */
export type UpdateProfileInput = {
  readonly id: Scalars['ID']['input'];
  readonly profileImageId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateReactionsInput = {
  readonly emojiId: Scalars['ID']['input'];
  readonly threadEventId: Scalars['ID']['input'];
};

export type UpdateSectionInput = {
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly position?: InputMaybe<Scalars['Int']['input']>;
  readonly sectionId: Scalars['ID']['input'];
};

export type UpdateTeamInput = {
  readonly audience?: InputMaybe<AudienceType>;
  readonly icon?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly teamId: Scalars['ID']['input'];
};

/** broadcast that user is typing on thread */
export type UserIsTypingInput = {
  readonly threadId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserTypeAssistantMessage = IAssistantMessage & INode & {
  readonly __typename: 'UserTypeAssistantMessage';
  readonly body: Scalars['String']['output'];
  readonly contextFilters: AssistantContextFilters;
  readonly id: Scalars['ID']['output'];
  readonly session: AssistantSession;
  readonly state: AssistantMessageState;
};

/** YDoc object */
export type YDoc = {
  readonly __typename: 'YDoc';
  readonly id: Scalars['ID']['output'];
  readonly version: Maybe<Scalars['Float']['output']>;
};


      export type PossibleTypesResultData = {
  "possibleTypes": {
    "CanHavePermissionRole": [
      "Account",
      "MaskedTeam",
      "Org",
      "PublicAudience",
      "Team"
    ],
    "FileUpload": [
      "File",
      "Image",
      "Media"
    ],
    "IAccountGroup": [
      "Discussion",
      "MaskedTeam",
      "Meeting",
      "Replies",
      "Team"
    ],
    "IAssistantMessage": [
      "AssistantTypeAssistantMessage",
      "UserTypeAssistantMessage"
    ],
    "IFollowable": [
      "Discussion",
      "Meeting",
      "PrivateChat",
      "Replies",
      "Team"
    ],
    "IHasPermissionProfile": [
      "Discussion"
    ],
    "INode": [
      "AccessRequestThreadEvent",
      "Account",
      "ActionItemThreadSummaryEvent",
      "AppObject",
      "AssistantTypeAssistantMessage",
      "AudienceMemberUpdatedThreadEvent",
      "AudienceUpdatedThreadEvent",
      "Discussion",
      "DiscussionCreatedThreadEvent",
      "DiscussionPermissionProfile",
      "DiscussionRenamedThreadEvent",
      "DiscussionStartThreadEvent",
      "MaskedTeam",
      "Meeting",
      "MeetingCreatedThreadEvent",
      "MeetingEndedThreadEvent",
      "MeetingParticipantsJoinedThreadEvent",
      "MeetingParticipantsLeftThreadEvent",
      "MeetingSeries",
      "MeetingStartedThreadEvent",
      "MentionedThreadEvent",
      "MessageCreatedThreadEvent",
      "MessageScheduledThreadEvent",
      "NoteCreatedThreadEvent",
      "Org",
      "PrivateChat",
      "PublicAudience",
      "Replies",
      "SummaryItemThreadSummaryEvent",
      "TagAddedThreadEvent",
      "TagRemovedThreadEvent",
      "Team",
      "TeamStartedThreadEvent",
      "ThreadEventMovedThreadEvent",
      "ThreadParentUpdatedThreadEvent",
      "ThreadResourceSummaryChunk",
      "ThreadSummaryChapter",
      "ThreadSummaryChunk",
      "UserTypeAssistantMessage"
    ],
    "IPermissionProfile": [
      "DiscussionPermissionProfile"
    ],
    "IProfile": [
      "Account",
      "AppObject",
      "Discussion",
      "MaskedTeam",
      "Meeting",
      "PrivateChat",
      "Replies",
      "Team"
    ],
    "ITaggable": [
      "Discussion",
      "Meeting",
      "PrivateChat"
    ],
    "IThread": [
      "Discussion",
      "Meeting",
      "PrivateChat",
      "Replies",
      "Team"
    ],
    "IThreadEvent": [
      "AccessRequestThreadEvent",
      "AudienceMemberUpdatedThreadEvent",
      "AudienceUpdatedThreadEvent",
      "DiscussionCreatedThreadEvent",
      "DiscussionRenamedThreadEvent",
      "DiscussionStartThreadEvent",
      "MeetingCreatedThreadEvent",
      "MeetingEndedThreadEvent",
      "MeetingParticipantsJoinedThreadEvent",
      "MeetingParticipantsLeftThreadEvent",
      "MeetingStartedThreadEvent",
      "MentionedThreadEvent",
      "MessageCreatedThreadEvent",
      "MessageScheduledThreadEvent",
      "NoteCreatedThreadEvent",
      "TagAddedThreadEvent",
      "TagRemovedThreadEvent",
      "TeamStartedThreadEvent",
      "ThreadEventMovedThreadEvent",
      "ThreadParentUpdatedThreadEvent"
    ],
    "IThreadSummaryEvent": [
      "ActionItemThreadSummaryEvent",
      "SummaryItemThreadSummaryEvent"
    ],
    "IThreadSummaryEventGroup": [
      "ThreadSummaryChapter",
      "ThreadSummaryChunk"
    ],
    "MeetingEvent": [
      "MeetingEventActiveSpeaker",
      "MeetingEventContentShare",
      "MeetingEventTranscript"
    ],
    "NavShortcutTarget": [
      "Account",
      "Discussion",
      "PrivateChat",
      "Team"
    ],
    "SummaryChunk": [
      "ThreadResourceSummaryChunk",
      "ThreadSummaryChunk"
    ],
    "ThreadResourceItem": [
      "Discussion",
      "File",
      "Image",
      "Link",
      "Media",
      "Meeting",
      "Team"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CanHavePermissionRole": [
      "Account",
      "MaskedTeam",
      "Org",
      "PublicAudience",
      "Team"
    ],
    "FileUpload": [
      "File",
      "Image",
      "Media"
    ],
    "IAccountGroup": [
      "Discussion",
      "MaskedTeam",
      "Meeting",
      "Replies",
      "Team"
    ],
    "IAssistantMessage": [
      "AssistantTypeAssistantMessage",
      "UserTypeAssistantMessage"
    ],
    "IFollowable": [
      "Discussion",
      "Meeting",
      "PrivateChat",
      "Replies",
      "Team"
    ],
    "IHasPermissionProfile": [
      "Discussion"
    ],
    "INode": [
      "AccessRequestThreadEvent",
      "Account",
      "ActionItemThreadSummaryEvent",
      "AppObject",
      "AssistantTypeAssistantMessage",
      "AudienceMemberUpdatedThreadEvent",
      "AudienceUpdatedThreadEvent",
      "Discussion",
      "DiscussionCreatedThreadEvent",
      "DiscussionPermissionProfile",
      "DiscussionRenamedThreadEvent",
      "DiscussionStartThreadEvent",
      "MaskedTeam",
      "Meeting",
      "MeetingCreatedThreadEvent",
      "MeetingEndedThreadEvent",
      "MeetingParticipantsJoinedThreadEvent",
      "MeetingParticipantsLeftThreadEvent",
      "MeetingSeries",
      "MeetingStartedThreadEvent",
      "MentionedThreadEvent",
      "MessageCreatedThreadEvent",
      "MessageScheduledThreadEvent",
      "NoteCreatedThreadEvent",
      "Org",
      "PrivateChat",
      "PublicAudience",
      "Replies",
      "SummaryItemThreadSummaryEvent",
      "TagAddedThreadEvent",
      "TagRemovedThreadEvent",
      "Team",
      "TeamStartedThreadEvent",
      "ThreadEventMovedThreadEvent",
      "ThreadParentUpdatedThreadEvent",
      "ThreadResourceSummaryChunk",
      "ThreadSummaryChapter",
      "ThreadSummaryChunk",
      "UserTypeAssistantMessage"
    ],
    "IPermissionProfile": [
      "DiscussionPermissionProfile"
    ],
    "IProfile": [
      "Account",
      "AppObject",
      "Discussion",
      "MaskedTeam",
      "Meeting",
      "PrivateChat",
      "Replies",
      "Team"
    ],
    "ITaggable": [
      "Discussion",
      "Meeting",
      "PrivateChat"
    ],
    "IThread": [
      "Discussion",
      "Meeting",
      "PrivateChat",
      "Replies",
      "Team"
    ],
    "IThreadEvent": [
      "AccessRequestThreadEvent",
      "AudienceMemberUpdatedThreadEvent",
      "AudienceUpdatedThreadEvent",
      "DiscussionCreatedThreadEvent",
      "DiscussionRenamedThreadEvent",
      "DiscussionStartThreadEvent",
      "MeetingCreatedThreadEvent",
      "MeetingEndedThreadEvent",
      "MeetingParticipantsJoinedThreadEvent",
      "MeetingParticipantsLeftThreadEvent",
      "MeetingStartedThreadEvent",
      "MentionedThreadEvent",
      "MessageCreatedThreadEvent",
      "MessageScheduledThreadEvent",
      "NoteCreatedThreadEvent",
      "TagAddedThreadEvent",
      "TagRemovedThreadEvent",
      "TeamStartedThreadEvent",
      "ThreadEventMovedThreadEvent",
      "ThreadParentUpdatedThreadEvent"
    ],
    "IThreadSummaryEvent": [
      "ActionItemThreadSummaryEvent",
      "SummaryItemThreadSummaryEvent"
    ],
    "IThreadSummaryEventGroup": [
      "ThreadSummaryChapter",
      "ThreadSummaryChunk"
    ],
    "MeetingEvent": [
      "MeetingEventActiveSpeaker",
      "MeetingEventContentShare",
      "MeetingEventTranscript"
    ],
    "NavShortcutTarget": [
      "Account",
      "Discussion",
      "PrivateChat",
      "Team"
    ],
    "SummaryChunk": [
      "ThreadResourceSummaryChunk",
      "ThreadSummaryChunk"
    ],
    "ThreadResourceItem": [
      "Discussion",
      "File",
      "Image",
      "Link",
      "Media",
      "Meeting",
      "Team"
    ]
  }
};
      export default result;
    