import { useApolloClient } from '@apollo/client';
import { IS_DEV, RTElementType, RTSerializer, RTTextNodeMark, toSlateNodes } from '@rmvw/x-common';
import * as React from 'react';
import { Descendant, Editor, Element as SlateElement, createEditor } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';

import { RTEditor, withRTExtensions } from '../../lib/rich-text/Editor';
import Alert from '../Alert';
import ErrorBoundary from '../ErrorBoundary';

import ElementRenderer from './elements/ElementRenderer';
import LeafRenderer from './leaves/LeafRenderer';
import useRTDecorators from './useRTDecorators';

export interface IBaseRichTextProps {
  className?: string;
  value: string;
  isPreview?: boolean;
}

/**
 * RichText renderer
 */
export default function BaseRichText(props: IBaseRichTextProps) {
  const apolloClient = useApolloClient();
  const editor = React.useMemo(
    () => withRTExtensions(withReact(createEditor() as RTEditor), { apolloClient }),
    [apolloClient]
  );
  const { decorate } = useRTDecorators({ editor });
  const renderElement = React.useCallback(
    (p) => <ElementRenderer {...p} isPreview={props.isPreview} />,
    [props.isPreview]
  );
  const renderLeaf = React.useCallback((p) => <LeafRenderer {...p} />, []);
  const updateCounter = React.useRef(0);
  const value = React.useMemo((): Descendant[] => {
    try {
      // updateCounter is hack to deal with the fact that Slate isn't a proper React component right now
      // (@see https://github.com/ianstormtaylor/slate/releases/tag/slate-react%400.67.0)
      updateCounter.current++;
      return toSlateNodes(RTSerializer.deserialize(props.value));
    } catch (e) {
      return [
        {
          type: RTElementType.DIV,
          children: [{ [RTTextNodeMark.ITALIC]: true, text: 'There was a problem loading this document.' }],
        },
      ];
    }
  }, [props.value]);

  return (
    <ErrorBoundary
      renderError={(error) => {
        const match = error.message.match(/path \[(.*?)\]/);
        if (!IS_DEV || !match) {
          throw error; // re-throw up to ThreadEvent ErrorBoundary
        }

        // Internal debugging information to assist developers
        const path = match[1].split(',').map((x) => parseInt(x, 10));
        const [node] = Editor.node(editor, path);
        return (
          <Alert variant="danger">
            Error:
            <pre>{error.message}</pre>
            Node:
            <pre>{JSON.stringify({ ...node, children: undefined })}</pre>
            {SlateElement.isElement(node) && (
              <>
                Children:
                <pre>{JSON.stringify(node.children)}</pre>
              </>
            )}
          </Alert>
        );
      }}
    >
      <Slate editor={editor} key={updateCounter.current} initialValue={value}>
        <Editable
          className={props.className}
          decorate={decorate}
          readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    </ErrorBoundary>
  );
}
