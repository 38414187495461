import { gql } from '@apollo/client';
import { Paths } from '@rmvw/x-common';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { truncateText } from '../../../lib/css';
import { useAppMeetingState } from '../../../providers/AppMeetingStateProvider';
import LivePreview from '../../meeting/LivePreview';
import MeetingMediaPreview from '../../meeting/MeetingMediaPreview';
import OverflowMask from '../../OverflowMask';
import Spinner from '../../Spinner';

// Circular dependency issue
const RichText = React.lazy(() => import('../../rich-text/RichText'));

import { CF_ThreadMeetingPreview } from './___generated___/MeetingPreview.types';

const _MeetingPreviewContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  overflow: hidden;
`;

const _MediaContainer = styled.div<{ $maxWidth?: string }>`
  max-width: ${({ $maxWidth, theme }) => $maxWidth ?? theme.dimension.mediaContainer.maxWidth};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    max-width: unset;
  }
`;

const _SummaryContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
  max-width: 100%;
  min-width: 200px;
  ${truncateText()}
`;

const _Abstract = styled.div`
  color: ${({ theme }) => theme.color.secondaryColor};
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  font-size: ${({ theme }) => theme.fontSize.p};
  gap: 4px;
`;

const _Name = styled(Link)`
  color: ${({ theme }) => theme.color.variant.secondary.text};
  flex-shrink: 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${truncateText()}
`;

const _ActionLink = styled(Link)`
  color: ${({ theme }) => theme.color.variant.secondary.color};
  flex-shrink: 0;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
`;

export interface IMeetingPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
  mediaMaxWidth?: string;
  meeting: CF_ThreadMeetingPreview;
  omitAbstract?: boolean;
  omitActionLink?: boolean;
}

/**
 * @deprecated Use `VideoChatPreviewCard` instead.
 */
function MeetingPreview({ mediaMaxWidth, meeting, omitAbstract, omitActionLink, ...props }: IMeetingPreviewProps) {
  const navigate = useNavigate();
  const { showMeeting } = useAppMeetingState();

  const [playing, setPlaying] = React.useState(false);

  let media;
  if (meeting.isActive) {
    media = <LivePreview meeting={meeting} />;
  } else if (meeting.media) {
    media = (
      <MeetingMediaPreview
        endTime={new Date(meeting.endTime)}
        media={meeting.media}
        onClick={(offsetSec) => navigate(Paths.MEETING(meeting.id, { offsetSec }))}
        playing={playing}
        startTime={new Date(meeting.startTime)}
      />
    );
  } else {
    return null; // There's no preview to render!
  }

  return (
    <_MeetingPreviewContainer {...props}>
      <_MediaContainer
        $maxWidth={mediaMaxWidth}
        onMouseEnter={() => setPlaying(true)}
        onMouseLeave={() => setPlaying(false)}
      >
        {media}
      </_MediaContainer>
      {(!omitAbstract || !omitActionLink) && (
        <_SummaryContainer>
          {!omitAbstract && (
            <>
              <_Name
                to={meeting.permalink}
                onClick={(e) => {
                  // Intercept click if meeting is active
                  if (meeting.isActive) {
                    e.preventDefault();
                    meeting.sourceMeetingSeries && showMeeting({ seriesCode: meeting.sourceMeetingSeries.code });
                  }
                }}
              >
                {meeting.name}
              </_Name>
              <OverflowMask maxHeight={mediaMaxWidth} expandable>
                <React.Suspense fallback={<Spinner />}>
                  <_Abstract>{meeting.abstract && <RichText value={meeting.abstract} />}</_Abstract>
                </React.Suspense>
              </OverflowMask>
            </>
          )}
          {!omitActionLink && (
            <_ActionLink
              to={meeting.permalink}
              onClick={(e) => {
                // Intercept click if meeting is active
                if (meeting.isActive) {
                  e.preventDefault();
                  meeting.sourceMeetingSeries && showMeeting({ seriesCode: meeting.sourceMeetingSeries.code });
                }
              }}
            >
              {meeting.isActive ? 'Join' : 'Watch'} Video Chat
            </_ActionLink>
          )}
        </_SummaryContainer>
      )}
    </_MeetingPreviewContainer>
  );
}

MeetingPreview.fragment = gql`
  ${LivePreview.fragment}
  ${MeetingMediaPreview.fragment}

  fragment CF_ThreadMeetingPreview on Meeting {
    id
    abstract
    isActive
    permalink
    media {
      id
      ...CF_MeetingMediaPreview
    }
    sourceMeetingSeries {
      id
      code
    }
    name
    startTime
    endTime
    ...CF_LivePreviewMeeting
  }
`;

export default MeetingPreview;
