import { Paths } from '@rmvw/x-common';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { TypeaheadProfileQueryProfileType } from '../../../___generated___/globalTypes';
import { IS_MAC } from '../../../env';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { useLoggedInAccount } from '../../../hooks/useLoggedInAccount';
import Logger from '../../../lib/observability/Logger';
import { CanonicalSizePx } from '../../../providers/ThemeProvider/themes';
import { SearchIcon } from '../../Icon';
import { TypeaheadRef } from '../../Typeahead';
import {
  BaseSearchTypeahead,
  IBaseSearchTypeaheadProps,
  IUberSearchTypeaheadOption,
} from '../../Typeahead/SearchTypeahead';
import { useSearchTypeaheadOptionsQuery } from '../../Typeahead/SearchTypeahead/useSearchTypeaheadOptionsQuery';

const SEARCH_ACTION_ID = 'search-action';

const PROFILE_TYPES = [
  TypeaheadProfileQueryProfileType.DISCUSSION,
  TypeaheadProfileQueryProfileType.MEETING,
  TypeaheadProfileQueryProfileType.PRIVATE_CHAT,
  TypeaheadProfileQueryProfileType.TEAM,
];

const _Container = styled.div`
  width: 100%;
`;

const _SearchTypeahead = styled(BaseSearchTypeahead)<{ $inputPadding: number }>`
  .rbt-input-wrapper {
    padding-left: ${({ $inputPadding }) => $inputPadding}px;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 16px 24px;
  }

  .dropdown-header {
    margin-bottom: 6px;
    padding: 0;
  }

  .dropdown-item {
    margin: 0;
    padding: 4px 8px 4px 6px;
  }
`;

const _SearchIconContainer = styled.div<{ $iconPadding: number }>`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: ${({ $iconPadding }) => $iconPadding}px;
  position: absolute;
  top: 0;
`;

const _SearchIcon = styled(SearchIcon)`
  color: ${({ theme }) => theme.color.secondaryColor};
`;

export default function HeaderSearch({
  className,
  limit,
  onExecute,
  ...props
}: Partial<IBaseSearchTypeaheadProps> & { limit?: number; onExecute?: () => void }) {
  const account = useLoggedInAccount();
  const navigate = useNavigate();
  const theme = useTheme();

  const { isMobileSize } = useBreakpoints();

  const ref = React.useRef<TypeaheadRef>(null);

  const [query, setQuery] = React.useState('');
  const [menuOpen, setMenuOpen] = React.useState(false);

  const size = props.size === undefined ? 'md' : props.size; // sm, md, lg
  const iconSize =
    size === 'sm' ? CanonicalSizePx.xxSmall : size === 'md' ? CanonicalSizePx.xSmall : CanonicalSizePx.small;
  const iconPadding = size === 'sm' ? 7 : size === 'md' ? 10 : 12;

  const {
    loading,
    refetch,
    typeaheadOptions: searchResultOptions,
  } = useSearchTypeaheadOptionsQuery({
    enableNullQuery: true, // Enable null query to show recent activity
    filters: { profileTypes: PROFILE_TYPES },
    limit: limit ?? 15,
    query,
  });

  if (!account) {
    return null;
  }

  // If we've selected more than one filter option, inject a "New discussion..." action
  // (In the singular case, the canonical PrivateChat conversation should always be first billing already)

  const options: IUberSearchTypeaheadOption[] = [
    ...searchResultOptions.map((o): IUberSearchTypeaheadOption => ({ optionType: 'RESULT', ...o })),
    // Inject advanced search action at end of list if query is present
    ...(query
      ? [
          {
            id: SEARCH_ACTION_ID,
            hotkeyHint:
              !isMobileSize && searchResultOptions.length ? ['Shift', IS_MAC ? 'Return' : 'Enter'] : undefined,
            icon: <SearchIcon color={theme.color.secondaryColor} size="small" />,
            name: query,
            onClick: () => navigate(Paths.SEARCH(query)),
            optionType: 'ACTION' as const,
            preview: 'Search all conversations...',
          },
        ]
      : []),
  ].filter((o) => o.id !== account.id);

  const _executeOption = (option: IUberSearchTypeaheadOption) => {
    // Blur focus
    ref.current?.blur();

    switch (option.optionType) {
      case 'RESULT': {
        ref.current?.clear();
        const permalink = option.permalink;
        permalink && navigate(permalink);
        break;
      }

      case 'ACTION': {
        option.onClick();
        break;
      }

      default:
        Logger.error(`[HeaderSearch] Unknown optionType ${option.optionType}`);
        break;
    }

    onExecute?.();
  };

  return (
    <_Container className={className}>
      <_SearchTypeahead
        {...props}
        $inputPadding={iconSize + 4}
        filterBy={() => true} // bypass default filtering (search back-end handles filtering)
        maxHeight={`calc(100vh - ${theme.dimension.header.height} - 16px)`}
        multiple
        open={menuOpen}
        id="header-search"
        isLoading={loading}
        labelKey="name"
        onBlur={(e) => {
          props.onBlur?.(e);
          setMenuOpen(false);
        }}
        onChange={(selected) => {
          const pickedOption = selected[0] ?? null;
          pickedOption && _executeOption(pickedOption);
        }}
        onInputChange={setQuery}
        onFocus={() => {
          refetch().catch((e) => Logger.error(e as Error, '[HeaderSearch] Failed to refetch search results'));
          setMenuOpen(true);
        }}
        onKeyDown={(e) => {
          // When escape key is pressed, blur the input and reset the query.
          if (e.key === 'Escape') {
            setQuery('');
            ref.current?.clear();
            ref.current?.blur();
          }

          // When enter key is pressed and there is no active menu item...
          if (e.key === 'Enter' && !ref.current?.state.activeItem && options.length) {
            if (e.shiftKey && query) {
              // Shift + Enter: Search all conversations
              navigate(Paths.SEARCH(query));
              onExecute?.();
            } else {
              const firstResultOption = options.find((o) => o.optionType === 'RESULT' || o.id === SEARCH_ACTION_ID);
              firstResultOption && _executeOption(firstResultOption);
            }
          }
        }}
        options={options}
        placeholder="Search conversations..."
        ref={ref}
      >
        <_SearchIconContainer $iconPadding={iconPadding}>
          <_SearchIcon size={iconSize} />
        </_SearchIconContainer>
      </_SearchTypeahead>
    </_Container>
  );
}
