import { gql, useQuery } from '@apollo/client';
import { LegacyPalette } from '@rmvw/c-common';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { hexColorWithAlpha, lineClamp, truncateText } from '../../lib/css';
import Logger from '../../lib/observability/Logger';
import Alert from '../Alert';
import Spinner from '../Spinner/Spinner';

import {
  BATCHED__LinkPreview_GetURLMetadata,
  BATCHED__LinkPreview_GetURLMetadataVariables,
} from './___generated___/LinkPreview.types';
import GithubPRPreview from './GithubPRPreview';

type BATCHED__LinkPreview_GetURLMetadata_link = NonNullable<BATCHED__LinkPreview_GetURLMetadata['link']>;

const MAX_IMAGE_SIZE_TABLET = 128;
const MAX_IMAGE_SIZE_MOBILE = 72;

const fragment = gql`
  fragment CF_LinkPreview on Link {
    id
    description
    image {
      id
      url: url_128
    }
    processed
    url
    title
    appObject {
      id
      app
      objectId
      objectData
    }
  }
`;

const GetURLMetadataQuery = gql`
  ${fragment}
  # Batched query
  query BATCHED__LinkPreview_GetURLMetadata($url: String) {
    link(url: $url) {
      ...CF_LinkPreview
    }
  }
`;

type LinkMetadata = BATCHED__LinkPreview_GetURLMetadata_link | null;

function insufficientMetadata(link?: LinkMetadata) {
  return (link?.description ? 1 : 0) + (link?.title ? 1 : 0) + (link?.image ? 1 : 0) < 1 && !link?.appObject;
}

export interface ILinkPreviewProps {
  error?: boolean;
  link?: LinkMetadata;
  loading: boolean;
  onClose?: () => void;
}

export function LinkPreview({ error, link, loading }: ILinkPreviewProps) {
  const navigate = useNavigate();

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (error || !link || insufficientMetadata(link)) {
    return <Alert variant="danger">Sorry, we couldn&rsquo;t display a preview.</Alert>;
  }

  function onClick() {
    const isTextSelected = window.getSelection()?.toString();
    if (!isTextSelected) {
      window.open(link?.url, '_blank');
    }
  }

  const isInternalLink = new URL(link.url, location.href).host === new URL(location.href).host;
  const linkProps = isInternalLink
    ? {
        href: '#',
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          navigate(new URL(link.url, location.href).pathname);
        },
      }
    : {
        href: link.url,
        target: '_blank',
      };

  return link.appObject ? (
    <GithubPRPreview objectData={link.appObject.objectData} />
  ) : (
    <_Container>
      {link.image?.url && (
        <_Image>
          <img style={{ width: '100%' }} alt="preview image" src={link.image.url} onClick={onClick} />
        </_Image>
      )}
      <_Content>
        {link.title && <_Title onClick={onClick}>{link.title}</_Title>}
        <_Link {...linkProps}>{link.url}</_Link>
        {link.description && <_Description onClick={onClick}>{link.description}</_Description>}
      </_Content>
    </_Container>
  );
}

LinkPreview.fragment = fragment;

interface IProps {
  url: string;
  onId?: (id: string) => void;
}

export default function LinkPreviewWithQuery(props: IProps) {
  const { data, error, loading } = useQuery<
    BATCHED__LinkPreview_GetURLMetadata,
    BATCHED__LinkPreview_GetURLMetadataVariables
  >(GetURLMetadataQuery, {
    variables: { url: props.url },
    onCompleted: (data) => {
      if (data.link?.id) {
        props.onId?.(data.link.id);
      }
    },
  });

  if (error) {
    Logger.info(`[LinkPreview] Error fetching ${props.url}, error: ${error.message}`);
  }

  if (!loading && !data?.link) {
    Logger.error(`[LinkPreview] Empty data for ${props.url}, check url is valid`);
  }

  return <LinkPreview error={!!error} link={data?.link} loading={loading} />;
}

const _Container = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  gap: 16px;
`;

const _Image = styled.div`
  cursor: pointer;
  height: fit-content;
  max-width: ${MAX_IMAGE_SIZE_TABLET}px;
  position: relative;

  &::after {
    border: 1px solid
      ${({ theme }) =>
        hexColorWithAlpha(theme.spec.scheme === 'light' ? LegacyPalette.black : LegacyPalette.white, 0.1)};
    content: '';
    inset: 0;
    position: absolute;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    max-width: ${MAX_IMAGE_SIZE_MOBILE}px;
  }
`;

const _Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
`;

const _Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${lineClamp(2)}

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    display: unset;
    ${truncateText()}
  }
`;

const _Link = styled.a`
  display: block;
  ${truncateText()}
`;

const _Description = styled.div`
  ${lineClamp(3)}

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}) {
    ${lineClamp(2)}
  }
`;
