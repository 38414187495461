import { gql, useQuery } from '@apollo/client';
import { REQUEST_CONTEXT_SAMPLE_RATE } from '@rmvw/c-common';
import { IS_DEV } from '@rmvw/x-common';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { ActivityStatus } from '../../___generated___/globalTypes';
import { getStatusColor } from '../../lib/Status';
import { ProfileAvatarById } from '../Avatar';
import { ClockIcon, GlobeIcon, SettingsIcon } from '../Icon';
import OverflowMask from '../OverflowMask';

// Mitigate circular dependency
const RichText = React.lazy(() => import('../rich-text/RichText'));

import {
  BATCHED__AccountHoverCardQuery,
  BATCHED__AccountHoverCardQueryVariables,
} from './___generated___/AccountHoverCard.types';
import { HOVER_CARD_AVATAR_SIZE, HoverCard, HoverCardGridRow } from './HoverCard';

const MAX_ABOUT_HEIGHT = '8rem';

const _About = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  margin-top: 8px;
  max-width: 375px;
`;

const _StatusIcon = styled.div<{ color?: string }>`
  background-color: ${({ color, theme }) => color || theme.color.tertiaryColor};
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

export type AccountHoverCardProps = React.PropsWithChildren<{
  id: string;
}>;

const query = gql`
  # Batched query
  query BATCHED__AccountHoverCardQuery($id: ID!) {
    account(id: $id) {
      id
      activityStatus
      name
      org {
        id
        name
      }
      privateChat {
        id
        permalink
      }
      readmeNote {
        id
        body
      }
      timezone
    }
  }
`;

/**
 * Smart version of HoverCard which performs data fetching in addition to render
 */
export default function AccountHoverCard(props: AccountHoverCardProps) {
  const theme = useTheme();
  const [time, setTime] = React.useState(new Date());

  const { data } = useQuery<BATCHED__AccountHoverCardQuery, BATCHED__AccountHoverCardQueryVariables>(query, {
    variables: { id: props.id },
    context: { [REQUEST_CONTEXT_SAMPLE_RATE]: 0 },
  });

  // Keep time state current
  React.useEffect(() => {
    const _interval = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(_interval);
  }, []);

  if (!data?.account?.name) {
    return <>{props.children}</>;
  }

  const _OrgRow = () => {
    return data.account?.org?.name ? (
      <HoverCardGridRow icon={<GlobeIcon size="xxSmall" />}>{data.account.org.name}</HoverCardGridRow>
    ) : null;
  };

  const _TimeRow = () => {
    if (!data?.account?.timezone) {
      return null;
    }

    // If account is in different calendar day from viewer, show additional date info, otherwise just show time
    return (
      <HoverCardGridRow icon={<ClockIcon />}>
        {formatInTimeZone(
          time,
          data.account.timezone,
          format(time, 'MMM d') === formatInTimeZone(time, data.account.timezone, 'MMM d')
            ? 'h:mm aaa zzz'
            : 'eee, MMM d, h:mm aaa zzz'
        )}
      </HoverCardGridRow>
    );
  };

  const _StatusBubble = () => {
    if (!data.account?.activityStatus) {
      return null;
    }
    const status = data.account.activityStatus;

    if (status === ActivityStatus.UNKNOWN) {
      return null;
    }

    let statusLabel = '';
    if (status === ActivityStatus.ACTIVE) {
      statusLabel = 'Active';
    } else if (status === ActivityStatus.AWAY) {
      statusLabel = 'Away';
    } else if (status === ActivityStatus.INACTIVE) {
      statusLabel = 'Offline';
    }

    return <_StatusIcon color={getStatusColor(theme, status)} />;
  };

  const _AccountIdRow = () => {
    if (!IS_DEV) {
      return null;
    }

    return <HoverCardGridRow icon={<SettingsIcon />}>{props.id}</HoverCardGridRow>;
  };

  const content = (
    <>
      <_OrgRow />
      <_TimeRow />
      <_AccountIdRow />
      {data.account.readmeNote?.body && (
        <OverflowMask expandable maxHeight={MAX_ABOUT_HEIGHT}>
          <React.Suspense fallback={null}>
            <_About>
              <RichText value={data.account.readmeNote.body} />
            </_About>
          </React.Suspense>
        </OverflowMask>
      )}
    </>
  );

  return (
    <HoverCard
      avatar={<ProfileAvatarById id={data.account.id} size={HOVER_CARD_AVATAR_SIZE} />}
      chatPermalink={data.account.privateChat?.permalink}
      content={content}
      name={data.account.name}
      status={<_StatusBubble />}
    >
      {props.children}
    </HoverCard>
  );
}
