import { ApolloClient, gql } from '@apollo/client';

import {
  Mutators_CreateImageFromURL,
  Mutators_CreateImageFromURLVariables,
  Mutators_CreateThreadNote,
  Mutators_CreateThreadNoteVariables,
  Mutators_EditMessage,
  Mutators_EditMessageVariables,
  Mutators_UpdateLocale,
  Mutators_UpdateLocaleVariables,
  Mutators_UploadFile,
  Mutators_UploadFileVariables,
} from './___generated___/Mutators.types';

type Mutators_CreateImageFromURL_createImageFromURL = NonNullable<Mutators_CreateImageFromURL['createImageFromURL']>;
type Mutators_UploadFile_uploadFiles = NonNullable<NonNullable<Mutators_UploadFile['uploadFiles']>[number]>;

export async function editMessage(client: ApolloClient<object>, messageId: string, data: { body: string }) {
  const EditMessageMutation = gql`
    mutation Mutators_EditMessage($messageId: ID!, $body: String!) {
      editMessage(input: { messageId: $messageId, body: $body }) {
        id
        editedAt
        body
      }
    }
  `;

  await client.mutate<Mutators_EditMessage, Mutators_EditMessageVariables>({
    mutation: EditMessageMutation,
    variables: {
      body: data.body,
      messageId: messageId,
    },
  });
}

export async function broadcastThreadTypingActivity(client: ApolloClient<object>, threadId: string) {
  await client
    .mutate({
      mutation: gql`
        mutation BroadcastThreadTypingActivity($input: UserIsTypingInput) {
          userIsTyping(input: $input) {
            id
          }
        }
      `,
      variables: { input: { threadId } },
    })
    .catch((e) => null); // Ignore any errors that fire during this low pri operation
}

export async function uploadFile(
  apolloClient: ApolloClient<object>,
  args: { file: File }
): Promise<Mutators_UploadFile_uploadFiles | null> {
  const { default: ImageElement } = await import('../components/rich-text/elements/ImageElement/ImageElement');
  const { default: MediaElement } = await import('../components/rich-text/elements/MediaElement/MediaElement');
  const UploadFileMutation = gql`
    ${ImageElement.fragment}
    ${MediaElement.fragment}
    mutation Mutators_UploadFile($file: Upload!) {
      uploadFiles(input: { files: [$file] }) {
        ... on File {
          id
        }
        ... on Image {
          id
          ...CF_ImageElement
        }
        ... on Media {
          id
          ...CF_MediaElement
        }
      }
    }
  `;

  const result = await apolloClient.mutate<Mutators_UploadFile, Mutators_UploadFileVariables>({
    mutation: UploadFileMutation,
    variables: { file: args.file },
  });

  if (result.errors) {
    throw result.errors[0];
  }

  return result.data?.uploadFiles?.[0] ?? null;
}

export async function createImageFromURL(
  apolloClient: ApolloClient<object>,
  args: { url: string }
): Promise<Mutators_CreateImageFromURL_createImageFromURL | null> {
  const CreateImageFromURLMutation = gql`
    mutation Mutators_CreateImageFromURL($url: String!) {
      createImageFromURL(input: { url: $url }) {
        id
      }
    }
  `;

  const result = await apolloClient.mutate<Mutators_CreateImageFromURL, Mutators_CreateImageFromURLVariables>({
    mutation: CreateImageFromURLMutation,
    variables: { url: args.url },
  });

  if (result.errors) {
    throw result.errors[0];
  }

  return result.data?.createImageFromURL ?? null;
}

export async function createThreadNote(
  apolloClient: ApolloClient<object>,
  args: { threadId: string; name: string }
): Promise<Mutators_CreateThreadNote | null> {
  const CreateThreadNoteMutation = gql`
    mutation Mutators_CreateThreadNote($name: String!, $threadId: ID!) {
      createThreadNote(input: { name: $name, threadId: $threadId }) {
        id
      }
    }
  `;

  const result = await apolloClient.mutate<Mutators_CreateThreadNote, Mutators_CreateThreadNoteVariables>({
    mutation: CreateThreadNoteMutation,
    variables: args,
  });

  return result.data ?? null;
}

export async function updateLocale(
  apolloClient: ApolloClient<object>,
  args: { locale: string; timezone: string }
): Promise<Mutators_UpdateLocale | null> {
  const UpdateLocaleMutation = gql`
    mutation Mutators_UpdateLocale($locale: String!, $timezone: String!) {
      updateLocale(input: { locale: $locale, timezone: $timezone }) {
        id
      }
    }
  `;

  const result = await apolloClient
    .mutate<Mutators_UpdateLocale, Mutators_UpdateLocaleVariables>({
      mutation: UpdateLocaleMutation,
      variables: args,
    })
    .catch((e) => null);

  return result?.data ?? null;
}
