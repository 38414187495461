import * as React from 'react';
import { NodeEntry, Range } from 'slate';

import { RTEditor } from '../../lib/rich-text/Editor';

import { useCodeHighlightDecorator } from './decorators/useCodeHighlightDecorator';
import { useSearchHighlightDecorator } from './decorators/useSearchHighlightDecorator';

export interface IUseDecoratorsProps {
  editor: RTEditor;
}

export interface IUseDecorators {
  decorate: (entry: NodeEntry) => Range[];
}

export default function useRTDecorators({ editor }: IUseDecoratorsProps): IUseDecorators {
  const codeHighlightDecorator = useCodeHighlightDecorator(editor);
  const searchHighlightDecorator = useSearchHighlightDecorator(editor);

  const decorate = React.useMemo((): ((entry: NodeEntry) => Range[]) => {
    const decorators: Array<(entry: NodeEntry) => Range[]> = [codeHighlightDecorator, searchHighlightDecorator];
    return (entry: NodeEntry) => decorators.flatMap((decorator) => decorator(entry));
  }, [codeHighlightDecorator, searchHighlightDecorator]);

  return { decorate };
}
