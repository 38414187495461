import * as React from 'react';

export interface ISearchResultContext {
  keywords?: ReadonlyArray<string>;
}

export const defaultContext: ISearchResultContext = {};

const SearchResultContext = React.createContext<ISearchResultContext>(defaultContext);

interface ISearchResultContextProviderProps {
  keywords?: ReadonlyArray<string>;
}

export default function SearchResultContextProvider({
  children,
  keywords,
}: React.PropsWithChildren<ISearchResultContextProviderProps>) {
  const searchResultContext = { keywords };
  return <SearchResultContext.Provider value={searchResultContext}>{children}</SearchResultContext.Provider>;
}

export const useSearchResultContext = (): ISearchResultContext => {
  const context = React.useContext(SearchResultContext);
  return context;
};
