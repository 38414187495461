import { gql, useApolloClient, useSubscription } from '@apollo/client';
import * as React from 'react';

import { ThreadEventConnection } from './___generated___/globalTypes';
import { ListenEvictionIds, ListenUpdateEvent } from './___generated___/SubscriptionHandler.types';
import {
  AssistantMessageFragmentGQL,
  AssistantSessionFragmentGQL,
} from './components/assistant/useAssistantSessionQuery';
import { ProfileAvatar } from './components/Avatar';
import { LinkPreview } from './components/LinkPreview';
import MeetingMediaPreview from './components/meeting/MeetingMediaPreview';
import MeetingPreview from './components/meeting/MeetingPreview';
import ImageElement from './components/rich-text/elements/ImageElement/ImageElement';
import MediaElement from './components/rich-text/elements/MediaElement/MediaElement';
import { EventFragmentsGQL } from './components/thread/ThreadContent.gql';
import useWayfinders from './components/thread/useWayfinders';
import ThreadResourceListItem from './components/ThreadDetails/ThreadResources/ThreadResourceListItem';
import { StarredThreadEventsFragmentGQL } from './hooks/mutation/useStarThreadEventMutation';
import { useGetExternalResourceQuery } from './hooks/query/useGetExternalResourceQuery';
import { ShortcutSectionFragment } from './hooks/query/useGetShortcutsQuery';
import { useLoggedInAccount } from './hooks/useLoggedInAccount';
import useNotifications from './hooks/useNotifications';
import { deleteFromCache, evictById } from './lib/apollo-cache/utils';
import Logger from './lib/observability/Logger';
import ThreadClientState from './lib/ThreadClientState';

export default function SubscriptionHandler() {
  const client = useApolloClient();
  const loggedInAccount = useLoggedInAccount();

  // listen for any ids to evict
  useSubscription<ListenEvictionIds>(
    gql`
      subscription ListenEvictionIds {
        evictionNotice
      }
    `,
    {
      skip: !loggedInAccount,
      onData: async ({ data }) => {
        evictById(client, data.data?.evictionNotice || null);
      },
    }
  );

  // listen for data updates
  useSubscription<ListenUpdateEvent>(
    gql`
      ${AssistantMessageFragmentGQL}
      ${AssistantSessionFragmentGQL}
      ${EventFragmentsGQL}
      ${ImageElement.fragment}
      ${LinkPreview.fragment}
      ${MediaElement.fragment}
      ${MeetingMediaPreview.fragment}
      ${MeetingPreview.fragment}
      ${ShortcutSectionFragment}
      ${ProfileAvatar.fragment}
      ${StarredThreadEventsFragmentGQL}
      ${ThreadResourceListItem.fragment}
      ${useGetExternalResourceQuery.fragment}
      ${useNotifications.fragment}
      ${useWayfinders.fragment}
      subscription ListenUpdateEvent {
        objectUpdateEvent {
          ...HF_useNotifications
          accountActivityUpdated {
            id
            activityStatus
          }
          accountUpdated {
            id
            ...CF_ProfileAvatar
          }
          assistantMessageCreated {
            id
            session {
              ...CF_AssistantSessionFragment
            }
          }
          assistantMessageUpdated {
            ...CF_AssistantMessageFragment
          }
          assistantSessionUpdated {
            ...CF_AssistantSessionFragment
          }
          composerDraftDeleted {
            id
          }
          discussionUpdated {
            id
            canEditMembership
            canEditPrivacy
            externallyVisible
            icon
            name
            subject
            followers {
              id
              ...CF_ProfileAvatar
            }
            membership {
              account {
                id
              }
              status
            }
            parent {
              id
            }
            parents {
              id
            }
            requests {
              account {
                id
              }
              status
            }
          }
          followChange {
            __typename
            id
            following
            followers {
              id
              ...CF_ProfileAvatar
            }
            ... on IThread {
              archived
              inboxed
              lastEventAt
            }
          }
          imageUpdated {
            id
            ...CF_ImageElement
          }
          linkMetadataUpdated {
            id
            ...CF_LinkPreview
            appObject {
              ...HF_GetExternalResource
            }
          }
          mediaDeleted {
            id
          }
          mediaThumbnailsUpdated {
            id
            previewImage {
              id
            }
          }
          mediaUpdated {
            id
            ...CF_MediaElement
            ...CF_MeetingMediaPreview
          }
          messageEdited {
            id
            editedAt
            body
          }
          meetingCreatedOrJoined {
            id
            meetingParticipants {
              id
              state
            }
            # refetch sourceMeetingSeries to update cache re: active meetings
            sourceMeetingSeries {
              id
              activeMeeting {
                id
              }
            }
            parent {
              id
              lastMeeting {
                id
              }
              meeting {
                id
              }
            }
          }
          meetingUpdated {
            id
            externallyVisible
            livestreamUrl
            parent {
              id
            }
            parents {
              id
            }
            media {
              id
              url
            }
            membership {
              account {
                id
              }
              status
            }
            recordingEnabled
            ...CF_ThreadMeetingPreview
          }
          meetingEnded {
            id
            # refetch sourceMeetingSeries to update cache re: active meetings
            sourceMeetingSeries {
              id
              activeMeeting {
                id
              }
            }
          }
          meetingSummaryUpdated {
            id
            abstract
            name
          }
          meetingParticipantDeclined {
            id
            meeting {
              id
              meetingParticipants {
                id
                state
              }
              parent {
                id
              }
            }
            state
            account {
              id
              isMe
            }
          }
          meetingParticipantInvited {
            id
            meeting {
              id
              meetingParticipants {
                id
                state
              }
              parent {
                id
              }
            }
            state
            account {
              id
              isMe
            }
          }
          meetingParticipantJoined {
            id
            meeting {
              id
              meetingParticipants {
                id
                state
              }
              parent {
                id
              }
            }
            state
            account {
              id
              isMe
            }
          }
          meetingParticipantLeft {
            id
            meeting {
              id
              parent {
                id
              }
            }
            state
          }
          navUpdated {
            id
            defaultSection {
              id
              ...HF_ShortcutSection
            }
            sections {
              id
              ...HF_ShortcutSection
            }
          }

          noteUpdated {
            id
            body
            name
          }
          recentActivity {
            id
            suggestedThreads {
              profile {
                id
              }
            }
          }
          teamCreated {
            id
            teamMemberships {
              id
              name
            }
          }
          teamUpdated {
            id
            audience
            canEditMembership
            canEditPrivacy
            canEditProperties
            icon
            membership {
              account {
                id
              }
              status
            }
          }
          threadCreated {
            id
          }
          threadReadStateChange {
            id
            hasUnread
            unreadMessageCount
          }
          threadUpdated {
            id
            archived
            present {
              id
            }
          }
          threadDeleted {
            id
          }
          threadSummaryUpdated {
            id
            abstract
          }
          threadEventCreated {
            id
            time
            ...ThreadEventFragments
            thread {
              id
              archived
              cursor
              firstUnreadMessageEvent {
                id
                readAt
              }
              followers {
                id
              }
              following
              hasUnread
              inboxed
              length
              lastEvent {
                id
              }
              lastEventAt
              messageCount
              newMessageCount
              newDiscussionCount
              newRepliesCount
              newVideoCount
              unreadMessageCount
              ... on Replies {
                id
                # need to update replyThread on threadEvent object
                parentThreadEvent {
                  id
                  replyThread {
                    id
                  }
                  thread {
                    id
                    newRepliesCount
                  }
                }
              }
            }
          }
          threadEventDeleted {
            id
            thread {
              id
              messageCount
              newRepliesCount
            }
          }
          threadEventMoved {
            threadEventId
            threadEvent {
              id
              ...ThreadEventFragments
              thread {
                id
                inboxed
              }
            }
            from {
              id
              hasUnread
              lastEvent {
                id
              }
              newRepliesCount
            }
          }
          threadEventRead {
            id
            readAt
            thread {
              id
              archived
              following
              inboxed
              firstUnreadMessageEvent {
                id
                readAt
              }
              unreadMessageCount
              ... on Replies {
                parentThreadEvent {
                  id
                  thread {
                    id
                    newRepliesCount
                  }
                }
              }
              ... on Meeting {
                parent {
                  id
                  newRepliesCount
                }
              }
            }
          }
          threadEventUpdated {
            id
            highlightedBy {
              id
            }
            reactions {
              emojiId
              count
              accounts {
                ...CF_ProfileAvatar
              }
              accountsIncludeMe
            }
            readAt
            # starring updates
            starred
            thread {
              ...StarredThreadEventsFragment
            }
          }
          threadNotificationCreated {
            id
            # just re-fetch all thread notifications to update cache
            threadEvent {
              thread {
                id
                inboxed
                ...HF_Wayfinders
              }
            }
          }
          threadNotificationUpdated {
            id
            archived
          }
          threadNotificationDeleted {
            id
            threadEvent {
              thread {
                id
                ...HF_Wayfinders
              }
            }
          }
          threadResourceCreated {
            id
            thread {
              id
            }
          }
          threadResourceDeleted {
            id
            thread {
              id
            }
          }
          threadResourceUpdated {
            id
            ...CF_ThreadResourceListItem
            thread {
              id
            }
          }
          userTypingThread {
            id
            typing {
              id
              name
            }
          }
        }
      }
    `,
    {
      context: {
        debuggerOnResponse: ({ objectUpdateEvent }: ListenUpdateEvent) => {
          if (!objectUpdateEvent) {
            return;
          }
          const updateEventType = Object.entries(objectUpdateEvent).find(
            ([key, value]) => key !== '__typename' && !!value
          )?.[0];
          // assumes only one update per event
          if (updateEventType) {
            Logger.debug(
              (objectUpdateEvent as Record<string, any>)[updateEventType],
              `[SubscriptionHandler] Received update event: ${updateEventType}`
            );
          }
        },
      },
      skip: !loggedInAccount,
      onData: async ({ data: subscriptionData }) => {
        const updateEvent = subscriptionData.data?.objectUpdateEvent;
        if (!updateEvent) {
          return;
        }

        const followChange = updateEvent.followChange;
        const threadEventCreated = updateEvent.threadEventCreated;
        const threadEventRead = updateEvent.threadEventRead;
        const userTypingThread = updateEvent.userTypingThread;
        const threadEventMoved = updateEvent.threadEventMoved;
        const threadNotificationCreated = updateEvent.threadNotificationCreated;

        // handle deletion events
        deleteFromCache(client.cache, updateEvent.composerDraftDeleted);
        deleteFromCache(client.cache, updateEvent.mediaDeleted);
        deleteFromCache(client.cache, updateEvent.meetingEnded);
        deleteFromCache(client.cache, updateEvent.threadCreated);
        deleteFromCache(client.cache, updateEvent.threadDeleted);
        deleteFromCache(client.cache, updateEvent.threadEventDeleted);
        deleteFromCache(client.cache, updateEvent.threadNotificationDeleted);
        deleteFromCache(client.cache, updateEvent.threadResourceDeleted);

        ////////////////////////////////////
        ////  ThreadDeleted
        ////////////////////////////////////
        if (updateEvent.threadDeleted) {
          // refetch inbox and unread count if new thread is followed
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'inboxThreadCount' });
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'threadNotificationCount' });
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'pagedInbox' });
        }

        ////////////////////////////////////
        ////  FollowChange
        ////////////////////////////////////
        if (followChange?.inboxed) {
          // refetch inbox and unread count if new thread is followed
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'inboxThreadCount' });
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'threadNotificationCount' });
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'pagedInbox' });
        }

        ////////////////////////////////////
        ////  ThreadEventRead
        ////////////////////////////////////
        if (threadEventRead && threadEventRead?.thread?.inboxed && !threadEventRead.thread.archived) {
          // hack to refetch inbox unread count whenever a message is marked as read
          // filter to following & !archived to limit number of fetches
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'inboxThreadCount' });
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'threadNotificationCount' });
        }

        ////////////////////////////////////
        ////  Meeting Started/Ended
        ////////////////////////////////////
        if (updateEvent.meetingCreatedOrJoined || updateEvent.meetingEnded) {
          // Hack to refetch active meetings count whenever a meeting is started or ended
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'meetings' });
        }

        /////////////////////////////////////////
        ////  MeetingParticipantInvited
        /////////////////////////////////////////
        if (updateEvent.meetingParticipantInvited) {
          // Hack to refetch pending invitations whenever a meeting invitation is sent or declined
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'meetingParticipantInvitations' });
        }

        ////////////////////////////////////
        ////  ThreadEventMoved
        ////////////////////////////////////
        if (threadEventMoved) {
          client.cache.modify({
            id: client.cache.identify(threadEventMoved.from),
            fields: {
              eventConnections(existing: ThreadEventConnection, { readField }) {
                return {
                  ...existing,
                  edges: existing.edges?.filter(
                    (e) => readField('id', readField('node', e)) !== threadEventMoved.threadEventId
                  ),
                };
              },
            },
          });
        }

        ////////////////////////////////////
        ////  ThreadEventCreated
        ////////////////////////////////////
        if (threadEventCreated || threadEventMoved) {
          const threadEvent = threadEventCreated || threadEventMoved?.threadEvent;
          const thread = threadEvent?.thread;
          if (!thread) {
            return;
          }

          /**
           * Filter out participant join/leave events
           */
          if (
            threadEvent.__typename == 'MeetingParticipantsJoinedThreadEvent' ||
            threadEvent.__typename == 'MeetingParticipantsLeftThreadEvent'
          ) {
            return;
          }

          /**
           * Write message into thread object in cache, relying on merge policy in ApolloProvider
           */
          client.cache.writeFragment({
            id: client.cache.identify({ __typename: thread.__typename, id: thread.id }),
            fragment: gql`
              fragment ThreadEventCreatedFragment on IThread {
                id
                # fragment args are not well supported.  i don't know a good way to pass this in
                # cacheKey should equal PAGED_THREAD_CACHE_KEY
                threadView: eventConnections(cacheKey: "pagedThread") {
                  __typename
                  edges {
                    cursor
                    hasNextPage
                    hasPreviousPage
                    node {
                      id
                    }
                  }
                }
                other: eventConnections {
                  __typename
                  edges {
                    cursor
                    hasNextPage
                    hasPreviousPage
                    node {
                      id
                    }
                  }
                }
              }
            `,
            data: {
              id: thread.id,
              threadView: {
                __typename: 'ThreadEventConnection',
                edges: [
                  {
                    __typename: 'ThreadEventEdge',
                    cursor: threadEvent.id,
                    node: threadEvent,
                    hasNextPage: null,
                    hasPreviousPage: threadEventCreated ? false : null,
                  },
                ],
              },
              other: {
                __typename: 'ThreadEventConnection',
                edges: [
                  {
                    __typename: 'ThreadEventEdge',
                    cursor: threadEvent.id,
                    node: threadEvent,
                    hasNextPage: null,
                    hasPreviousPage: threadEventCreated ? false : null,
                  },
                ],
              },
            },
          });

          // hack to refetch inbox and unread count whenever new message to an inboxed thread
          if (thread.inboxed) {
            // refetch inbox if new message is to inboxed thread
            client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'pagedInbox' });
            client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'inboxThreadCount' });
            client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'threadNotificationCount' });
          }

          /* begin side effects of new message */
          if (threadEvent.__typename == 'MessageCreatedThreadEvent') {
            const messageCreated = threadEvent.message;
            if (!messageCreated) {
              return;
            }

            ThreadClientState.get(thread).removeTyper(client.cache, messageCreated.creator?.id);
          }
          /* end side effects of new message */
        }

        ////////////////////////////////////
        ////  ThreadResourceCreated
        ////////////////////////////////////
        if (updateEvent.threadResourceCreated?.thread) {
          // Poor man's hack to refetch thread resource connections. This is not ideal because it
          // resets pagination state. (https://app.asana.com/0/1204937559932507/1204937562612506/f)
          client.cache.evict({
            id: client.cache.identify(updateEvent.threadResourceCreated.thread),
            fieldName: 'resourceConnections',
          });
        }

        ////////////////////////////////////
        ////  ThreadResourceDeleted
        ////////////////////////////////////
        if (updateEvent.threadResourceDeleted?.thread) {
          // Poor man's hack to refetch thread resource connections. This is not ideal because it
          // resets pagination state. (https://app.asana.com/0/1204937559932507/1204937562612506/f)
          client.cache.evict({
            id: client.cache.identify(updateEvent.threadResourceDeleted.thread),
            fieldName: 'resourceConnections',
          });
        }

        ////////////////////////////////////
        ////  ThreadResourceDeleted
        ////////////////////////////////////
        if (updateEvent.threadResourceUpdated?.thread) {
          // Poor man's hack to refetch thread resource connections. This is not ideal because it
          // resets pagination state. (https://app.asana.com/0/1204937559932507/1204937562612506/f)
          client.cache.evict({
            id: client.cache.identify(updateEvent.threadResourceUpdated.thread),
            fieldName: 'resourceConnections',
          });
        }

        ////////////////////////////////////
        ////  meetingSummaryUpdated
        ////////////////////////////////////
        if (updateEvent.meetingSummaryUpdated) {
          // Poor man's hack to refetch summaryEventConnections and summaryEventGroupConnections. This is not ideal because it
          // resets pagination state. But we're not actually using pagination right now, so we're ok for now.
          client.cache.evict({
            id: client.cache.identify(updateEvent.meetingSummaryUpdated),
            fieldName: 'summaryEventConnections',
          });
          client.cache.evict({
            id: client.cache.identify(updateEvent.meetingSummaryUpdated),
            fieldName: 'summaryEventGroupConnections',
          });
        }

        ////////////////////////////////////
        ////  UserTypingThread
        ////////////////////////////////////
        if (userTypingThread) {
          userTypingThread.typing?.forEach(async (account) => {
            if (!account) {
              return;
            }
            ThreadClientState.get(userTypingThread).addTyper(client.cache, account.id);
          });
        }

        /////////////////////////////////////////
        ////  TeamCreated
        /////////////////////////////////////////
        if (updateEvent.teamCreated || updateEvent.teamUpdated) {
          // Hack to refetch browseTeams whenever a team is created
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'browseTeams' });
          const membership = updateEvent.teamUpdated?.membership;
          // user added or changed status
          if (membership?.find((m) => m?.account.id === loggedInAccount?.id)) {
            client.cache.evict({
              id: client.cache.identify({ id: loggedInAccount?.id, __typename: 'Account' }),
              fieldName: 'teamMemberships',
            });
          }
          // user removed
          if (loggedInAccount?.teamMemberships.find((team) => team.id === updateEvent.teamUpdated?.id)) {
            client.cache.evict({
              id: client.cache.identify({ id: loggedInAccount?.id, __typename: 'Account' }),
              fieldName: 'teamMemberships',
            });
          }
        }

        /////////////////////////////////////////
        ////  threadNotificationCreated
        /////////////////////////////////////////
        if (threadNotificationCreated) {
          // hack to refetch inbox and unread count whenever new message to an inboxed thread
          if (threadNotificationCreated.threadEvent.thread.inboxed) {
            // refetch inbox if new message is to inboxed thread
            client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'pagedInbox' });
            client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'inboxThreadCount' });
            client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'threadNotificationCount' });
          }
        }
      },
    }
  );
  return <div />;
}
