import { gql, useMutation, useQuery } from '@apollo/client';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useLocalStorage } from '@rehooks/local-storage';
import { exhaustive } from '@rmvw/x-common';
import debounce from 'lodash.debounce';
import * as React from 'react';

import {
  CreateSectionInput,
  MoveShortcutInput,
  NavShortcutType,
  UpdateSectionInput,
} from '../___generated___/globalTypes';
import { NavDragDropType } from '../components/ui/NavBar/Sortable/types';
import Logger from '../lib/observability/Logger';

import {
  HM_AddShortcut,
  HM_AddShortcutVariables,
  HM_CreateSection,
  HM_CreateSectionVariables,
  HM_DeleteSection,
  HM_DeleteSectionVariables,
  HM_DeleteShortcut,
  HM_DeleteShortcutVariables,
  HM_MoveShortcut,
  HM_MoveShortcutVariables,
  HM_UpdateSection,
  HM_UpdateSectionVariables,
  HQ_NavConfig,
  HQ_NavConfigVariables,
} from './___generated___/useShortcuts.types';
import { HF_Shortcut, NavConfig, NavSection, NavShortcut, ShortcutSectionFragment } from './query/useGetShortcutsQuery';

//
// Fragments and types
//

const NavConfigQuery = gql`
  fragment HF_NavConfigSection on NavSection {
    id
    name
    shortcuts {
      id
      target {
        ... on INode {
          id
        }
      }
      sectionId
    }
  }

  query HQ_NavConfig {
    account {
      id
      navConfig {
        id
        defaultSection {
          id
          ...HF_NavConfigSection
        }
        sections {
          id
          ...HF_NavConfigSection
        }
      }
    }
  }
`;

export interface IShortcut {
  id: string;
  sectionId: string;
  target: IShortcutTarget;
}

export interface IShortcutTarget {
  id: string;
  __typename: 'Account' | 'Discussion' | 'PrivateChat' | 'Team';
}

//
// Nav config
//

export function useNavConfigQuery() {
  return useQuery<HQ_NavConfig, HQ_NavConfigVariables>(NavConfigQuery);
}

//
// Shortcuts
//

export function useGetShortcutForTarget(targetId?: string): IShortcut | undefined {
  const { data } = useNavConfigQuery();
  const sections = data?.account ? data.account.navConfig.sections : [];
  const shortcuts = sections.map(({ shortcuts }) => shortcuts).flat();
  return shortcuts.find(({ target }) => targetId === target.id);
}

const AddShortcutMutation = gql`
  mutation HM_AddShortcut($input: AddShortcutInput) {
    addShortcut(input: $input) {
      id
      defaultSection {
        ...HF_ShortcutSection
      }
      sections {
        ...HF_ShortcutSection
      }
    }
  }
  ${ShortcutSectionFragment}
`;

export function useAddShortcutMutation() {
  const [_addShortcut, result] = useMutation<HM_AddShortcut, HM_AddShortcutVariables>(AddShortcutMutation);
  const [resolve, setResolver] = React.useState<[string, (value: HF_Shortcut) => void]>();

  /**
   * addShortcut awaits the result of the mutation and returns the created shortcut
   */
  const addShortcut = React.useCallback(
    async (target?: IShortcutTarget, sectionId?: string, position?: number) => {
      if (!target) {
        return;
      }

      try {
        await _addShortcut({
          variables: {
            input: {
              position,
              sectionId,
              shortcutType: getShortcutType(target),
              targetId: target.id,
            },
          },
        });

        return new Promise((resolve: (value: HF_Shortcut) => void) => {
          setResolver([target.id, resolve]);
        });
      } catch (err) {
        Logger.error(err as Error, 'Could not add shortcut');
        return Promise.reject(err);
      }
    },
    [_addShortcut]
  );

  React.useEffect(() => {
    if (!resolve) {
      return;
    }
    if (result.data?.addShortcut) {
      const navConfig = result.data?.addShortcut;
      const [targetId, _resolve] = resolve;
      // find newly created shortcut for target
      const shortcut = [navConfig.defaultSection.shortcuts, ...navConfig.sections.map((section) => section.shortcuts)]
        .flat()
        .find((s) => s.target.id === targetId);
      if (shortcut) {
        _resolve(shortcut);
      }
      setResolver(undefined);
    }
  }, [resolve, result]);

  return [addShortcut, result] as const;
}

const DeleteShortcutMutation = gql`
  mutation HM_DeleteShortcut($input: DeleteShortcutInput) {
    deleteShortcut(input: $input) {
      id
      defaultSection {
        ...HF_ShortcutSection
      }
      sections {
        ...HF_ShortcutSection
      }
    }
  }
  ${ShortcutSectionFragment}
`;

export function useDeleteShortcutMutation() {
  const [_deleteShortcut, result] = useMutation<HM_DeleteShortcut, HM_DeleteShortcutVariables>(DeleteShortcutMutation);

  async function deleteShortcut(shortcutId: string) {
    try {
      await _deleteShortcut({
        variables: { input: { shortcutId } },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not delete shortcut');
    }
  }

  return [deleteShortcut, result] as const;
}

const MoveShortcutMutation = gql`
  mutation HM_MoveShortcut($input: MoveShortcutInput) {
    moveShortcut(input: $input) {
      id
      defaultSection {
        ...HF_ShortcutSection
      }
      sections {
        ...HF_ShortcutSection
      }
    }
  }
  ${ShortcutSectionFragment}
`;

export function useMoveShortcutMutation() {
  const [_moveShortcut, result] = useMutation<HM_MoveShortcut, HM_MoveShortcutVariables>(MoveShortcutMutation);

  async function moveShortcut(input: MoveShortcutInput) {
    try {
      await _moveShortcut({
        variables: { input },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not move shortcut');
    }
  }

  return [moveShortcut, result] as const;
}

//
// Shortcut sections
//

const CreateSectionMutation = gql`
  mutation HM_CreateSection($input: CreateSectionInput) {
    createSection(input: $input) {
      id
      defaultSection {
        id
        name
      }
      sections {
        id
        name
      }
    }
  }
`;

export function useCreateSectionMutation() {
  const [_createSection, result] = useMutation<HM_CreateSection, HM_CreateSectionVariables>(CreateSectionMutation);

  async function createSection(input: CreateSectionInput) {
    try {
      await _createSection({
        variables: { input },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not update section');
    }
  }

  return [createSection, result] as const;
}

const DeleteSectionMutation = gql`
  mutation HM_DeleteSection($input: DeleteSectionInput) {
    deleteSection(input: $input) {
      id
      defaultSection {
        id
      }
      sections {
        id
      }
    }
  }
`;

export function useDeleteSectionMutation() {
  const [_deleteSection, result] = useMutation<HM_DeleteSection, HM_DeleteSectionVariables>(DeleteSectionMutation);

  async function deleteSection(sectionId: string) {
    try {
      await _deleteSection({
        variables: { input: { sectionId } },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not update section');
    }
  }

  return [deleteSection, result] as const;
}

const UpdateSectionMutation = gql`
  mutation HM_UpdateSection($input: UpdateSectionInput) {
    updateSection(input: $input) {
      id
      defaultSection {
        id
        name
      }
      sections {
        id
        name
      }
    }
  }
`;

export function useUpdateSectionMutation() {
  const [_updateSection, result] = useMutation<HM_UpdateSection, HM_UpdateSectionVariables>(UpdateSectionMutation);

  async function updateSection(input: UpdateSectionInput) {
    try {
      await _updateSection({
        variables: { input },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not update section');
    }
  }

  return [updateSection, result] as const;
}

//
// Helpers
//

// TODO: Break this helpers out into different files @nick

function getShortcutType(target: IShortcutTarget) {
  switch (target.__typename) {
    case 'Account':
      return NavShortcutType.ACCOUNT;
    case 'Discussion':
      return NavShortcutType.DISCUSSION;
    case 'PrivateChat':
      return NavShortcutType.PRIVATE_CHAT;
    case 'Team':
      return NavShortcutType.TEAM;
    default:
      exhaustive(target.__typename, `Unhandled shortcut target type ${target.__typename}`);
  }
}

export function useNavSectionState() {
  const [state, _setState] = useLocalStorage<Record<string, boolean>>('nav-section-state', {});

  function setState(key: string) {
    return (isExpanded: boolean) => _setState({ ...state, [key]: isExpanded });
  }

  return [state, setState] as const;
}

export function useNavConfigOnDragEnd(
  sections: NavSection[],
  shortcuts: NavShortcut[],
  setSections: (sections: NavSection[]) => void,
  setShortcuts: (shortcuts: NavShortcut[]) => void
) {
  const [addShortcut] = useAddShortcutMutation();
  const [updateSection] = useUpdateSectionMutation();
  const [moveShortcut] = useMoveShortcutMutation();

  return React.useCallback(
    async ({ active, over }: DragEndEvent) => {
      if (!over || active.id === over.id) {
        return;
      }

      const activeType = active.data.current?.type;
      const overType = over.data.current?.type;

      if (overType === NavDragDropType.THREAD) {
        return;
      }

      const activeTarget = active.data.current?.target;
      const activeShortcutId = active.data.current?.shortcutId;
      const overShortcutId = over.data.current?.shortcutId;

      if (activeType === NavDragDropType.SECTION && overType === NavDragDropType.SHORTCUT) {
        // Sections cannot interact with shortcuts.
        return;
      }

      // Reorder sections
      if (activeType === NavDragDropType.SECTION && overType === NavDragDropType.SECTION) {
        const activeIndex = sections.findIndex(({ id }) => id === active.id);
        const overIndex = sections.findIndex(({ id }) => id === over?.id);

        setSections(arrayMove(sections, activeIndex, overIndex));

        await updateSection({
          position: overIndex,
          sectionId: String(active.id),
        });
        return;
      }

      // Reorder items
      const activeIndex = shortcuts.findIndex(({ id }) => id === activeShortcutId);
      const overIndex = shortcuts.findIndex(({ id }) => id === overShortcutId);

      // helper method to find index in flattened shortcut list where section starts
      function _getIndexOfSectionStart(sectionId: string) {
        let index = 0;
        for (const section of sections) {
          if (section.id === sectionId) {
            return index;
          }
          index = index + section.shortcuts.length;
        }
        return index;
      }

      // Dragging shortcut onto a section.
      if (activeType === NavDragDropType.SHORTCUT && overType === NavDragDropType.SECTION) {
        const sectionId = String(over.id);
        shortcuts[activeIndex] = { ...shortcuts[activeIndex], sectionId };
        let position = _getIndexOfSectionStart(sectionId);
        // need to correct target position when moving to later in list to account for hole left by item
        if (position > activeIndex) {
          position = position - 1;
        }
        setShortcuts(arrayMove(shortcuts, activeIndex, position));

        await moveShortcut({
          sectionId,
          shortcutId: String(activeShortcutId),
          position: 0,
        });

        return;
      }

      // dragging un-bookmarked thread onto a nav section
      if (activeType === NavDragDropType.THREAD && overType === NavDragDropType.SECTION) {
        const sectionId = String(over.id);
        const position = _getIndexOfSectionStart(sectionId);
        const shortcut = await addShortcut(activeTarget, sectionId, 0);
        if (shortcut) {
          shortcuts.splice(position, 0, shortcut);
          setShortcuts([...shortcuts]);
        }
        return;
      }

      let sectionId = shortcuts[activeIndex]?.sectionId;
      // Dragging shortcut onto item in a different section.
      if (shortcuts[activeIndex]?.sectionId !== shortcuts[overIndex]?.sectionId) {
        sectionId = shortcuts[overIndex].sectionId;
        shortcuts[activeIndex] = { ...shortcuts[activeIndex], sectionId };
      }

      // Dragging shortcut to new position
      if (activeType === NavDragDropType.SHORTCUT) {
        setShortcuts(arrayMove(shortcuts, activeIndex, overIndex));

        await moveShortcut({
          sectionId,
          shortcutId: String(activeShortcutId),
          position: overIndex,
        });
        return;
      }

      // Dragging thread to specific position
      if (activeType === NavDragDropType.THREAD) {
        const sectionIndex = _getIndexOfSectionStart(sectionId);
        // convert to section position and correct for fact that drop indicator is placed AFTER drop target
        const shortcut = await addShortcut(activeTarget, sectionId, overIndex + 1 - sectionIndex);
        if (shortcut) {
          shortcuts.splice(overIndex + 1, 0, shortcut);
          setShortcuts([...shortcuts]);
        }
      }
    },
    [addShortcut, moveShortcut, sections, setSections, setShortcuts, shortcuts, updateSection]
  );
}

export const DEFAULT_SHORTCUT_SECTION_NAME = 'Shortcuts';
const SHORTCUT_HIGHLIGHT_DEBOUNCE_MS = 3500; // 3.5s (highlight animation is 3s)

export function useNavConfigState({ defaultSection, ...navConfig }: NavConfig) {
  const nonDefaultSections = navConfig.sections.filter((section) => section.id !== defaultSection.id);
  const _sections: NavSection[] = [...nonDefaultSections, defaultSection].map((section) => ({
    ...section,
    isDefault: section.id === defaultSection.id,
  }));
  const [sections, setSections] = React.useState<NavSection[]>(_sections);

  const sectionStr = JSON.stringify(_sections);
  React.useEffect(() => {
    setSections(JSON.parse(sectionStr));
  }, [sectionStr]);

  const _shortcuts = _sections.flatMap(({ shortcuts }) => shortcuts);

  const [shortcuts, setShortcuts] = React.useState<NavShortcut[]>(_shortcuts);
  const [shortcutIdsToHighlight, setShortcutIdsToHighlight] = React.useState<string[]>([]);

  const shortcutsStr = JSON.stringify(_shortcuts);
  React.useEffect(() => {
    setShortcuts((prev) => {
      const newShortcuts = JSON.parse(shortcutsStr) as NavShortcut[];

      const newShortcutIdsToHighlight = newShortcuts
        .filter((shortcut) => !prev.filter((s) => s.id === shortcut.id).length)
        .extract('id');
      setShortcutIdsToHighlight((prev) => [...prev, ...newShortcutIdsToHighlight]);

      // clear the highlights after a delay
      // TODO: Support bulk shortcut creation so we don't need to debounce
      debounce(() => {
        setShortcutIdsToHighlight([]);
      }, SHORTCUT_HIGHLIGHT_DEBOUNCE_MS)();

      return newShortcuts;
    });
  }, [shortcutsStr]);

  return { defaultSection, sections, setSections, shortcuts, setShortcuts, shortcutIdsToHighlight };
}
