import { gql, useQuery } from '@apollo/client';
import { RTElementProp, RTMediaElement } from '@rmvw/x-common';
import * as React from 'react';
import { useFocused, useSelected } from 'slate-react';
import styled from 'styled-components';

import { useDownloadMedia } from '../../../../hooks/useDownloadMedia';
import { hexColorWithAlpha } from '../../../../lib/css';
import Logger from '../../../../lib/observability/Logger';
import MediaPlayer, { IMediaPlayerRef } from '../../../MediaPlayer';
import { IRTElementProps } from '../../IRTElementProps';
import ElementPlaceholder from '../common/ElementPlaceholder';
import ResolverStatusOverlay from '../common/ResolverStatusOverlay';
import { IUseResolverStatusResult, useResolverStatus } from '../common/useResolverStatus';
import VoidBlockElement from '../common/VoidBlockElement';

import {
  BATCHED__MediaElement_MediaQuery,
  BATCHED__MediaElement_MediaQueryVariables,
} from './___generated___/MediaElement.types';

const fragment = gql`
  fragment CF_MediaElement on Media {
    id
    type
    url
  }
`;

const MediaQuery = gql`
  ${fragment}
  # Batched query
  query BATCHED__MediaElement_MediaQuery($mediaId: ID!) {
    media(id: $mediaId) {
      ...CF_MediaElement
    }
  }
`;

const _MediaContainer = styled.div<{ $isVideo: boolean }>`
  border-radius: ${(props) => props.theme.borderRadius.medium};
  cursor: pointer;
  height: ${({ $isVideo }) => ($isVideo ? 'auto' : '28px')};
  max-width: ${({ theme }) => theme.dimension.threadPreviewCard.maxWidth};
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const _SelectedOverlay = styled.div`
  background-color: ${({ theme }) => hexColorWithAlpha(theme.color.textSelectionBackground, 0.5)};
  inset: 0;
  position: absolute;
`;

function _Media({
  editable,
  media,
  onPause,
  onPlay,
  playing,
  resolverStatus,
  videoRef,
}: {
  editable?: boolean;
  media?: BATCHED__MediaElement_MediaQuery['media'];
  onPause: () => void;
  onPlay: () => void;
  playing: boolean;
  resolverStatus?: IUseResolverStatusResult;
  videoRef: React.RefObject<IMediaPlayerRef>;
}) {
  // Memoize src since URL.createObjectURL generates a different URL per invocation
  const src = resolverStatus?.previewUrl ?? media?.url ?? undefined;
  const mediaType = resolverStatus?.status?.data?.file?.type ?? media?.type ?? undefined;
  const isVideo = mediaType?.toLowerCase().startsWith('video');
  const isAudio = mediaType?.toLowerCase().startsWith('audio');

  if (src) {
    if (!isVideo && !isAudio) {
      Logger.error(`[MediaElement] Unsupported media type: ${mediaType}`);
      return <ElementPlaceholder />;
    }

    return (
      <MediaPlayer
        controls={isAudio || !editable}
        height={'100%'}
        onPause={onPause}
        onPlay={onPlay}
        playing={playing}
        playsinline // on mobile, prevent default fullscreen playback
        ref={videoRef}
        style={{ display: 'flex' }}
        url={src}
        width={'100%'}
      />
    );
  } else {
    // Placeholder for empty media over which we can render error as needed
    return <ElementPlaceholder />;
  }
}

const DEFAULT_MEDIA_QUERY_POLL_INTERVAL_MS = 15_000;

export default function MediaElement(props: IRTElementProps<RTMediaElement>) {
  const focused = useFocused();
  const selected = useSelected();

  const downloadMedia = useDownloadMedia({ id: props.element[RTElementProp.MEDIA__MEDIA_ID] });
  const resolverStatus = useResolverStatus(props);

  const videoRef = React.useRef<IMediaPlayerRef>(null);
  const [mediaPlaying, setMediaPlaying] = React.useState(false);

  const mediaQuery = useQuery<BATCHED__MediaElement_MediaQuery, BATCHED__MediaElement_MediaQueryVariables>(MediaQuery, {
    // Temporary workaround: poll interval compensates for limitations in the current pubsub model.
    // Will be replaced by a more robust, object-level pubsub subscription & routing system in the future.
    pollInterval: DEFAULT_MEDIA_QUERY_POLL_INTERVAL_MS,
    skip: !props.element[RTElementProp.MEDIA__MEDIA_ID],
    variables: { mediaId: props.element[RTElementProp.MEDIA__MEDIA_ID] ?? '' },
  });

  // Stop polling when media is ready
  React.useEffect(() => {
    if (mediaQuery.data?.media?.url) {
      mediaQuery.stopPolling();
    }
  }, [mediaQuery]);

  // Pause playback on blur
  React.useEffect(() => {
    if (props.editable && (!focused || !selected)) {
      setMediaPlaying(false);
    }
  }, [props.editable, focused, selected]);

  const isVideo = !!(resolverStatus?.status?.data?.file?.type ?? mediaQuery?.data?.media?.type)
    ?.toLowerCase()
    .startsWith('video');

  return (
    <VoidBlockElement attributes={props.attributes} slateChildren={props.children}>
      <_MediaContainer
        $isVideo={isVideo}
        onClick={async (e) => {
          if (!props.editable) {
            if (e.altKey) {
              e.preventDefault();
              e.stopPropagation();

              // If user is holding alt/option key, download the image
              await downloadMedia();
            }
            return;
          }
          setMediaPlaying(!mediaPlaying);
        }}
        onMouseLeave={(e) => {
          if (!props.editable) {
            return;
          }
          setMediaPlaying(false);
        }}
        onMouseMove={(e) => {
          if (!isVideo || !props.editable || mediaPlaying) {
            return;
          }
          const targetRect = e.currentTarget.getBoundingClientRect();
          const relativeXPos = (e.clientX - targetRect.x) / targetRect.width;
          videoRef.current?.seekTo(relativeXPos);
        }}
      >
        <_Media
          editable={props.editable}
          media={mediaQuery?.data?.media}
          onPause={() => setMediaPlaying(false)}
          onPlay={() => setMediaPlaying(true)}
          playing={mediaPlaying}
          resolverStatus={resolverStatus}
          videoRef={videoRef}
        />
        {props.editable && resolverStatus && <ResolverStatusOverlay status={resolverStatus.status} />}
        {selected && <_SelectedOverlay />}
      </_MediaContainer>
    </VoidBlockElement>
  );
}

MediaElement.fragment = fragment;
