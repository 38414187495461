import { gql } from '@apollo/client';
import { CourbetPalette, LegacyPalette } from '@rmvw/c-common';
import { dedupe } from '@rmvw/x-common';
import { parseISO } from 'date-fns';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { MeetingParticipantState } from '../../../___generated___/globalTypes';
import { hexColorWithAlpha } from '../../../lib/css';
import { useAppMeetingState } from '../../../providers/AppMeetingStateProvider';
import { ProfileAvatar } from '../../Avatar';
import LiveVideoBadge from '../../LiveVideoBadge';
import MediaContainer from '../../MediaContainer';
import MediaPlayer from '../../MediaPlayer';
import { PreviewMeetingDuration } from '../../MeetingDuration';

import { CF_LivePreviewMeeting } from './___generated___/LivePreview.types';

const _ParticipantGrid = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  padding: 16px;
  width: 100%;
`;

const _computeParticipantGridItemWidthRatio = (count: number) => {
  // Assumption: containing rectangle has an aspect ratio of 16:9
  const arWidth = 16;
  const arHeight = 9;

  // Find the number of rows and columns for the grid
  const r = Math.ceil(Math.sqrt((count * arHeight) / (arWidth + arHeight)));
  const c = Math.ceil(count / r); // ensure we have enough columns to fit n squares

  // The maximum square size will be the minimum of the width and height constraint
  const maxWidthPerSquare = arWidth / c;
  const maxHeightPerSquare = arHeight / r;

  // Maximum size of square as a proportion of the rectangle's width
  return Math.min(maxWidthPerSquare, maxHeightPerSquare) / arWidth;
};

const _ParticipantGridItem = styled.div<{ $widthPct: number }>`
  padding: 8px;
  width: ${({ $widthPct }) => $widthPct}%;
`;

const _LiveBadge = styled.div`
  left: 8px;
  position: absolute;
  top: 8px;
`;

const _JoinPrompt = styled.div`
  align-items: center;
  background-color: ${hexColorWithAlpha(LegacyPalette.black, 0.5)};
  border: 1px solid ${LegacyPalette.white};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  display: flex;
  filter: drop-shadow(0 0 4px ${LegacyPalette.black});
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  opacity: 0.8;
  padding: 8px 16px;
  transition: opacity 250ms;
`;

const _JoinLink = styled.a`
  align-items: center;
  backdrop-filter: brightness(80%) saturate(50%); /* Darken the background */
  background: radial-gradient(ellipse at center, rgb(0 0 0 / 0%) 50%, rgb(0 0 0 / 50%) 100%);
  color: ${LegacyPalette.white};
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;

  &:active,
  &:focus,
  &:hover {
    color: ${LegacyPalette.white};

    ${_JoinPrompt} {
      opacity: 1;
    }
  }

  /* Always show join link on devices that don't support hover state */
  @media (hover: none) {
    ${_JoinPrompt} {
      opacity: 1;
    }
  }
`;

export const Indicator = styled.div`
  background-color: ${CourbetPalette.red600};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  height: 8px;
  margin-right: 4px;
  width: 8px;
`;

export interface IBaseLivePreviewProps extends ILivePreviewProps {
  hidePreview?: boolean;
  onClick?: () => void;
  overlay?: JSX.Element;
}

export function BaseLivePreview({ hidePreview, meeting, onClick, overlay }: IBaseLivePreviewProps) {
  const { ref, inView } = useInView();

  // Prompt viewers to join the meeting if they are allowed to join and haven't
  // done so already.
  const joinOverlay = meeting.canJoin && onClick && (
    <_JoinLink onClick={onClick}>
      <_JoinPrompt>
        <Indicator />
        Join Video Chat
      </_JoinPrompt>
    </_JoinLink>
  );

  const liveBadgeOverlay = (
    <_LiveBadge>
      <LiveVideoBadge invert />
    </_LiveBadge>
  );

  const durationOverlay = meeting.startTime && (
    <PreviewMeetingDuration
      start={parseISO(meeting.startTime)}
      end={meeting.endTime ? parseISO(meeting.endTime) : undefined}
    />
  );

  const activeParticipants = dedupe(
    meeting.meetingParticipants?.filter((p) => p?.state === MeetingParticipantState.JOINED).filterNullish() ?? [],
    (p) => p.account?.id
  );

  const participantTileGrid = (
    <_ParticipantGrid>
      {activeParticipants
        .map(({ account }) =>
          account ? (
            <_ParticipantGridItem
              $widthPct={Math.floor(100 * _computeParticipantGridItemWidthRatio(activeParticipants.length))}
              key={account.id}
            >
              <ProfileAvatar profile={account} />
            </_ParticipantGridItem>
          ) : null
        )
        .filterNullish()}
    </_ParticipantGrid>
  );

  const content =
    !inView || !meeting.livestreamUrl || hidePreview ? (
      participantTileGrid
    ) : (
      <MediaPlayer
        config={{ file: { attributes: { crossOrigin: 'true' } } }}
        height="100%"
        muted
        playing
        playsinline // Prevent default fullscreen playback on mobile.
        url={meeting.livestreamUrl}
        width="100%"
      />
    );

  return (
    <MediaContainer ref={ref}>
      {content}
      {overlay ? (
        overlay
      ) : (
        <>
          {joinOverlay}
          {liveBadgeOverlay}
          {durationOverlay}
        </>
      )}
    </MediaContainer>
  );
}

export interface ILivePreviewProps {
  hidePreview?: boolean;
  meeting: CF_LivePreviewMeeting;
  overlay?: JSX.Element;
}

function LivePreview({ hidePreview, ...props }: ILivePreviewProps) {
  const { meetingState, showMeeting } = useAppMeetingState();
  const seriesCode = props.meeting.sourceMeetingSeries?.code;
  return (
    <BaseLivePreview
      {...props}
      hidePreview={hidePreview || meetingState.seriesCode === seriesCode} // Hide preview if user is already in the meeting to avoid confusion around delayed playback
      onClick={seriesCode ? () => showMeeting({ seriesCode }) : undefined}
    />
  );
}

LivePreview.fragment = gql`
  ${ProfileAvatar.fragment}
  fragment CF_LivePreviewMeeting on Meeting {
    id
    canJoin
    endTime
    livestreamUrl
    meetingParticipants {
      id
      state
      account {
        ...CF_ProfileAvatar
      }
    }
    sourceMeetingSeries {
      id
      code
    }
    startTime
  }
`;

export default LivePreview;
